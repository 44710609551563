<template>
    <DialogEditor
        v-model="showEditor"
        :title="edit ? 'Edit User' : 'New User'"
    >
        <template v-slot:content>
            <v-text-field
                label="Name"
                v-model="name"
                :rules="emptyRule"
                v-if="$store.state.isAdmin"
            ></v-text-field>
            <v-text-field
                label="Username"
                v-model="username"
                :rules="emptyRule"
                v-if="$store.state.isAdmin"
            ></v-text-field>
            <v-text-field
                label="Password"
                v-model="password"
                type="password"
                :hint="!edit || (password != null && password.length > 0 && password.length < 6) ?
                'Password must be longer than 5 characters' : 'Leave this field empty to keep old password'"
                persistent-hint
                clearable
                @click:clear="repeatpassword = null"
            ></v-text-field>
            <v-text-field
                label="Repeat password"
                v-model="repeatpassword"
                type="password"
                v-if="password != null && password.length > 5"
                :rules="[(password === repeatpassword) || 'The password does not match']"
                clearable
            ></v-text-field>
            <v-autocomplete
                :items="$store.state.owners.list"
                v-model="owners"
                item-value="id"
                item-text="name"
                return-object
                label="Hotel chains"
                multiple
                chips
                deletable-chips
                v-if="$store.state.isAdmin"
            ></v-autocomplete>
            <v-autocomplete
                :items="$store.state.groups.list"
                v-model="groups"
                item-value="id"
                item-text="name"
                return-object
                label="Groups"
                multiple
                chips
                v-if="$store.state.isAdmin"
                deletable-chips
            ></v-autocomplete>
        </template>
        <template v-slot:actions="{valid}">
            <v-btn
                color="error"
                v-show="edit"
                @click="deleteUser"
                :disabled="$store.state.users.saving"
                v-if="$store.state.isAdmin && user !== null && user.id !== $store.state.userAccountId"
                :text="$vuetify.breakpoint.mdAndDown"
            >
                Delete
            </v-btn>
            <v-btn
                color="accent"
                :disabled="!valid || (password != null && password.length < 6) || (!edit && password == null) ||
                (!$store.state.isAdmin && (password == null || password.length < 6)) || (password !== repeatpassword)"
                @click="save"
                :loading="$store.state.users.saving"
                :text="$vuetify.breakpoint.mdAndDown"
            >
                {{edit ? 'Save' : 'Create'}}
            </v-btn>
        </template>
    </DialogEditor>
</template>

<script>
    import Vue from 'vue';
    import DialogEditor from "../core/DialogEditor";

    export default {
        name: "TheUserEditor",
        components: {DialogEditor},
        data: () => ({
            emptyRule: [v => !!v || 'Field required'],
            name: null,
            username: null,
            password: null,
            repeatpassword: null,
            owners: null,
            groups: null,
            showEditor: false,
            user: null,
            edit: false,
        }),
        created() {
            Vue.prototype.$userEditor = this;
        },
        methods: {
            show(user, edit) {
                if (user === null && edit) {
                    this.$alert.error('User could not be edited');
                    this.showEditor = false;
                    return;
                }

                this.edit = edit;
                this.user = user;
                this.name = edit ? user.name : null;
                this.username = edit ? user.username : null;
                this.owners = edit ? user.owners : null;
                this.groups = edit ? user.groups : null;
                this.password = null;
                this.repeatpassword = null;
                this.showEditor = true;
            },
            save() {
                this.$store.dispatch('users/save', {
                    edit: this.edit, item: {
                        name: this.name,
                        username: this.username,
                        owners: this.owners == null ? [] : this.owners.map(o => o.id),
                        groups: this.groups == null ? [] : this.groups.map(g => g.id),
                        password: (this.password == null || this.password !== this.repeatpassword) ? undefined : this.password,
                        id: this.edit ? this.user.id : undefined,
                    }
                });

                this.showEditor = false;
            },
            deleteUser() {
                this.$confirm.delete('Delete User', 'Are you sure you wish to delete this user?', () => {
                    this.$store.dispatch('users/delete', {item: this.user});
                    this.showEditor = false;
                });
            }
        }
    }
</script>