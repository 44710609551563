import API from '../../lib/api'
import Vue from 'vue'
import crudModule from "./crudModule";

const module = crudModule('Event log', '/status');

module.actions['fetch'] = async ({commit}) => {
    commit('setFetching', true);

    const response = await API.request('GET', module.path + '/recent');

    if (response.success === true) {
        commit('setList', response.data.items);
    } else {
        Vue.prototype.$alert.error(`Failed to fetch ${module.itemName.toLowerCase()} list`);
        commit('setList', []);
    }

    commit('setFetching', false);
};

export default module;