<template>
    <v-card
        :height="height"
        :class="!noScroll || 'no-scroll'"
        class="dashboard-card"
    >
        <v-card-title v-if="title!==null">
            {{title}}
            <v-spacer></v-spacer>
            <slot name="titleActions"></slot>
        </v-card-title>
        <v-card-text :class="!contentPadding || 'content-padding'">
            <slot name="content"></slot>
            <div style="height: 6px"></div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "DashboardCard",
        props: {
            height: {
                default: 360,
                type: Number
            },
            title: String,
            noScroll: {
                defualt: false,
                type: Boolean,
            },
            contentPadding: {
                default: false,
                type: Boolean,
            }
        }
    }
</script>

<style scoped>
    .v-card__text {
        height: calc(100% - 52px);
        overflow-y: auto;
        padding: 0;
    }

    .v-card__text.content-padding {
        padding: 0 16px 0 16px;
    }

    .v-card__title {
        padding-bottom: 4px;
        padding-top: 12px;
    }

    .v-card.no-scroll .v-card__text {
        overflow: hidden !important;
    }
</style>

<style lang="scss">
    .md-and-down .dashboard-card .apexcharts-legend {
        display: none;
    }
</style>