<!--suppress JSUnfilteredForInLoop -->
<template>
    <DashboardCard
        :title="null"
        :no-scroll="true"
        content-padding
    >
        <template v-slot:content>
            <v-container>
                <v-row>
                    <v-col cols="6" class="summary-body pb-0">
                        <span class="label">FacilityId:</span>
                    </v-col>
                    <v-col cols="6" class="summary-body pb-0">
                        {{instance.facilityId}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="summary-body pt-0">
<!--                        <div class="heading">Servers</div>-->
                        <span class="label">Brand:</span><br>{{instance.brandTitle}}<br>
                        <span class="label">Chain:</span><br>{{instance.ownerTitle}}<br>
                    </v-col>
                    <v-col cols="6" class="summary-body pt-0">
<!--                        <div class="heading">{{clientTitle}}s</div>-->
                        <span class="label">TimeZone:</span><span class="value">{{instance.timeZone}}</span><br>
                        <span class="label">Rooms:</span><span class="value">{{instance.roomCnt}}</span><br>
                        <span class="label">TVs:</span><span class="value">{{instance.numberOfClients}}</span><br>
                        <span class="label">Activation Date:</span>
                        <span v-if="instance.service === 'chromecast' " class="value">n/a</span>
                        <span v-else class="value">{{instance.activationDate.substr(0, 10)}}</span>
                        <br>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <VueApexChart
                                v-if="$route.name === 'details'"
                                height="160"
                                type="donut"
                                :series="series"
                                :options="options"
                        ></VueApexChart>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </DashboardCard>
</template>

<script>
    import DashboardCard from "../core/dashboard/DashboardCard";
    import VueApexChart from 'vue-apexcharts';

    export default {
        name: "DetailsSummary",
        components: {DashboardCard, VueApexChart},
        props: {
            instance: Object,
        },
        data: () => ({
            options: {},
        }),
        computed: {
            series() {
                const series = [];

                series.push(this.instance.clientsOnline);
                series.push(this.instance.offlineTvs);

                this.options = {
                    labels: [ 'Online TVs', 'Offline TVs' ],
                    colors: ['#008ffb', '#b71c1c']
                };

                return series;
            }
        }
    }
</script>

<style scoped lang='scss'>
    .summary-body {
        .heading {
            font-size: 1.3em;
            line-height: 1.5em;
            border-bottom: 1px solid gray;
            margin-bottom: 10px;
        }
        .value {
            float: right;
        }
        font-size: 1em;
        line-height: 1.3em;
    }

    .label {
        width: 50px;
        font-weight: bold;
    }
</style>
