<template>
    <div>
        <v-btn
            text
            @click="create"
            height="64px"
        >
            <v-icon>mdi-plus</v-icon>
            Create Alert
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: "AlertActions",
        methods: {
            create() {
                this.$alertEditor.show(null, false);
            }
        }
    }
</script>