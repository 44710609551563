import crudModule from "./crudModule";
import Storage from "../../lib/storage";
import DateLib from "@/lib/date";
import Vue from 'vue'

const set = Vue.prototype.$set;

const module = crudModule('Instance', '/status/latest');

module.state.filterKey = null;
module.state.filterVal = null;
module.state.recentInstances = [];
module.state.lockRecentInstances = true;
module.state.offlineHotelIds = null;
module.state.typeCount = {
    hotels: 0,
    cloudHotels: 0,
    onPremHotels: 0,
    offlineHotels: 0,
    hotelScreens: 0,
    casts: 0,
    offlineCasts: 0,
    castScreens: 0,
};

module.mutations['setList'] = (state, l) => {
    state.list = l.sort((a, b) => {
        if (a.online && !b.online) return 1;
        else if (!a.online && b.online) return -1;
        return a.name > b.name ? 1 : -1;
    });

    state.fetched = new Date();
};

module.mutations['setApplyFilter'] = (state, t) => {
    if(typeof t === 'object') {
        state.filterKey = t.key;
        state.filterVal = t.val;
    } else {
        state.filterKey = null;
        state.filterVal = null;
    }
};

module.mutations['setTypeCount'] = (state, tc) => {
    state.typeCount = tc;
};

module.mutations['setOfflineHotelIds'] = (state, ids) => {
    state.offlineHotelIds = ids;
};

module.mutations['setLockRecentInstances'] = (state, l) => {
    state.lockRecentInstances = l;
};

module.mutations['pushRecentInstance'] = (state, i) => {
    state.recentInstances.push(i);
};

module.mutations['shiftRecentInstances'] = (state) => {
    state.recentInstances.shift();
};


const actions = {
    applyFilter: async ({commit}, type) => {
        commit('setApplyFilter', type);
    },
    reset: ({commit}) => {
        commit('setOfflineHotelIds', null);
        commit('resetState');
    },
    updateTypeCount: ({commit}) => {
        let hotels = 0;
        let cloudHotels = 0;
        let onPremHotels = 0;
        let offlineHotels = 0;
        let hotelScreens = 0;
        let casts = 0;
        let offlineCasts = 0;
        let castScreens = 0;

        const offlineHotelIds = [];

        module.state.list.forEach(i => {
            if (!i.online) offlineHotelIds.push(i.facilityId + i.service);

            if (i.service === 'enterprise') {
                hotels++;

                if (i.hosted) cloudHotels++;
                else onPremHotels++;

                if (i.active === true) {
                    if (i.online === false) offlineHotels++;
                    if (i.numberOfClients !== null) hotelScreens += parseInt(i.numberOfClients);
                }
            } else {
                casts++;

                if (i.active === true) {
                    if (i.online === false) offlineCasts++;
                    if (i.numberOfClients !== null) castScreens += parseInt(i.numberOfClients);
                }
            }
        });

        commit('setOfflineHotelIds', offlineHotelIds);

        commit('setTypeCount', {
            hotels: hotels,
            cloudHotels: cloudHotels,
            onPremHotels: onPremHotels,
            offlineHotels: offlineHotels,
            hotelScreens: hotelScreens,
            casts: casts,
            offlineCasts: offlineCasts,
            castScreens: castScreens,
        });
    },
    addRecentInstance: ({commit}, instance) => {
        if (module.state.lockRecentInstances ||
            module.state.recentInstances.filter(i => i.facilityId === instance.facilityId && i.service === instance.service).length > 0) {

            return;
        }

        commit('pushRecentInstance', instance);

        if (module.state.recentInstances.length > 8) {
            commit('shiftRecentInstances');
        }

        const toStore = [];

        module.state.recentInstances.forEach(i => {
            if (!toStore.includes(i.facilityId + '|' + i.service)) {
                toStore.push(i.facilityId + '|' + i.service);
            }
        });

        Storage.set('recentInstances', toStore.join());
    },
    retrieveRecentInstances: ({commit}, instancesMap) => {
        if(!instancesMap) {
            return;
        }
        const recentInstances = Storage.get('recentInstances', '').split(',');

        recentInstances.forEach(i => {
            const facilityId = i.split('|')[0];
            const service = i.split('|')[1];
            const instance = instancesMap[facilityId + service];

            if (instance !== undefined && !module.state.recentInstances.includes(instance)) {
                commit('pushRecentInstance', instance);
            }
        });

        commit('setLockRecentInstances', false);
    }
};

module.actions = {
    ...module.actions,
    ...actions
};

const toMap = (list, key1, key2 = null) => {
    const map = {};

    list.forEach(i => {
        if (key2 === null) {
            map[i[key1]] = i;
        } else {
            map[i[key1] + i[key2]] = i;
        }
    });

    return map;
};

module.getters = {
    instancesComputed: (state, getters, rootState, rootGetters) => {
        let instances = Vue.prototype.$clone(state.list);

        instances.forEach(i => {
            set(i, 'serverTime', DateLib.formatFromString(i.serverTime));
            set(i, 'hasBackup', rootGetters.backupsFacilityIdServiceMap[i.facilityId + i.service] !== undefined);
            set(i, 'uniqueKey', i.facilityId + i.service);
            set(i, 'hotelType', i.service === 'enterprise' ? i.hosted ? 'Cloud' : 'On-prem' : 'OtrumCast');
            set(i, 'offlineTvs', i.numberOfClients - i.clientsOnline);

            const note = rootGetters.notesIdMap[i.facilityId + i.service];
            set(i, 'marsha', note === undefined ? null : note.marshaCode);

            set(i, 'maxClientsFormatted',
                ((i.maxClients === undefined || i.maxClients === -1 || i.maxClients === 0) ? "" : i.maxClients));

            const screenshot = rootGetters['tvScreenshots/tvScreenshotsIdMap'][i.facilityId];
            set(i, 'tvScreenshotUrl', screenshot === undefined ? null : 'https://ost.otrum.net/' + screenshot.screenshotUrl);
            set(i, 'tvUrl', screenshot === undefined ? null : screenshot.tvUrl);
            set(i, 'hasTvScreenshot', screenshot !== undefined);

            set(i, 'standalone', i.service !== 'enterprise' && getters.instancesFacilityIdServiceMap[i.facilityId + 'enterprise'] === undefined);
            set(i, 'occupied', i.occupancy === null ? '' : (i.occupancy + ' (' + (Math.floor((i.occupancy / i.roomCnt) * 100)) + '%)'));

            if (isNaN(parseFloat(i.lat)) || isNaN(parseFloat(i.lng))) {
                set(i, 'lat', 53.274248);
                set(i, 'lng', -6.267301);
                console.error(i.name + " is missing coords!");
            }

            const clientFirmwares = i.clientFirmwares;
            let clientFirmwaresList = [];

            if (clientFirmwares != null) {
                clientFirmwares.split('<br />').forEach(f => {
                    if (!clientFirmwaresList.includes(f) && !f.includes('null') && f !== '' && f !== null) {
                        clientFirmwaresList.push(f);
                    }
                });
            }

            set(i, 'clientFirmwaresList', clientFirmwaresList);
        });

        return instances;
    },
    instancesFilteredComputedByService: (state, getters) => (service) => {
        const instances = getters.instancesFiltered;
        return instances.filter(item => {
            return item.service === service;
        });
    },
    instancesFiltered: (state, getters, rootState, rootGetters) => {
        let instances = getters.instancesComputed;

        switch (state.filterKey) {
            case 'state': {
                switch (state.filterVal) {
                    case 'hotels':
                    case 'hotelScreens':
                        instances = instances.filter(i => i.service === 'enterprise');
                        break;
                    case 'cloudHotels':
                        instances = instances.filter(i => i.service === 'enterprise' && i.hosted);
                        break;
                    case 'onPremHotels':
                        instances = instances.filter(i => i.service === 'enterprise' && !i.hosted);
                        break;
                    case 'offlineHotels':
                        instances = instances.filter(i => i.service === 'enterprise' && i.online === false);
                        break;
                    case 'onlineHotels':
                        instances = instances.filter(i => i.service === 'enterprise' && i.online === true);
                        break;
                    case 'casts':
                    case 'castScreens':
                        instances = instances.filter(i => i.service !== 'enterprise');
                        break;
                    case 'offlineCasts':
                        instances = instances.filter(i => i.service !== 'enterprise' && i.online === false);
                        break;
                    case 'onlineCasts':
                        instances = instances.filter(i => i.service !== 'enterprise' && i.online === true);
                        break;
                }
            }
                break;
            case 'enterpriseSoftwareVersion': {
                instances = instances.filter(i => i.service === 'enterprise' && typeof i.version === 'string' && i.version.startsWith(state.filterVal));
                break;
            }
            case 'chromecastSoftwareVersion': {
                instances = instances.filter(i => i.service === 'chromecast' && typeof i.version === 'string' && i.version.startsWith(state.filterVal));
                break;
            }
            case 'group': {
                instances = instances.filter(i => i.group === state.filterVal);
                break;
            }
            case 'backupDays': {
                const days = parseInt(state.filterVal);
                if (days === -1) {
                    instances = instances.filter(i => {
                        return (i.service !== 'enterprise' && i.hasBackup === false)
                    });
                } else {
                    const now = new Date();
                    instances = instances.filter(i => {
                        if (i.service !== 'enterprise') {
                            return;
                        }
                        const backup = rootGetters.backupsFacilityIdServiceMap[i.facilityId + i.service];
                        if (!backup) {
                            return false;
                        }
                        const daysOld = DateLib.daysBetween(now, new Date(backup.timeStamp));
                        switch (days) {
                            case 1:
                                return (daysOld < 2);
                            case 6:
                                return (daysOld > 1 && daysOld < 6);
                            default:
                        }
                        return (daysOld > 5);
                    });
                }
            }
        }
        return instances;
    },
    instancesComputedFacilityIdServiceMap: (state, getters) => {
        return toMap(getters.instancesComputed, 'facilityId', 'service');
    },
    instancesFacilityIdServiceMap: (state) => {
        return toMap(Vue.prototype.$clone(state.list), 'facilityId', 'service');
    }
};

export default module;