<template>
    <v-bottom-sheet
        v-model="show"
        v-if="$vuetify.breakpoint.mdAndDown"
        scrollable
    >
        <InstanceActions :instance="instance" @close="show = false"></InstanceActions>
    </v-bottom-sheet>
    <v-menu
        v-else
        :position-x="mousePos.x"
        :position-y="mousePos.y"
        v-model="show"
        max-height="70%"
        min-width="300px"
    >
        <InstanceActions :instance="instance"></InstanceActions>
    </v-menu>
</template>

<script>
    import InstanceActions from "./InstanceActions";

    export default {
        name: "InstanceActionsMenu",
        components: {InstanceActions},
        props: {
            value: Boolean,
            instance: Object,
        },
        data: () => ({
            mousePos: {
                x: 0,
                y: 0
            },
        }),
        computed: {
            show: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        watch: {
            show(s) {
                if (s) {
                    this.mousePos = {
                        x: this.$store.state.mousePos.x,
                        y: this.$store.state.mousePos.y,
                    }
                }
            }
        },
    }
</script>