<!--suppress JSUnfilteredForInLoop -->
<template>
    <DashboardCard
        :title="orderBy === 'model' ? 'TV Models(minimum 500)' : 'TV Types'"
        content-padding
    >
        <template v-slot:content>
            <VueApexChart
                v-if="$route.name === 'home'"
                height="270"
                type="bar"
                :series="series"
                :options="options"
            ></VueApexChart>
        </template>
        <template v-slot:titleActions>
            <v-btn-toggle
                    v-model="orderBy"
                    style="margin-right: 16px;"
            >
                <v-btn value="vendor">
                    Vendors
                </v-btn>
                <v-btn value="model">
                    Models
                </v-btn>
            </v-btn-toggle>

        </template>

    </DashboardCard>
</template>

<script>
    import DateLib from "../../lib/date";
    import DashboardCard from "../core/dashboard/DashboardCard";
    import VueApexChart from 'vue-apexcharts';
    import {mapGetters} from "vuex";

    export default {
        name: "HomeTvTypes",
        components: {DashboardCard, VueApexChart},
        data: () => ({
            orderBy: 'vendor',
            options: {
                chart: {
                    toolbar: {
                        show: true,
                    },
                },
                xaxis: {
                    labels: {
                        show: true
                    },
                    type: 'string'
                },
                colors: ['#8535AF'],
            },
        }),
        computed: {
            ...mapGetters({
                instancesFiltered: 'instances/instancesFiltered'
            }),
            series() {
                const data = [];
                const typesSeen = {};
                this.instancesFiltered.forEach(i => {
                    if(i.service === 'enterprise' && typeof i.clientFirmwares === 'string') {
                        i.clientFirmwares.split('<br />').forEach(f => {
                            const infos = f.split('|')[0].trim().split('/');
                            let type = infos[0];
                            if(typeof type === 'string' && type.length > 1 && type !== 'null') {
                                type = type.toUpperCase();
                                if(type === 'SAMSUNG_TIZEN') {
                                    type = 'SAMSUNG';
                                } else if(type === 'TPVISION') {
                                    type = 'PHILIPS';
                                }
                                if(this.orderBy === 'model' && infos.length > 1 && typeof infos[1] === 'string') {
                                    type += ' ' + infos[1].split(' ')[0];
                                }
                                const parts = f.split(' ');
                                const count = parts[parts.length - 1].length >= 3 ? parseInt(parts[parts.length - 1].substr(1, parts[parts.length - 1].length - 2)) : 0;
                                if (typesSeen.hasOwnProperty(type)) {
                                    data[typesSeen[type]].y += isNaN(count) ? 0 : count;
                                } else {
                                    typesSeen[type] = data.length;
                                    data.push({x: type, y: isNaN(count) ? 0 : count});
                                }
                            }
                        });
                    }
                });
                for(let idx = 0; idx < data.length; idx++) {
                    if(data[idx].y < (this.orderBy === 'model' ? 500 : 10)) {
                        data.splice(idx, 1);
                        idx--;
                    }
                }
                return [
                    {
                        name: 'TV models',
                        data: data,
                    }
                ]
            }
        }
    }
</script>