<!--suppress JSUnfilteredForInLoop -->
<template>
    <DashboardCard
        title="Enterprise Backup"
        content-padding
    >
        <template v-slot:content>
            <VueApexChart
                v-if="$route.name === 'home'"
                height="270"
                type="donut"
                :series="series"
                :options="options"
                @dataPointSelection="onChartSelection"
            ></VueApexChart>
        </template>
    </DashboardCard>
</template>

<script>
    import DateLib from "../../lib/date";
    import DashboardCard from "../core/dashboard/DashboardCard";
    import VueApexChart from 'vue-apexcharts';

    export default {
        name: "HomeBackups",
        components: {DashboardCard, VueApexChart},
        data: () => ({
            options: {},
            days: []
        }),
        computed: {
            series() {
                const data = {};
                this.days = [];

                this.$store.state.instances.list.forEach(i => {
                    if(i.service !== 'enterprise') {
                        return;
                    }
                    const backup = this.$store.getters.backupsFacilityIdServiceMap[i.facilityId + i.service];

                    if (backup === undefined) {
                        if (data[-1] === undefined) { data[-1] = 0; }
                        data[-1] += 1;
                    } else {
                        const daysOld = DateLib.daysBetween(new Date(), new Date(backup.timeStamp));
                        let category = 1;

                        if (daysOld > 1 && daysOld < 6) {
                            category = 6;
                        } else if (daysOld > 5) {
                            category = 10000;
                        }

                        if (!data.hasOwnProperty(category)) { data[category] = 0; }
                        data[category] += 1;
                    }
                });

                const series = [];
                const categories = [];
                const colors = [];
                const colorByCat = {
                    1: '#008ffb',
                    6: '#004a88',
                    10000: '#973a1c',
                    '-1': '#b71c1c'
                };
                const labelByCat = {
                    1: 'Today',
                    6: 'Less than 5 days old',
                    10000: 'Older than 5 days',
                    '-1': 'None'
                };

                for (let key in data) {
                    series.push(
                        data[key]
                    );
                    colors.push(colorByCat[key]);
                    categories.push(labelByCat[key]);
                    this.days.push(key);
                }

                this.options = {
                    labels: categories,
                    colors: colors
                };

                return series;
            }
        },
        methods: {
            onChartSelection(ev, ctx, cfg) {
                this.$store.dispatch('instances/applyFilter', { key: 'backupDays', val: this.days[cfg.dataPointIndex] });
                //this.$router.push('/list');
            }
        }

    }
</script>