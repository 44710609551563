<template>
    <div
        v-if="$vuetify.breakpoint.mdAndDown"
        class="the-skeleton-loader"
    >
        <v-bottom-navigation
            app
            dark
            background-color="primary"
        >
            <template v-for="i in [0, 1, 2, 3, 4]">
                <v-skeleton-loader
                    type="button"
                ></v-skeleton-loader>
            </template>
        </v-bottom-navigation>

        <v-main>
            <div
                v-if="$route.name === 'home' || $route.name === 'details'"
                class="home-skeleton"
            >
                <v-skeleton-loader
                    type="text"
                    width="80%"
                ></v-skeleton-loader>
                <v-layout wrap>
                    <template v-for="i in [0, 1, 2, 3, 4]">
                        <v-flex
                            xs12
                            lg6
                        >
                            <v-skeleton-loader
                                type="card"
                                class="ma-2"
                            ></v-skeleton-loader>
                        </v-flex>
                    </template>
                </v-layout>
            </div>
            <div
                v-else-if="$route.name === 'map'"
                class="map-skeleton"
            >
                <v-skeleton-loader
                    type="image"
                ></v-skeleton-loader>
            </div>
            <div v-else>
                <v-app-bar
                    app
                    color="primary"
                    dark
                >
                    <v-skeleton-loader
                        type="text"
                        width="100%"
                    ></v-skeleton-loader>
                </v-app-bar>

                <template v-for="i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
                    <v-skeleton-loader
                        type="list-item-avatar-two-line"
                        width="100%"
                    ></v-skeleton-loader>
                </template>
            </div>
        </v-main>
    </div>
    <div v-else class="the-skeleton-loader">
        <v-app-bar
            app
            dark
            color="primary"
        >
            <template v-for="i in [0, 1, 2, 3, 4]">
                <v-skeleton-loader
                    type="button"
                ></v-skeleton-loader>
            </template>
            <v-spacer></v-spacer>
            <v-skeleton-loader
                type="text"
                width="200px"
            ></v-skeleton-loader>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <template v-for="i in [0, 1, 2]">
                <v-skeleton-loader
                    type="button"
                ></v-skeleton-loader>
            </template>
        </v-app-bar>
        <v-main>
            <div
                v-if="$route.name === 'home' || $route.name === 'details'"
                class="home-skeleton"
            >
                <v-skeleton-loader
                    type="text"
                    width="300px"
                ></v-skeleton-loader>
                <v-layout wrap>
                    <v-flex
                            xs12
                            lg7
                    >
                        <v-skeleton-loader
                                type="card"
                                height="500"
                                class="ma-2"
                        ></v-skeleton-loader>
                    </v-flex>
                    <v-flex
                            xs12
                            lg5
                    >
                        <v-skeleton-loader
                                type="card"
                                height="500"
                                class="ma-2"
                        ></v-skeleton-loader>
                    </v-flex>

                    <template v-for="i in [0, 1, 2]">
                        <v-flex
                            xs12
                            lg4
                        >
                            <v-skeleton-loader
                                type="card"
                                class="ma-2"
                            ></v-skeleton-loader>
                        </v-flex>
                    </template>
                </v-layout>
            </div>
            <div
                v-else-if="$route.name === 'map'"
                class="map-skeleton"
            >
                <v-skeleton-loader
                    type="image"
                ></v-skeleton-loader>
            </div>
            <div v-else>
                <v-skeleton-loader
                    type="table-thead"
                    width="100%"
                    tile
                ></v-skeleton-loader>

                <template v-for="i in [0, 1, 2]">
                    <v-skeleton-loader
                        type="table-tbody"
                        width="100%"
                        tile
                    ></v-skeleton-loader>
                </template>
            </div>
        </v-main>
    </div>
</template>

<script>
    export default {
        name: "TheSkeletonLoader"
    }
</script>

<style lang="scss">
    .md-and-down .the-skeleton-loader {
        .v-bottom-navigation {
            .v-skeleton-loader__button {
                width: calc(100vw / 5 - 20px);
                margin: 10px;
            }
        }

        .v-app-bar {
            .v-skeleton-loader__text {
                height: 40px;
                margin-top: 5px;
            }
        }

        .map-skeleton {
            .v-skeleton-loader__image {
                width: 100vw;
                height: calc(100vh - 50px);
            }
        }

        .home-skeleton {
            margin-top: 12px;

            > .layout {
                padding-left: 8px;
            }

            .v-skeleton-loader__text:first-child {
                padding-left: 8px;
                margin-bottom: 8px;
            }

            .v-skeleton-loader__chip {
                height: 30px;
                width: 80px;
                margin: 0 8px 8px 0;
            }
        }
    }

    .lg-and-up .the-skeleton-loader {
        .v-skeleton-loader__button {
            margin-left: 8px;
        }

        .v-main__wrap {
            padding: 12px;
        }

        .home-skeleton {
            .v-skeleton-loader__chip {
                height: 52px;
                width: 160px;
                margin: 0 12px 12px 0;
            }
        }

        .map-skeleton {
            .v-skeleton-loader__image {
                width: 100vw;
                height: calc(100vh - 100px);
            }
        }
    }
</style>