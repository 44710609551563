<template>
    <DashboardCard
        :title="title"
        content-padding
        no-scroll
    >
        <template v-slot:content>
            <VueApexChart
                v-if="$route.name === 'details'"
                height="270"
                type="line"
                :series="series"
                :options="options"
            ></VueApexChart>
        </template>
    </DashboardCard>
</template>

<script>
    import DashboardCard from "../core/dashboard/DashboardCard";
    import VueApexChart from 'vue-apexcharts';

    export default {
        name: "DetailsConnectivity",
        components: {DashboardCard, VueApexChart},
        props: {
            instance: Object,
            onlineField: String,
            title: String,
        },
        computed: {
            options() {
                return {
                    xaxis: {
                        labels: {
                            show: true,
                        },
                        type: 'datetime'
                    },
                    yaxis: {
                        labels: {
                            show: !this.$vuetify.breakpoint.mdAndDown,
                            formatter: (value) => {
                                if (value === 0 || value === 1) {
                                    return value === 0 ? 'Offline' : 'Online';
                                }

                                return '';
                            }
                        }
                    },
                    tooltip: {
                        x: {
                            formatter: (value) => {
                                return new Date(value).toISOString().replace('T', ' ').substr(0, 19);
                            },
                        }
                    },
                    colors: ['#8535AF'],
                    stroke: {
                        curve: 'stepline',
                        width: 2,
                    },
                    chart: {
                        toolbar: {
                            show: !this.$vuetify.breakpoint.mdAndDown,
                        },
                    },
                }
            },
            series() {
                const now = new Date();

                const data = this.$store.state.connectivity.list.filter(i => {
                    const date = new Date(i.updatedDate);
                    const diffTime = Math.abs(now - date);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                    if (diffDays < 15) {
                        if (this.onlineField === 'online' || (this.onlineField === 'pmsLinkUp' && i.online === true)) {
                            return i;
                        }
                    }
                }).map(i => {
                    return {x: new Date(i.updatedDate), y: i[this.onlineField] ? 1 : 0};
                });

                if (data.length === 0 && this.$store.state.connectivity.list.length > 0) {
                    const event = this.$store.state.connectivity.list[this.$store.state.connectivity.list.length - 1];

                    data.push({
                        x: new Date(),
                        y: event.online ? 1 : 0,
                    });
                }

                if (data.length !== 0) {
                    data.push({
                        x: new Date(),
                        y: data.length > 2 ? data[data.length - 1].y : data[0].y
                    });

                    const fourteenDaysAgo = new Date();
                    fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);

                    data.unshift({
                        x: fourteenDaysAgo,
                        y: data[0].y
                    });
                } else {
                    data.push({
                        x: new Date(),
                        y: 0
                    });

                    const fourteenDaysAgo = new Date();
                    fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);

                    data.unshift({
                        x: fourteenDaysAgo,
                        y: 0
                    });
                }

                return [{
                    name: 'Status',
                    data: data.sort((a, b) => {
                        const aDate = a.x;
                        const bDate = b.x;

                        if (aDate.getTime() < bDate.getTime()) return -1;
                        if (aDate.getTime() === bDate.getTime()) return 0;
                        return 1;
                    })
                }];
            }
        }
    }
</script>