<template>
    <DashboardCard
        title="Virtual TV"
    >
        <template v-slot:content>
            <v-row>
                <v-col>
                    <v-textarea
                        label="TV URL"
                        v-model="tvUrl"
                        hide-details
                        class="virtual-tv-textarea"
                    ></v-textarea>
                </v-col>
            </v-row>
        </template>
        <template v-slot:titleActions>
            <v-btn
                text
                color="accent"
                @click="grabScreenshot"
                :disabled="tvUrl === '' || tvUrl === null || disableGrab"
            >
                Grab Screenshot
            </v-btn>
            <v-btn
                text
                color="accent"
                @click="saveUrl"
                :disabled="tvUrl === '' || tvUrl === null"
                :loading="$store.state.tvScreenshots.processing"
            >
                Save URL
            </v-btn>
        </template>
    </DashboardCard>
</template>

<script>
    import DashboardCard from "../core/dashboard/DashboardCard";

    export default {
        name: "DetailsVirtualTv",
        components: {DashboardCard},
        props: {
            instance: Object
        },
        data: () => ({
            disableGrab: false,
            tvUrl: null,
        }),
        watch: {
            instance: {
                immediate: true,
                handler(i) {
                    if (i != null) {
                        this.tvUrl = i.tvUrl;
                    }
                }
            }
        },
        methods: {
            grabScreenshot() {
                this.disableGrab = true;
                this.$store.dispatch('tvScreenshots/grab', this.instance.facilityId);

                setTimeout(() => {
                    this.disableGrab = false;
                    this.$emit('updateScreenshot');
                }, 20000);
            },
            saveUrl() {
                this.$store.dispatch('tvScreenshots/saveUrl', {facilityId: this.instance.facilityId, url: this.tvUrl});
            }
        }
    }
</script>

<style scoped>
    .row {
        margin: 0;
    }

    img {
        cursor: zoom-in;
    }
</style>

<style>
    .virtual-tv-textarea textarea {
        min-height: 260px !important;
    }
</style>