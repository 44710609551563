<template>
    <v-container>
        <v-toolbar
            dense
            class="py-1"
        >
            Service Status
            <v-spacer></v-spacer>
            <v-btn
                icon
                @click="refresh"
            >
                <v-icon>mdi-reload</v-icon>
            </v-btn>
        </v-toolbar>

        <v-expansion-panels
            focusable
            class="py-1"
        >
            <v-expansion-panel
                v-for="(item) in statuses"
                :key="item.id"
                @change="loadDetails(item)"
            >
                <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                        <v-row no-gutters>
                            <v-icon
                                :color="item.state==='ONLINE' ? 'green' : 'red'"
                                style="padding-right: 20px;"
                            >
                                {{ item.state === 'ONLINE' ? 'mdi-check-circle' : 'mdi-alert' }}
                            </v-icon>
                            <span style="font-weight:bold; margin: auto; padding-right: 10px;">{{
                                    item.systemCapitalized
                                }}</span>
                            <span v-if="open" style="margin: auto;">{{ item.name }}</span>
                            <span v-else style="flex: 1; margin: auto;">{{ item.name }}</span>
                            <v-spacer style="flex:1;"></v-spacer>
                            <span style="font-weight:bold; margin: auto; padding-right: 30px;">
                                {{ item.state === 'ONLINE' ? "ONLINE" : "OFFLINE" }}
                            </span>
                        </v-row>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card-title>{{ currStatusText }}</v-card-title>
                    <v-card style="margin-top: 20px;">
                        <v-card-title>Request latency for {{ item.name }}</v-card-title>
                        <VueApexChart
                            height="260px"
                            type="area"
                            :series="currentHistoryConverted"
                            :options="options"
                        ></VueApexChart>
                    </v-card>
                        <v-card style="margin-top: 20px;">
                        <v-card-title
                            v-if="currentItemEvents.length > 0"
                        >Events</v-card-title>
                        <v-container
                            v-if="currentItemEvents.length > 0"
                            class="elevation-1"
                        >
                            <v-data-table
                                dense
                                :headers="eventHeaders"
                                :items="currentItemEvents"
                                :items-per-page="6"
                            >
                            </v-data-table>
                        </v-container>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>


    </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import VueApexChart from 'vue-apexcharts';

export default {
    name: "Status",
    components: {VueApexChart},
    data: () => ({
        currStatusItem: null,
        currStatusData: null,
        currentHistoryConverted: [{name: '', data: []}],
        currentItemEvents: [],
        eventHeaders: [
            {
                text: 'Status',
                value: 'state'
            },
            {
                text: 'Time',
                value: 'date'
            }
        ],
        options: {
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
                style: 'hollow',
            },
            xaxis: {
                type: 'datetime',
                tickAmount: 6,
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 100]
                }
            },
        }
    }),
    methods: {
        refresh() {
            this.$store.dispatch('serviceStatus/fetch');
            if(this.currStatusItem) {
                this.loadDetails(this.currStatusItem);
            }
        },
        async loadDetails(item) {
            this.currStatusItem = item;
            this.currStatusData = null;
            this.currentItemEvents = [];
            this.currentHistoryConverted = [{name: '', data: []}];
            if (!item) {
                return;
            }
            this.$nextTick(async () => {
                const response = await fetch('https://status.thecloudportal.com/api/' + item.id, {
                    method: 'GET'
                });

                const data = await response.json();
                const convertedHistory = [];
                data.history.forEach(item => {
                    const parts = item.split('|');
                    convertedHistory.push({
                        x: parseInt(parts[2]),//DateLib.formatToString(d),
                        y: parts[0] === 'ONLINE' ? parseFloat(parts[1]) : 0
                    });
                });
                this.currStatusData = data;
                data.events.forEach(item => {
                    const parts = item.split('|');
                    this.currentItemEvents.push({
                        state: parts[1],
                        date: new Date(parseInt(parts[3]))
                    });
                });
                this.currentHistoryConverted = [
                    {
                        name: 'Seconds',
                        data: convertedHistory
                    }
                ];
            });
        }
    },
    watch: {},
    computed: {
        ...mapGetters({
            statuses: 'serviceStatus/statusesComputed',
        }),
        currStatusText() {
            if (!this.currStatusItem) {
                return '';
            }
            let text = this.currStatusItem.name;
            text += (this.currStatusItem.state === 'ONLINE' ? ' UP ' : ' DOWN ');

            if (this.currStatusData && this.currStatusData.events && this.currStatusData.events.length > 0) {
                const parts = this.currStatusData.events[0].split('|');
                const ts = new Date(parseInt(parts[3]));
                text += " since " + ts;
            } else {
                text += " since forever";
            }

            return text;
        }
    }
}
</script>

<style scoped>
</style>