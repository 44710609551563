import API from '../../lib/api'
import Vue from 'vue'
import crudModule from "./crudModule";

const module = crudModule('TV screenshot', '/facilities/tvScreenshot');

const toMap = (list, key1, key2 = null) => {
    const map = {};

    list.forEach(i => {
        if (key2 === null) {
            map[i[key1]] = i;
        } else {
            map[i[key1] + i[key2]] = i;
        }
    });

    return map;
};

module.actions['grab'] = ({commit}, facilityId) => {
    API.request('POST', module.path + `/grab/${facilityId}`);
    Vue.prototype.$alert.success('Grabbing TV screenshot - this may take a while');
};

module.actions['saveUrl'] = async ({commit}, {facilityId, url}) => {
    commit('setProcessing', true);

    const response = await API.request('POST', module.path + `/saveUrl/${facilityId}`, {
        tvUrl: url
    }, false);

    if (response.success) {
        const item = module.state.list.filter(i => i.facilityId === facilityId)[0];

        if (item !== undefined) {
            item.tvUrl = url;
            commit('itemEdited', item);
        }
    }

    commit('setProcessing', false);
};

module.getters = {
    tvScreenshotsIdMap: (state, getters) => {
        return toMap(Vue.prototype.$clone(state.list), 'facilityId');
    }
};

export default module;