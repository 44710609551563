<template>
    <DashboardCard
        :title="title"
        content-padding
        :height="380"
        no-scroll
    >
        <template v-slot:titleActions>
            <v-btn
                icon
                @click="fetchData"
            >
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
        </template>
        <template v-slot:content>
            <div v-if="error === null">
                <v-text-field
                    readonly
                    v-model="uptime"
                    label="Uptime"
                ></v-text-field>
                <v-text-field
                    readonly
                    v-model="clients"
                    label="Clients"
                ></v-text-field>
                <v-text-field
                    readonly
                    v-model="webSocket"
                    label="WebSocket server"
                ></v-text-field>
                <v-textarea
                    readonly
                    v-model="memory"
                    label="Memory"
                    class="home-push-server-text-area"
                ></v-textarea>
            </div>
            <v-subheader v-else>{{error}}</v-subheader>
        </template>
    </DashboardCard>
</template>

<script>
    import DashboardCard from "../core/dashboard/DashboardCard";

    export default {
        name: "HomePushServer",
        components: {DashboardCard},
        props: {
            title: String,
            url: String,
        },
        data: () => ({
            uptime: null,
            memory: null,
            webSocket: null,
            clients: null,
            error: null,
        }),
        created() {
            this.fetchData();

            this.fetchInterval = setInterval(() => {
                this.fetchData();
            }, 600000);
        },
        destroyed() {
            clearInterval(this.fetchInterval);
        },
        methods: {
            async fetchData() {
                const response = await fetch(this.url);

                if (response.status === 200) {
                    const json = await response.json();
                    const item = json.items[0];

                    this.clients = item.clientCount;

                    const startDiff = new Date() - new Date(item.startTime);
                    const dateDiff = new Date(startDiff);
                    this.uptime = `${dateDiff.getDate() - 1} days, ${dateDiff.getHours()} hours, ${dateDiff.getMinutes()} mins`;

                    this.webSocket = `TX: ${item.ws.tx}, RX: ${item.ws.rx}`;
                    this.memory = `RSS: ${item.mem.rss}\nHeap total: ${item.mem.heapTotal}\nHeap used: ${item.mem.heapUsed}`;
                } else {
                    this.error = 'Failed to fetch server data.';
                }
            }
        }
    }
</script>

<style>
    .home-push-server-text-area textarea {
    }
</style>