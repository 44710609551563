<!--suppress JSUnfilteredForInLoop -->
<template>
    <DashboardCard
        title="Versions"
        :height="400"
        content-padding
    >
        <template v-slot:content>
            <VueApexChart
                v-if="$route.name === 'home'"
                height="270"
                type="donut"
                :series="series"
                :options="options"
                @dataPointSelection="onChartSelection"
            ></VueApexChart>
        </template>
        <template v-slot:titleActions>
            <v-btn-toggle
                v-model="major"
                style="margin-right: 16px;"
            >
                <v-btn value="major">
                    {{major ? 'Major' : 'Minor'}}
                </v-btn>
            </v-btn-toggle>

            <v-btn-toggle
                v-model="service"
                mandatory
            >
                <v-btn value="enterprise">
                    <v-icon>mdi-office-building</v-icon>
                </v-btn>
                <v-btn value="chromecast">
                    <v-icon>mdi-google-chrome</v-icon>
                </v-btn>
            </v-btn-toggle>
        </template>
    </DashboardCard>
</template>

<script>
    import DashboardCard from "../core/dashboard/DashboardCard";
    import VueApexChart from 'vue-apexcharts';

    export default {
        name: "HomeVersions",
        components: {DashboardCard, VueApexChart},
        data: () => ({
            service: 'enterprise',
            major: 'major',
            options: {}
        }),
        computed: {
            series() {
                const data = {};

                this.$store.state.instances.list.forEach(i => {
                    if (i.service === this.service && i.version !== null) {
                        let version = i.version;

                        if (this.major === 'major') {
                            version = i.version.split('.');
                            version = version[0] + '.' + version[1];
                        }

                        if (data[version] === undefined) data[version] = 1;
                        else data[version] += 1;
                    }
                });

                const series = [];
                const categories = [];

                for (let key in data) {
                    series.push(
                        data[key]
                    );

                    categories.push(key);
                }

                this.options = {
                    labels: categories,
                };

                return series;
            }
        },
        methods: {
            onChartSelection(ev, ctx, cfg) {
                this.$store.dispatch('instances/applyFilter', { key: this.service + 'SoftwareVersion', val: this.options.labels[cfg.dataPointIndex] });
                //this.$router.push('/list');
            }
        }
    }
</script>