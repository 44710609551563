<template>
    <v-bottom-navigation
        color="white"
        dark
        background-color="primary"
        app
        v-if="$store.state.clientToken !== null"
        class="the-bottom-navigation"
        :height="$store.state.iPhoneX ? 80 : 56"
    >
        <v-btn to="/">
            <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn to="/list">
            <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>
        <v-btn to="/map">
            <v-icon>mdi-map</v-icon>
        </v-btn>
        <v-btn to="/alerts">
            <v-icon>mdi-email-alert</v-icon>
        </v-btn>
        <v-btn to="/users" v-if="$store.state.isAdmin">
            <v-icon>mdi-account-group</v-icon>
        </v-btn>
        <v-btn to="/hotels" v-if="$store.state.isAdmin">
            <v-icon>mdi-office-building</v-icon>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
    export default {
        name: "TheBottomNavigation",
    }
</script>

<style lang="scss">
    .the-bottom-navigation .v-btn {
        width: 25% !important;
        min-width: 25% !important;
        max-width: 25% !important;
    }

    .is-admin .the-bottom-navigation .v-btn {
        width: 16.7% !important;
        min-width: 16.7% !important;
        max-width: 16.7% !important;
    }

    .iphone-x .the-bottom-navigation {
        .v-btn {
            padding-bottom: 24px;
        }
    }
</style>