<!--suppress JSUnfilteredForInLoop -->
<template>
    <DashboardCard
        :title='"Partners by " + (byScreens ? "screen count " : "sites ") + service'
        content-padding
    >
        <template v-slot:content>
            <VueApexChart
                v-if="$route.name === 'home'"
                height="270"
                type="bar"
                :series="series"
                :options="options"
                @dataPointSelection="onChartSelection"
            ></VueApexChart>
        </template>
    </DashboardCard>
</template>

<script>
    import DateLib from "../../lib/date";
    import DashboardCard from "../core/dashboard/DashboardCard";
    import VueApexChart from 'vue-apexcharts';
    import {mapGetters} from "vuex";

    export default {
        name: "HomeGroups",
        components: {DashboardCard, VueApexChart},
        props: {
            service: String,
            byScreens: Boolean,
        },
        data: () => ({
            orderBy: 'vendor',
            options: {
                chart: {
                    toolbar: {
                        show: true,
                    },
                },
                xaxis: {
                    labels: {
                        show: true
                    },
                    type: 'string'
                },
                colors: ['#8535AF'],
            },
            data: []
        }),
        computed: {
            ...mapGetters({
                instancesFiltered: 'instances/instancesFiltered'
            }),
            series() {
                const data = [];
                const groupsSeen = {};
                this.instancesFiltered.forEach(i => {
                    if(!i.group) {
                        return;
                    }
                    if(i.service !== this.service) {
                        return;
                    }
                    if(!groupsSeen.hasOwnProperty(i.group)) {
                        groupsSeen[i.group] = data.length;
                        data.push({x: i.group, y: 0});
                    }
                    if(this.byScreens) {
                        data[groupsSeen[i.group]].y += i.numberOfClients;
                    } else {
                        data[groupsSeen[i.group]].y++;
                    }
                });

                this.data = data.sort((first, second) => { return (second.y - first.y); });
                return [
                    {
                        name: this.byScreens ? 'Screens' : 'Sites',
                        data: data,
                    }
                ]
            }
        },
        methods: {
            onChartSelection(ev, ctx, cfg) {
                const partner = this.data[cfg.dataPointIndex].x;
                this.$store.dispatch('instances/applyFilter', { key: 'group', val: partner });
            }
        }
    }
</script>