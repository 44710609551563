<template>
    <DialogEditor
        v-model="show"
        :title="edit ? 'Edit Condition' : 'Update Condition'"
    >
        <template v-slot:content>
            <v-autocomplete
                :items="conditions"
                v-model="selectedCondition"
                label="Condition"
            ></v-autocomplete>
            <v-textarea
                v-model="comment"
                label="Comment"
            ></v-textarea>
        </template>
        <template v-slot:actions>
            <v-btn
                color="error"
                v-show="edit"
                @click="deleteCondition"
                :text="$vuetify.breakpoint.mdAndDown"
            >
                Delete
            </v-btn>
            <v-btn
                color="accent"
                @click="save"
                :text="$vuetify.breakpoint.mdAndDown"
            >
                {{edit ? 'Save' : 'Update'}}
            </v-btn>
        </template>
    </DialogEditor>
</template>

<script>
    import DialogEditor from "../core/DialogEditor";
    import DateLib from "../../lib/date";

    export default {
        name: "DetailsConditionEditor",
        components: {DialogEditor},
        props: {
            value: Boolean,
            condition: Object,
            edit: Boolean,
            instance: Object,
        },
        data: () => ({
            comment: null,
            selectedCondition: 'New',
            conditions: [
                'New',
                'In progress',
                'Issues',
                'OK'
            ],
        }),
        computed: {
            show: {
                get() {
                    return this.value;
                },
                set(input) {
                    this.$emit('input', input);
                }
            },
        },
        watch: {
            show(s) {
                if (s) {
                    this.comment = this.edit ? this.condition.comment : null;
                    this.selectedCondition = this.edit ? this.condition.condition : 'New';
                }
            }
        },
        methods: {
            save() {
                const condition = this.edit ? this.condition : {
                    facilityId: this.instance.facilityId,
                    serviceType: this.instance.service,
                };

                this.$store.dispatch('conditions/save', {
                    edit: this.edit, item: {
                        facilityId: condition.facilityId,
                        serviceType: condition.serviceType,
                        condition: this.selectedCondition,
                        comment: this.comment,
                        userAccountId: this.$store.state.userAccountId,
                        id: this.edit ? condition.id : undefined,
                        timeStamp: DateLib.formatToString(new Date()),
                    }
                });

                this.show = false;
            },
            deleteCondition() {
                this.$confirm.delete('Delete Condition', 'Are you sure you wish to delete this condition?', () => {
                    this.$store.dispatch('conditions/delete', {item: this.condition});
                    this.show = false;
                });
            },
        }
    }
</script>