<template>
    <v-list>
        <InstanceTypeListItem
            :type="null"
            icon="mdi-domain"
            title="All instances"
            :number="$store.state.instances.list.length"
        ></InstanceTypeListItem>

        <div style="height: 20px;"></div>

        <InstanceTypeListItem
            type="hotels"
            icon="mdi-hospital-building"
            title="Enterprise hotels"
            :number="$store.state.instances.typeCount.hotels"
        ></InstanceTypeListItem>
        <InstanceTypeListItem
            type="onPremHotels"
            icon="mdi-office-building"
            title="On-prem hotels"
            :number="$store.state.instances.typeCount.onPremHotels"
        ></InstanceTypeListItem>
        <InstanceTypeListItem
            type="cloudHotels"
            icon="mdi-cloud"
            title="Cloud hotels"
            :number="$store.state.instances.typeCount.cloudHotels"
        ></InstanceTypeListItem>
        <InstanceTypeListItem
            type="offlineHotels"
            icon="mdi-flash-circle"
            title="Offline hotels"
            :number="$store.state.instances.typeCount.offlineHotels"
        ></InstanceTypeListItem>
        <InstanceTypeListItem
                v-if="false"
            type="hotelScreens"
            icon="mdi-television-classic"
            title="Enterprise TVs"
            :number="$store.state.instances.typeCount.hotelScreens"
        ></InstanceTypeListItem>

        <div style="height: 20px;"></div>

        <InstanceTypeListItem
            type="casts"
            icon="mdi-google-chrome"
            title="OtrumCasts"
            :number="$store.state.instances.typeCount.casts"
        ></InstanceTypeListItem>
        <InstanceTypeListItem
            type="offlineCasts"
            icon="mdi-flash-circle"
            title="Offline OtrumCasts"
            :number="$store.state.instances.typeCount.offlineCasts"
        ></InstanceTypeListItem>
        <InstanceTypeListItem
            type="castScreens"
            icon="mdi-television-classic"
            title="OtrumCast TVs"
            v-if="false"
            :number="$store.state.instances.typeCount.castScreens"
        ></InstanceTypeListItem>
    </v-list>
</template>

<script>
    import InstanceTypeListItem from "./InstanceTypeListItem";

    export default {
        name: "InstanceTypeList",
        components: {InstanceTypeListItem},
    }
</script>