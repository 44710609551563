<template>
    <v-menu
        offset-y
        class="search-field-menu"
        max-height="70%"
        :close-on-content-click="false"
        v-model="showMenu"
        :close-delay="focused ? 1000 : 100"
    >
        <template v-slot:activator="{on}">
            <v-text-field
                class="search-field"
                v-on="on"
                solo-inverted
                single-line
                hide-details
                placeholder="Search for hotels"
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                clearable
                @focusout="focused = false"
                @focusin="focused = true"
            >
                <template v-slot:prepend-inner>
                    <v-progress-circular
                        size="24"
                        :color="focused ? 'accent' : 'white'"
                        indeterminate v-if="searching"
                    ></v-progress-circular>
                    <v-icon v-else>mdi-magnify</v-icon>
                </template>
            </v-text-field>
        </template>
        <v-card>
            <v-card-title>
                {{search === null || search.length < 2 ? 'Recently viewed' : 'Results'}}
                <v-spacer></v-spacer>
                <v-col class="pa-0 search-by">
                    <v-row>
                        <div class="search-by-title">Search by</div>
                    </v-row>
                    <v-row>
                        <v-btn-toggle v-model="searchBy">
                            <v-btn
                                value="name"
                                x-small
                            >
                                Name
                            </v-btn>
                            <v-btn
                                value="all"
                                x-small
                            >
                                All
                            </v-btn>
                        </v-btn-toggle>
                    </v-row>
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-list>
                    <v-subheader v-show="(search === null || search.length < 2) && items.length === 0">
                        You have not viewed any hotels yet.
                    </v-subheader>
                    <v-subheader v-show="search !== null && search.length > 1 && items.length === 0">
                        There are no hotels matching the search.
                    </v-subheader>

                    <v-list-item
                        v-for="item in items"
                        :key="item.id"
                        @click="selectInstance(item)"
                    >
                        <v-list-item-icon>
                            <v-icon :color="item.online ? 'success' : 'error'">
                                {{item.service === 'enterprise' ? item.hosted ? 'mdi-cloud' : 'mdi-office-building' : 'mdi-google-chrome'}}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{item.name}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
    import Storage from "../../lib/storage";
    import {mapGetters} from "vuex";

    export default {
        name: "TheSearchField",
        data: () => ({
            search: null,
            items: [],
            recentInstances: [],
            searchBy: 'all',
            showMenu: false,
            focused: false,
            searching: false,
        }),
        created() {
            this.searchBy = Storage.get('searchFieldSearchBy', 'all');
        },
        watch: {
            searchBy(s) {
                const old = this.search;

                this.search = null;

                this.$nextTick(() => {
                    this.search = old;
                });

                Storage.set('searchFieldSearchBy', s);
            },
            showMenu(s) {
                if (!s && this.focused) {
                    setTimeout(() => {
                        this.showMenu = true;
                    }, 1);
                }
            },
            search: {
                immediate: true,
                handler(s) {
                    this.searching = typeof s === 'string' && s.length > 1;

                    clearTimeout(this.searchTimeout);

                    this.searchTimeout = setTimeout(() => {
                        if (s === null || s.length < 2) this.items = this.$store.state.instances.recentInstances;
                        else {
                            this.items = this.$store.state.instances.list
                                .filter(i => this.searchBy === 'name' ? i.name.toLowerCase().includes(s.toLowerCase()) :
                                    Object.values(i).toString().toLowerCase().includes(s.toLowerCase()));
                        }

                        this.searching = false;
                    }, s === null || s.length > 4 ? 400 : 800);
                },
            },
            '$store.state.instances.fetched'(newValue, oldValue) {
                if (oldValue === null) {
                    this.$store.dispatch('instances/retrieveRecentInstances', this.instancesFacilityIdServiceMap);
                }
            }
        },
        methods: {
            selectInstance(i) {
                this.showMenu = false;
                this.$router.push({path: '/details', query: {facilityId: i.facilityId, service: i.service}});
            },
        },
        computed: {
            ...mapGetters({
                instancesComputedFacilityIdServiceMap: 'instances/instancesComputedFacilityIdServiceMap'
            }),
        }
    }
</script>

<style scoped>
    .v-menu__content {
        top: 52px !important;
    }

    .v-text-field {
        min-width: 300px;
    }

    .v-card__title {
        padding-bottom: 6px;
    }

    .v-list {
        padding-top: 0;
        min-width: 500px;
    }

    .v-card__text {
        padding: 0;
        max-height: 60vh;
        overflow-y: auto;
    }

    .search-by {
        max-width: 92px;
    }

    .search-by .search-by-title {
        font-size: 12px;
        opacity: 0.8;
        height: 16px;
        line-height: 16px;
    }

    .search-by .row:last-child {
        padding-bottom: 4px;
        padding-top: 1px;
    }
</style>

<style>
    .search-field.v-input--is-focused ::placeholder {
        color: black !important;
        opacity: 0.6;
        caret-color: black !important;
    }

    .search-field input {
        caret-color: black !important;
    }

    .search-field.v-input--is-focused .v-icon {
        color: black !important;
        opacity: 0.6;
    }
</style>