<!--suppress JSUnfilteredForInLoop -->
<template>
    <v-app :class="appClasses">
        <TheSkeletonLoader
            v-if="$store.state.authenticating">
        </TheSkeletonLoader>
        <v-main v-else-if="$store.state.clientToken !== null || $route.query.clientToken !== undefined">
            <TheBottomNavigation
                v-if="$vuetify.breakpoint.mdAndDown"
            ></TheBottomNavigation>
            <TheAppBar
                @refresh="fetchAllData"
                v-if="!$route.query.clientToken && !$vuetify.breakpoint.mdAndDown"
            ></TheAppBar>

            <TheInstanceTypeSelector
                    v-if="!$route.query.clientToken && !$vuetify.breakpoint.mdAndDown"
            ></TheInstanceTypeSelector>

            <keep-alive :max="10">
                <router-view :key="$route.path"/>
            </keep-alive>
        </v-main>
        <Login v-else></Login>

        <TheConfirm></TheConfirm>
        <TheAlert></TheAlert>
        <TheProcessingIndicator></TheProcessingIndicator>
        <TheImageViewer></TheImageViewer>
        <TheScrollButton></TheScrollButton>
        <TheUserEditor></TheUserEditor>
        <TheAlertEditor></TheAlertEditor>
        <TheLinkGenerator></TheLinkGenerator>
    </v-app>
</template>

<script>
    import API from "./lib/api";
    import TheAppBar from "./components/app/TheAppBar";
    import TheAlert from "./components/app/TheAlert";
    import TheConfirm from "./components/app/TheConfirm";
    import TheScrollButton from "./components/app/TheScrollButton";
    import TheProcessingIndicator from "./components/app/TheProcessingIndicator";
    import TheImageViewer from "./components/app/TheImageViewer";
    import TheBottomNavigation from "./components/app/TheBottomNavigation";
    import Login from "./views/Login";
    import TheSkeletonLoader from "./components/app/TheSkeletonLoader";
    import InstanceTypeList from "./components/core/instancetype/InstanceTypeList";
    import TheUserEditor from "./components/app/TheUserEditor";
    import TheLinkGenerator from "./components/app/TheLinkGenerator";
    import TheInstanceTypeSelector from "./components/app/TheInstanceTypeSelector";
    import TheAlertEditor from "./components/app/TheAlertEditor";
    import Storage from "./lib/storage";

    export default {
        name: 'App',
        components: {
            TheAlertEditor,
            TheInstanceTypeSelector,
            TheUserEditor,
            InstanceTypeList,
            TheSkeletonLoader,
            Login,
            TheBottomNavigation,
            TheImageViewer,
            TheLinkGenerator,
            TheProcessingIndicator, TheScrollButton, TheConfirm, TheAlert, TheAppBar
        },
        created() {
            if(typeof this.$route.query.clientToken === 'string' && this.$route.query.clientToken.length > 10) {
                Storage.set('clientToken', this.$route.query.clientToken);
            }

            this.$store.dispatch('authenticate');
            this.deviceCheck();

            if (this.$route.name === null) {
                this.$router.push('/');
            }

            this.$router.beforeEach((to, from, next) => {
                if (this.$store.state.clientToken === null) {
                    next();
                } else {
                    if (!this.$store.state.isAdmin && (to.name === 'hotels' || to.name === 'users')) {
                        next('/');
                    } else {
                        next();
                    }
                }
            });

            window.addEventListener('mousemove', (e) => {
                this.$store.dispatch('updateMousePos', {x: e.clientX, y: e.clientY});
            });

            window.addEventListener('scroll', (e) => {
                this.$store.dispatch('updateScrollTop', e.target.scrollingElement.scrollTop);
            });

            this.fetchMiscInterval = setInterval(() => {
                this.fetchMiscData();
            }, 300000);

            this.fetchStatusInterval = setInterval(async () => {
                if (this.$store.state.clientToken !== null) {
                    const response = await API.request('GET', '/status/seconds/120');

                    if (response.success && response.data.numberOfStatuses > 0) {
                        this.fetchStatusData();
                    }
                }
            }, 120000);
        },
        mounted() {
            if(0) {
                this.$confirm.show("We are moving!", "The status overview is moving to the new Otrum Mirage " +
                    "system at <a href='https://thecloudportal.com'>thecloudportal.com</a>!<br>" +
                    "If you don't yet have an account, you can contact support to have one set up for you.<br><br>" +
                    "Proceed to thecloudportal.com?", () => {
                    window.location = "https://thecloudportal.com";
                });
            }
        },
        destroyed() {
            clearInterval(this.fetchMiscInterval);
            clearInterval(this.fetchStatusInterval);
        },
        computed: {
            appClasses() {
                let classes = this.$vuetify.breakpoint.mdAndDown ? 'md-and-down' : 'lg-and-up';
                if (this.$store.state.isAdmin) classes += ' is-admin';
                if (this.$store.state.tablet) classes += ' tablet';
                if (this.$store.state.iPhoneX) classes += ' iphone-x';
                return classes;
            },
        },
        watch: {
            '$store.state.clientToken': {
                immediate: true,
                handler(newToken, oldToken) {
                    if (newToken !== null && oldToken === null) {
                        this.fetchAllData();
                    }

                    if (newToken !== null && !this.$store.state.isAdmin && (this.$route.name === 'hotels' || this.$route.name === 'users')) {
                        this.$router.push('/');
                    }

                    if (newToken === null && oldToken !== null && oldToken !== undefined) {
                        for (let action in this.$store._actions) {
                            if (action.includes('reset')) this.$store.dispatch(action);
                        }
                    }
                },
            },
            '$store.state.instances.fetched'(f) {
                if (f !== null) this.$store.dispatch('instances/updateTypeCount');
            }
        },
        methods: {
            deviceCheck() {
                const ratio = window.devicePixelRatio || 1;

                const screen = {
                    width: window.screen.width * ratio,
                    height: window.screen.height * ratio
                };

                const iPhoneX = ((screen.width === 1125 && screen.height === 2436) || (screen.width === 1242 && screen.height === 2688))
                    && window.navigator.standalone === true;

                this.$store.dispatch('updateIPhoneX', iPhoneX);
                this.$store.dispatch('updateTablet', /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
            },
            fetchAllData() {
                this.fetchMiscData();
                this.fetchStatusData();
            },
            fetchMiscData() {
                if (this.$store.state.clientToken == null) return;

                this.$store.dispatch('users/fetch');
                this.$store.dispatch('owners/fetch');
                this.$store.dispatch('backups/fetch');
                this.$store.dispatch('groups/fetch');
                this.$store.dispatch('notes/fetch');
                this.$store.dispatch('alerts/fetch');
                this.$store.dispatch('conditions/fetch');
                this.$store.dispatch('tvScreenshots/fetch');
                this.$store.dispatch('serviceStatus/fetch');
                this.$store.dispatch('checkStatusFetch');

                if (this.$store.state.isAdmin) {
                    this.$store.dispatch('hotels/fetch');
                }
            },
            fetchStatusData() {
                if (this.$store.state.clientToken == null) return;

                this.$store.dispatch('instances/fetch');
                this.$store.dispatch('eventLogAll/fetch');
            },
        }
    };
</script>

<style scoped>
    .v-main {
        background: white;
    }
</style>

<style lang="scss">
    ::-webkit-scrollbar-track {
        background-color: white;
    }

    ::-webkit-scrollbar {
        width: 14px;
        height: 18px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #b2b4b5;
        height: 6px;
        border: 3px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: 7px;
        cursor: grab;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #939596;
    }

    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    body, html, .v-application {
        background: white !important;
    }

    .v-navigation-drawer {
        ::-webkit-scrollbar {
            width: 0 !important;
        }
    }

    .iphone-x {
        .v-bottom-sheet .v-card__text {
            padding-bottom: 24px !important;
        }
    }

    .v-data-table td {
        height: 36px !important;
    }

    .v-data-table tr:nth-child(even) {
        background: #f8f8f8;
    }

    .apexcharts-toolbar {
        z-index: 1 !important;
    }
</style>
