import Storage from './storage'

const BASE_URL = 'https://ost.otrum.net/ost';
//const BASE_URL = 'http://localhost:8093/ost';

export default class API {

    static getBaseUrl() {
        return BASE_URL;
    }

    static buildHeaders(custom = {}) {
        const headers = {
            'Content-Type': 'application/json',
            accept: '*/*',
            'client-token': Storage.get('clientToken')
        };

        for (let key in custom) {
            // noinspection JSUnfilteredForInLoop
            headers[key] = custom[key];
        }

        return headers;
    }

    static request(method, path, data = null, jsonResponse = true, headers = {}) {
        // Using race for timeout
        return Promise.race([
            new Promise(async (resolve) => {
                try {
                    const url = (path.startsWith('http:') || path.startsWith('https:')) ?
                            path : BASE_URL + path;
                    const response = await fetch(url, {
                        method: method,
                        cache: 'no-cache',
                        credentials: 'same-origin',
                        headers: this.buildHeaders(headers),
                        body: data === null ? undefined : JSON.stringify(data)
                    });

                    const responseData = jsonResponse ? await response.json() : response;

                    resolve({
                        success: response.status === 200,
                        data: responseData,
                    });
                } catch (e) {
                    console.error(e);
                    resolve({success: false, message: 'server is down - please try again later'});
                }
            }),
            new Promise((resolve) => setTimeout(() => resolve({success: false, message: 'server is down - please try again later'}), 30000))
        ]);
    }
}