<template>
    <div class="filterable-list">
        <MobileAppBar>
            <v-text-field
                solo-inverted
                :placeholder="filterText"
                prepend-inner-icon="mdi-magnify"
                single-line
                clearable
                hide-details
                v-model="filterModel"
                height="44px"
            ></v-text-field>
            <slot name="headerActions"></slot>
        </MobileAppBar>
        <v-skeleton-loader
            v-show="skeleton"
            type="list-item-avatar-two-line"
            v-for="item in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            :key="item"
        ></v-skeleton-loader>
        <v-list v-show="!skeleton">
            <template
                v-for="(item, index) in filteredItems"
            >
                <FilterableListItem
                    :item="item"
                    :index="index"
                    :clickable-items="clickableItems"
                    :item-height="itemHeight"
                    @itemClick="itemClick"
                >
                    <template v-slot:item="{item}">
                        <slot name="item" :item="item"></slot>
                    </template>
                </FilterableListItem>
            </template>
        </v-list>
    </div>
</template>

<script>
    import FilterableListItem from "./FilterableListItem";
    import MobileAppBar from "../appbar/MobileAppBar";

    export default {
        name: "FilterableList",
        components: {MobileAppBar, FilterableListItem},
        props: {
            skeleton: {
                type: Boolean,
                required: true,
            },
            items: {
                type: Array,
                required: true,
            },
            filterableFields: {
                type: Array,
                required: true,
            },
            clickableItems: {
                default: true,
                type: Boolean,
            },
            itemHeight: {
                default: 61,
                type: Number,
            },
            filterText: {
                required: true,
                type: String,
            }
        },
        data: () => ({
            filter: null,
            filterModel: null,
        }),
        computed: {
            filteredItems() {
                return this.filter === null || this.filter === '' ? this.items : this.items.filter(i => {
                    let included = false;

                    this.filterableFields.forEach(f => {
                        if (typeof i[f] === 'string') {
                            if (i[f].toLowerCase().includes(this.filter)) included = true;
                        }
                    });

                    if (included) return i;
                });
            }
        },
        watch: {
            filterModel(f) {
                clearTimeout(this.filterTimeout);

                this.filterTimeout = setTimeout(() => {
                    this.filter = f !== null ? f.toLowerCase() : null;
                }, 400);
            }
        },
        methods: {
            itemClick(i) {
                this.$emit('itemClick', i);
            }
        }
    }
</script>

<style lang="scss">
    .filterable-list {
        .v-input--is-focused ::placeholder {
            color: black !important;
            opacity: 0.6;
            caret-color: black !important;
        }

        .v-input--is-focused .v-icon {
            color: black !important;
            opacity: 0.6;
        }

        .v-text-field .v-input__slot {
            max-height: 42px;
            min-height: 42px;
            margin-top: 3px;
        }

        .v-text-field input {
            caret-color: black !important;
        }
    }
</style>