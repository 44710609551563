import Vue from 'vue'
import Vuex from 'vuex'
import API from '../lib/api'
import Storage from '../lib/storage'
import instances from './modules/instances'
import getters from './getters'
import hotels from "./modules/hotels";
import crudModule from "./modules/crudModule";
import eventLogSingle from "./modules/eventLogSingle";
import eventLogAll from "./modules/eventLogAll";
import connectivity from "./modules/connectivity";
import tvScreenshots from "./modules/tvScreenshots";
import serviceStatus from "@/store/modules/serviceStatus";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        clientToken: null,
        loginError: null,
        authenticating: false,
        userAccountId: null,
        isAdmin: false,
        statusFetchPaused: false,
        showInstanceTypeSheet: false,
        iPhoneX: false,
        tablet: false,
        loggingIn: false,
        scrollTop: 0,
        mousePos: {
            x: 0,
            y: 0
        },
        tools: [
            {title: 'PDM', url: 'https://pdm.otrum.net'},
            {title: 'EAAS', url: 'https://user.otrum.net'},
            {title: 'Releases', url: 'https://release.otrum.net/'},
            {title: 'AWS', url: 'https://otrum.signin.aws.amazon.com/console'},
            {title: 'SeaFile', url: 'https://seafile.otrum.net'},
            {title: 'PassPack', url: 'https://www.passpack.com/online/#'},
            {title: 'JIRA', url: 'https://otrumrd.atlassian.net'},
        ],
        reports: [
            {title: 'Front End 1', url: 'http://fe1.otrum.net'},
            {title: 'Front End 2', url: 'http://fe2.otrum.net'},
            {title: 'Signage Screen ELB', url: 'https://status.otrum.net/otrum-ds-elb-prod-screen.html'},
            {title: 'Signage Portal ELB', url: 'https://status.otrum.net/otrum-ds-elb-prod-portal.html'},
            {title: 'Video CF distribution', url: 'https://status.otrum.net/otrum-cf-video.html'},
        ],
    },
    mutations: {
        setClientToken(state, {token, loginError, accountId, isAdmin}) {
            state.clientToken = token;
            state.loginError = loginError;
            state.userAccountId = accountId;
            state.isAdmin = isAdmin;
            Storage.set('clientToken', token);
        },
        setAuthenticating(state, auth) {
            state.authenticating = auth;
        },
        setMousePos(state, pos) {
            state.mousePos = pos;
        },
        setScrollTop(state, s) {
            state.scrollTop = s;
        },
        setStatusFetchPaused(state, p) {
            state.statusFetchPaused = p;
        },
        setLoggingIn(state, l) {
            state.loggingIn = l;
        },
        setShowInstanceTypeSheet(state, s) {
            state.showInstanceTypeSheet = s;
        },
        setIPhoneX(state, i) {
            state.iPhoneX = i;
        },
        setTablet(state, t) {
            state.tablet = t;
        }
    },
    actions: {
        async login({commit}, credentials) {
            commit('setLoggingIn', true);

            const response = await API.request('POST', '/authenticate', credentials);

            if (response.data !== undefined && response.data.success === true) {
                commit('setClientToken', {
                    token: response.data.clientToken,
                    loginError: null,
                    accountId: response.data.userAccountId,
                    isAdmin: response.data.isAdmin,
                });
            } else {
                commit('setClientToken', {
                    token: null, loginError: response.data === undefined ? 'server offline' : response.data.message,
                    accountId: null, isAdmin: false,
                });
            }

            commit('setLoggingIn', false);
        },
        logout({commit}) {
            commit('setClientToken', {token: null, loginError: null, accountId: null, isAdmin: false, routeToLogin: true});
        },
        async authenticate({commit}) {
            if (Storage.get('clientToken') === undefined || Storage.get('clientToken') === 'null') {
                return;
            }

            commit('setAuthenticating', true);

            const response = await API.request('GET', '/authenticate');

            if (response.data !== undefined && response.data.success === true) {
                commit('setClientToken', {
                    token: response.data.clientToken,
                    loginError: null,
                    accountId: response.data.userAccountId,
                    isAdmin: response.data.isAdmin,
                });
            } else {
                commit('setClientToken', {token: null, loginError: null, accountId: null, isAdmin: false,});
            }

            commit('setAuthenticating', false);
        },
        updateMousePos({commit}, pos) {
            commit('setMousePos', pos);
        },
        updateScrollTop({commit}, s) {
            commit('setScrollTop', s);
        },
        updateShowInstanceTypeSheet({commit}, s) {
            commit('setShowInstanceTypeSheet', s);
        },
        updateIPhoneX({commit}, i) {
            commit('setIPhoneX', i);
        },
        updateTablet({commit}, t) {
            commit('setTablet', t);
        },
        async pauseStatusFetch({commit}) {
            const response = await API.request('POST', '/status/triggerSuspend');

            if (response.success) {
                commit('setStatusFetchPaused', response.data.paused);
                Vue.prototype.$alert.success(response.data.paused ? 'Status fetch paused' : 'Status fetch resumed');
            } else {
                Vue.prototype.$alert.error('Status fetch resume/pause failed - please try again');
            }
        },
        async checkStatusFetch({commit}) {
            const response = await API.request('GET', '/status/paused');

            if (response.success) {
                commit('setStatusFetchPaused', response.data.paused);
            }
        },
    },
    modules: {
        instances,
        hotels,
        owners: crudModule('Hotel chain', '/admin/hotelowners'),
        groups: crudModule('Group', '/admin/usergroups'),
        users: crudModule('User', '/admin/useraccounts'),
        notes: crudModule('Note', '/facilityNote'),
        alerts: crudModule('Alert', '/notification'),
        conditions: crudModule('Condition', '/condition'),
        backups: crudModule('Backup', '/facilities/backup'),
        connectivity: connectivity,
        eventLogSingle: eventLogSingle,
        eventLogAll: eventLogAll,
        tvScreenshots: tvScreenshots,
        serviceStatus: serviceStatus,
    },
    getters: getters
});
