<template>
    <v-btn
        :small="$vuetify.breakpoint.mdAndDown"
        :height="$vuetify.breakpoint.mdAndDown ? '30px' : '52px'"
        :max-height="$vuetify.breakpoint.mdAndDown ? '30px' : '52px'"
        rounded
        :color="color"
        @click="$emit('buttonClick')"
        class="elevation-1 dashboard-button"
    >
        <v-icon>{{icon}}</v-icon>
        {{title}}
    </v-btn>
</template>

<script>
    export default {
        name: "DashboardButton",
        props: {
            icon: String,
            title: String,
            color: {
                default: 'accent',
                type: String,
            }
        }
    }
</script>

<style lang="scss">
    .dashboard-button {
        margin: 0 12px 12px 0;
    }

    .md-and-down .dashboard-button {
        margin: 0 8px 8px 0;
    }
</style>