<template>
    <v-menu
        :position-x="mousePos.x"
        :position-y="mousePos.y"
        v-model="show"
        max-height="70%"
        min-width="300px"
    >
        <v-card v-if="header !== null && header !== undefined">
            <v-card-title>{{header.text}}</v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-item @click="$emit('sort', header.value, false)">
                        <v-list-item-icon>
                            <v-icon color="accent">mdi-sort-ascending</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Sort ascending</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('sort', header.value, true)">
                        <v-list-item-icon>
                            <v-icon color="accent">mdi-sort-descending</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Sort descending</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$emit('sort', null, false)">
                        <v-list-item-icon>
                            <v-icon color="accent">mdi-sort-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Default sort</v-list-item-title>
                    </v-list-item>
                    <v-menu offset-x open-on-hover :close-on-content-click="false">
                        <template v-slot:activator="{on}">
                            <v-list-item v-on="on">
                                <v-list-item-icon>
                                    <v-icon color="accent">mdi-table-column-plus-after</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>Column selection</v-list-item-title>
                                <v-list-item-icon>
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </template>
                        <v-card max-height="50vh" color="white">
                            <v-card-text>
                                <v-list>
                                    <v-list-item
                                        v-for="header in headers"
                                        :key="header.value"
                                    >
                                        <v-switch
                                            v-model="header.enabled"
                                            :label="header.text"
                                            hide-details
                                        ></v-switch>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    <v-list-item @click="show = false">
                        <v-list-item-icon>
                            <v-icon>mdi-close</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Close</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: "FilterableTableHeaderOptions",
        props: {
            headers: Array,
            value: Boolean,
            header: Object,
        },
        data: () => ({
            mousePos: {
                x: 0,
                y: 0
            },
        }),
        computed: {
            show: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        watch: {
            show(s) {
                if (s) {
                    this.mousePos = {
                        x: this.$store.state.mousePos.x,
                        y: this.$store.state.mousePos.y,
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .v-card__title {
        padding-left: 14px;
        padding-right: 14px;
        padding-bottom: 8px;
    }

    .v-card__text {
        padding: 0 !important;
        padding-bottom: 6px !important;
    }

    .v-list {
        padding-top: 0;
    }
</style>