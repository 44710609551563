<template>
    <v-card>
        <v-card-title>
            <v-btn
                v-if="$vuetify.breakpoint.mdAndDown"
                @click="$emit('close')"
                icon
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
                {{$store.getters.user !== null ? $store.getters.user.name : 'Links & Tools'}}
            </v-toolbar-title>
        </v-card-title>

        <v-card-text>
            <v-list>
                <v-list-item @click="openTab('https://otrum.com')">
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-open-in-new</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Otrum</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openTab('https://report.otrum.net/reports/login.html#')">
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-open-in-new</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Statistics portal</v-list-item-title>
                </v-list-item>

                <v-menu offset-x open-on-hover v-if="$store.state.isAdmin">
                    <template v-slot:activator="{on}">
                        <v-list-item v-on="on">
                            <v-list-item-icon>
                                <v-icon color="accent">mdi-tools</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Tools</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </template>

                    <v-card max-height="50vh" color="white">
                        <v-card-text>
                            <v-list>
                                <v-list-item
                                    v-for="t in $store.state.tools"
                                    :key="t.title"
                                    @click="openTab(t.url)"
                                >
                                    <v-list-item-title>{{t.title}}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-menu>

                <v-menu offset-x open-on-hover v-if="$store.state.isAdmin">
                    <template v-slot:activator="{on}">
                        <v-list-item v-on="on">
                            <v-list-item-icon>
                                <v-icon color="accent">mdi-file-chart</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Reports</v-list-item-title>
                            <v-list-item-icon>
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </template>

                    <v-card max-height="50vh" color="white">
                        <v-card-text>
                            <v-list>
                                <v-list-item
                                    v-for="r in $store.state.reports"
                                    :key="r.title"
                                    @click="openTab(r.url)"
                                >
                                    <v-list-item-title>{{r.title}}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-menu>

                <v-list-item
                    @click="$store.dispatch('pauseStatusFetch')"
                    v-if="$store.state.isAdmin"
                >
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-cloud-sync</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{$store.state.statusFetchPaused ? 'Resume status fetch' : 'Pause status fetch'}}
                    </v-list-item-title>
                </v-list-item>

                <v-list-item
                        @click="generateMapLink"
                >
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-link</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        Generate map link
                    </v-list-item-title>
                </v-list-item>

                <v-list-item
                    @click="editUser"
                >
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        User settings
                    </v-list-item-title>
                </v-list-item>

                <v-list-item
                    @click="$store.dispatch('logout')"
                >
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-logout-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        Log out
                    </v-list-item-title>
                </v-list-item>

            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "AppBarMenuContent",
        methods: {
            openTab(url) {
                window.open(url, '_blank');
            },
            editUser() {
                this.$userEditor.show(this.$store.getters.user, true);
            },
            generateMapLink() {
                this.$linkGenerator.show();
            }
        }
    }
</script>

<style scoped>
    .v-card__title {
        padding-left: 8px !important;
        padding-right: 8px !important;
        padding-bottom: 8px !important;
    }

    .v-card__title .v-btn {
        padding-left: 0;
        margin-left: 0;
    }

    .v-toolbar__title {
        margin-left: 5px;
        max-width: calc(100% - 50px);
    }

    .v-card__text {
        padding: 0 !important;
        padding-bottom: 6px !important;
    }

    .v-list {
        padding-top: 0;
    }
</style>