import crudModule from "./crudModule";
import Vue from "vue";

const module = crudModule('serviceStatus', 'https://status.thecloudportal.com/api');

module.getters = {
    statusesComputed: (state) => {
        let statuses = Vue.prototype.$clone(state.list);
        statuses.forEach(status => {
            status.systemCapitalized = status.system[0].toUpperCase() + status.system.substr(1);
        });
        return statuses;
    }
};

export default module;