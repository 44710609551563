<template>
    <v-menu offset-y>
        <template v-slot:activator="{on}">
            <v-btn
                icon
                v-on="on"
                @click="unseenNotifications = false"
            >
                <v-icon>mdi-bell</v-icon>
                <div
                    v-show="unseenNotifications"
                    class="notifications-badge"
                ></div>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                Notifications
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="accent"
                    v-show="notifications.length > 0"
                    @click.stop="notifications = []"
                >
                    Clear
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-subheader v-if="notificationsComputed.length === 0">
                    There are no new notifications.
                </v-subheader>
                <v-list>
                    <template v-for="n in notificationsComputed">
                        <v-list-item
                            @click="$router.push({path: '/details', query: {facilityId: n.facilityId, service: n.service}})"
                        >
                            <v-list-item-icon>
                                <v-icon :color="n.online ? 'success' : 'error'">
                                    {{n.service === 'enterprise' ? n.hosted ? 'mdi-cloud' : 'mdi-office-building' : 'mdi-google-chrome'}}
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{n.name}}</v-list-item-title>
                                <v-list-item-subtitle>{{n.online ? 'Came back online' : 'Went offline'}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                {{n.timestampReadable}}
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-list>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
    import DateLib from "../../lib/date";
    import {mapGetters} from "vuex";

    export default {
        name: "TheNotifications",
        data: () => ({
            notifications: [],
            unseenNotifications: false,
        }),
        created() {
            if (("Notification") in window) {
                Notification.requestPermission();
            }
        },
        computed: {
            ...mapGetters({
                instancesComputedFacilityIdServiceMap: 'instances/instancesComputedFacilityIdServiceMap'
            }),
            notificationsComputed() {
                this.notifications.forEach(n => {
                    this.$set(n, 'timestampReadable', DateLib.formatToReadable(n.timestamp));
                });

                return this.notifications.sort((a, b) => {
                    const timeA = a.timestamp.getTime();
                    const timeB = b.timestamp.getTime();

                    if (timeA > timeB) return -1;
                    return timeA === timeB ? 0 : 1;
                });
            }
        },
        watch: {
            '$store.state.instances.offlineHotelIds'(newIds, oldIds) {
                if (oldIds !== null) {
                    const newOfflineHotels = [];
                    const changed = [];

                    newIds.forEach(i => {
                        if (!oldIds.includes(i)) {
                            changed.push(i);
                        }
                    });

                    oldIds.forEach(i => {
                        if (!newIds.includes(i)) {
                            changed.push(i)
                        }
                    });

                    changed.forEach(c => {
                        if(this.instancesFacilityIdServiceMap) {
                            const instance = this.instancesFacilityIdServiceMap[c];

                            if (instance !== undefined) {
                                this.notifications.push({
                                    name: instance.name,
                                    service: instance.service,
                                    hosted: instance.hosted,
                                    facilityId: instance.facilityId,
                                    online: instance.online,
                                    timestamp: new Date(instance.updatedDate),
                                });

                                if (!instance.online) newOfflineHotels.push(instance);

                                this.unseenNotifications = true;
                            }
                        }
                    });

                    if (newOfflineHotels.length > 0) this.showBrowserNotification(newOfflineHotels);
                    if (this.notifications.length > 30) this.notifications.shift();
                }
            }
        },
        methods: {
            showBrowserNotification(offlineHotels) {
                const text = offlineHotels.length === 1 ? offlineHotels[0].name + ' has gone offline' :
                    offlineHotels.length + ' hotels has gone offline';

                if (("Notification") in window && Notification.permission === 'granted') {
                    const notification = new Notification('Otrum Status Update', {
                        body: text,
                        icon: require('@/assets/logo_purple.svg'),
                    });

                    setTimeout(notification.close.bind(notification), 4000);
                } else {
                    this.$alert.error(text);
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .notifications-badge {
        position: absolute;
        top: -2px;
        left: 10px;
        width: 14px;
        height: 14px;
        border-radius: 25px;
        background: var(--v-error-lighten2);
    }

    .v-card__text {
        max-height: 40vh;
        overflow-y: auto;
        padding: 8px 0 8px;
    }

    .v-card__title {
        padding-bottom: 0;
        min-width: 300px;
    }

    .v-list {
        padding: 0;
        max-width: 480px;
        min-width: 480px;
        max-height: 60vh;
    }
</style>