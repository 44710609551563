<!--suppress JSUnfilteredForInLoop -->
<template>
    <DashboardCard
        title="OS"
        content-padding
    >
        <template v-slot:content>
            <VueApexChart
                v-if="$route.name === 'home'"
                height="270"
                type="donut"
                :series="series"
                :options="options"
            ></VueApexChart>
        </template>
    </DashboardCard>
</template>

<script>
    import DashboardCard from "../core/dashboard/DashboardCard";
    import VueApexChart from 'vue-apexcharts';

    export default {
        name: "HomeOS",
        components: {DashboardCard, VueApexChart},
        data: () => ({
            options: {},
        }),
        computed: {
            series() {
                const data = {};

                this.$store.state.instances.list.forEach(i => {
                    let os = i.operatingSystem;

                    if (os !== null) {
                        if (os.toLowerCase().includes('linux')) {
                            os = os.split('-')[0];
                        }

                        if (data[os] === undefined) data[os] = 1;
                        else data[os] += 1;
                    }
                });

                const series = [];
                const categories = [];

                for (let key in data) {
                    series.push(
                        data[key]
                    );

                    categories.push(key);
                }

                this.options = {
                    labels: categories,
                };

                return series;
            }
        }
    }
</script>