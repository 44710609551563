<template>
    <div>
        <div @click="errorClick">
            <v-alert
                class="error-alert"
                dense
                type="error"
                :max-width="$vuetify.breakpoint.mdAndDown && !errorFocused ? '56px' : ''"
                v-show="errorMessage !== null"
            >
                {{errorMessage}}
            </v-alert>
        </div>

        <div @click="successClick">
            <v-alert
                class="success-alert"
                dense
                type="success"
                v-show="successMessage !== null"
                :max-width="$vuetify.breakpoint.mdAndDown && !successFocused ? '56px' : ''"
            >
                {{successMessage}}
            </v-alert>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: "TheAlert",
        data: () => ({
            successMessage: null,
            errorMessage: null,
            successFocused: false,
            errorFocused: false,
        }),
        created() {
            Vue.prototype.$alert = this;
        },
        methods: {
            success(message) {
                clearTimeout(this.successTimeout);
                this.successFocused = false;
                this.successMessage = message;

                this.successTimeout = setTimeout(() => {
                    this.successMessage = null;
                }, 6000);
            },
            error(message) {
                clearTimeout(this.errorTimeout);
                this.errorFocused = false;
                this.errorMessage = message;

                this.errorTimeout = setTimeout(() => {
                    this.errorMessage = null;
                }, 6000);
            },
            errorClick() {
                if (this.$vuetify.breakpoint.mdAndDown) {
                    if (this.errorFocused) this.errorMessage = null;
                    else this.errorFocused = true;
                } else {
                    this.errorMessage = null;
                }
            },
            successClick() {
                if (this.$vuetify.breakpoint.mdAndDown) {
                    if (this.successFocused) this.successMessage = null;
                    else this.successFocused = true;
                } else {
                    this.successMessage = null;
                }
            }
        }
    }
</script>

<style lang="scss">
    .v-alert {
        position: fixed !important;
        bottom: 80px;
        right: 15px;
        z-index: 2;
    }

    .error-alert {
        position: fixed;
        bottom: 130px;
        right: 15px;
    }

    .md-and-down {
        .v-alert {
            bottom: 60px;
            max-width: 90vw;
            max-height: 40px;
        }

        .v-alert__content {
            max-height: 24px;
            overflow: hidden;
        }

        .error-alert {
            bottom: 110px;
        }
    }

    .iphone-x .v-alert {
        bottom: 95px;
    }

    .iphone-x .error-alert {
        bottom: 155px;
    }
</style>