<template>
    <DashboardCard
        title="Alerts"
    >
        <template v-slot:titleActions>
            <v-btn
                color="accent"
                text
                @click="newAlert"
                :loading="$store.state.alerts.processing"
            >
                Create
            </v-btn>
        </template>
        <template v-slot:content>
            <v-list class="pa-0">
                <v-subheader v-if="items.length === 0">
                    No alerts have been set up for this instance yet.
                </v-subheader>
                <v-list-item
                    v-for="a in items"
                    :key="a.id"
                    @click="editAlert(a)"
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            {{a.typeTitle}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <v-chip
                                v-for="(i, index) in a.recipients.split(',')"
                                :key="i"
                                v-if="index < 2"
                                class="ma-1"
                            >
                                {{i}}
                            </v-chip>
                            <span v-if="a.recipients.split(',').length > 2"
                                  style="margin-left: 5px;">+{{a.recipients.split(',').length - 2}} more</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        {{a.threshold}}min
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </template>
    </DashboardCard>
</template>

<script>
    import DashboardCard from "../core/dashboard/DashboardCard";

    export default {
        name: "DetailsAlerts",
        components: {DashboardCard},
        props: {
            instance: Object,
        },
        computed: {
            items() {
                return this.$store.getters.alertsComputed
                .filter(i => i.facilityId === this.instance.facilityId && i.serviceType === this.instance.service);
            }
        },
        methods: {
            newAlert() {
                this.$alertEditor.show(null, false, this.instance);
            },
            editAlert(a) {
                this.$alertEditor.show(a, true, this.instance);
            },
        }
    }
</script>