<template>
    <v-card>
        <v-card-title>Events</v-card-title>
        <v-card-text class="pa-0">
            <v-data-table
                :items-per-page="10"
                :items="eventLogSingleComputed"
                :headers="headers"
                sort-by="time"
                sort-desc
            >
                <template v-slot:item="{item, headers}">
                    <tr>
                        <template v-for="header in headers">
                            <td v-if="header.value === 'status'">
                            <span :class="item.online || 'offline'">
                                {{item.online ? 'ONLINE' : 'OFFLINE'}}
                            </span>
                            </td>
                            <td v-else-if="header.value === 'pms'">
                            <span :class="item.pmsLinkUp || 'offline'">
                                {{item.pmsLinkUp ? 'UP' : 'DOWN'}}
                            </span>
                            </td>
                            <td v-else-if="header.value === 'time'">
                                {{item.time === '' ? item.updatedDate : item.time}}
                            </td>
                            <td v-else>{{item[header.value]}}</td>
                        </template>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "DetailsEventLog",
        props: {
            instance: Object,
        },
        data: () => ({
            headers: [
                {text: 'Time', value: 'time'},
                {text: 'Status', value: 'status'},
                {text: 'PMS', value: 'pms'},
                {text: 'Version', value: 'version'},
                {text: 'Uptime', value: 'uptime'},
            ]
        }),
        watch: {
            instance: {
                immediate: true,
                handler(i) {
                    if (i != null) {
                        this.$store.dispatch('eventLogSingle/fetch', {facilityId: i.facilityId, service: i.service});
                    }
                }
            }
        },
        computed: {
            ...mapGetters({
                eventLogSingleComputed: 'eventLogSingle/withTime'
            }),
        }
    }
</script>

<style scoped lang="scss">
    span {
        color: var(--v-success-base);
    }

    span.offline {
        color: var(--v-error-base);
    }

    .v-card__title {
        padding-bottom: 0;
    }

</style>