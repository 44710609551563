<template>
    <v-list-item
        :class="($store.state.instances.filterKey !== 'state' || $store.state.instances.filterVal !== type) || 'v-btn--active'"
        class="instance-type-list-item"
        @click="select"
    >
        <v-col class="instance-type-list-col">
            <v-layout :justify-center="true">
                <v-icon>{{icon}}</v-icon>
            </v-layout>
            <v-layout :justify-center="true" style="padding-right: 2.5px;">
                <div>{{number}}</div>
            </v-layout>
        </v-col>
        <v-list-item-title>{{title}}</v-list-item-title>
    </v-list-item>
</template>

<script>
    export default {
        name: "InstanceTypeListItem",
        props: {
            type: {
                required: true,
            },
            icon: {
                required: true,
                type: String,
            },
            title: {
                required: true,
                type: String,
            },
            number: {
                required: true,
                type: Number,
            }
        },
        methods: {
            select() {
                this.$store.dispatch('instances/applyFilter', { key: "state", val: this.type});
                if(this.$route.name === 'home') {
                    this.$router.push('/list');
                } else {
                    this.$store.dispatch('updateShowInstanceTypeSheet', false);
                }
            }
        }
    }
</script>

<style scoped>
    .v-list-item__title {
        padding-left: 24px;
    }

    .instance-type-list-col {
        padding-left: 0;
        padding-right: 0;
        min-width: 70px;
        padding-top: 15px !important;
    }
</style>

<style lang="scss">
    .md-and-down .instance-type-list-item {
        max-height: 60px;
    }
</style>