<template>
    <v-progress-linear
        height="5px"
        v-show="processingValue > 0"
        v-model="processingValue"
        fixed
        top
        color="accent"
    ></v-progress-linear>
</template>

<script>
    export default {
        name: "TheProcessingIndicator",
        data: () => ({
            processingValue: 0,
        }),
        computed: {
            loading() {
                let loading = false;
                const storesToWatch = ['instances', 'hotels', 'groups', 'users', 'notes', 'conditions', 'alerts'];

                storesToWatch.forEach(s => {
                    if (this.$store.state[s].fetching === true || this.$store.state[s].processing === true) {
                        loading = true;
                    }
                });

                return loading;
            }
        },
        watch: {
            loading(l) {
                if (l && this.processingValue === 0) {
                    this.processingInterval = setInterval(() => {
                        this.processingValue += 1;
                    }, 10);
                } else {
                    clearInterval(this.processingInterval);
                    this.processingValue = 100;

                    setTimeout(() => {
                        this.processingValue = 0;
                    }, 500);
                }
            }
        }
    }
</script>

<style scoped>
    .v-progress-linear {
        top: 0;
        z-index: 10;
    }
</style>