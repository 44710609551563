<template>
    <v-btn
        fixed
        fab
        bottom
        right
        :small="$vuetify.breakpoint.mdAndDown"
        color="accent"
        @click="scrollUp"
        v-show="scrollTop > 1500"
        class="the-scroll-button"
    >
        <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
</template>

<script>
    export default {
        name: "TheScrollButton",
        data: () => ({
            scrollTop: 0,
        }),
        created() {
            window.addEventListener('scroll', e => {
                this.scrollTop = e.target.scrollingElement.scrollTop;
            });
        },
        methods: {
            scrollUp() {
                this.$vuetify.goTo(0);
            }
        }
    }
</script>

<style lang="scss">
    .the-scroll-button {
        bottom: 90px !important;
    }

    .md-and-down .the-scroll-button {
        bottom: 65px;
        right: 8px;
    }

    .iphone-x .the-scroll-button {
        bottom: 95px;
    }
</style>