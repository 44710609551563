import { render, staticRenderFns } from "./TheInstanceTypeSelector.vue?vue&type=template&id=10467efb&scoped=true&"
import script from "./TheInstanceTypeSelector.vue?vue&type=script&lang=js&"
export * from "./TheInstanceTypeSelector.vue?vue&type=script&lang=js&"
import style0 from "./TheInstanceTypeSelector.vue?vue&type=style&index=0&id=10467efb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10467efb",
  null
  
)

export default component.exports