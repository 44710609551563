<template>
    <v-dialog
            max-width="800"
            v-model="showDialog"
    >
        <v-card>
            <v-card-title>
                Authenticated link
            </v-card-title>
            <v-divider></v-divider>
            <v-text-field
                    style="padding: 10px;"
                    disabled
                    width="600"
                    v-model="link"
            >
            </v-text-field>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        @click="copyLinkToClipboard"
                >
                    Copy to Clipboard
                </v-btn>
                <v-btn
                        @click="close"
                >
                    Close
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Vue from 'vue';
    import Storage from "../../lib/storage";

    export default {
        name: "TheLinkGenerator",
        data: () => ({
            link: "",
            showDialog: false
        }),
        created() {
            Vue.prototype.$linkGenerator = this;
        },
        methods: {
            show() {
                this.link = window.location.origin + "/#map?clientToken=" + Storage.get('clientToken');
                this.color = 'primary';
                this.showDialog = true;
            },
            close() {
                this.showDialog = false;
            },
            copyLinkToClipboard() {
                const dummy = document.createElement("textarea");
                dummy.value = this.link;
                document.body.appendChild(dummy);
                dummy.select();
                document.execCommand("copy");
                document.body.removeChild(dummy);
                this.$alert.success('URL copied to clipboard');
            }
        }
    }
</script>

<style scoped>
    .v-card__text {
        margin-top: 24px;
        margin-bottom: 12px;
    }
</style>