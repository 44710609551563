import DateLib from "../lib/date"
import Vue from 'vue'

const set = Vue.prototype.$set;

const toMap = (list, key1, key2 = null) => {
    const map = {};

    list.forEach(i => {
        if (key2 === null) {
            map[i[key1]] = i;
        } else {
            map[i[key1] + i[key2]] = i;
        }
    });

    return map;
};

export default {
    alertsComputed: (state, getters, rootState, rootGetters) => {
        let alerts = Vue.prototype.$clone(state.alerts.list);

        alerts.forEach(i => {
            const typeTitle = i.type === 'facility_online' ? 'Facility comes online' : 'Facility goes offline';
            set(i, 'typeTitle', i.type === 'tvs_offline' ? 'TVs go offline' : typeTitle);

            const instance = rootGetters['instances/instancesFacilityIdServiceMap'][i.facilityId + i.serviceType];
            set(i, 'instanceTitle', instance === undefined ? null : instance.name);
            set(i, 'instanceOnline', instance === undefined ? false : instance.online);
            set(i, 'instanceHosted', instance === undefined ? false : instance.hosted);
        });

        return alerts.filter(i => i.instanceTitle !== null);
    },
    notesIdMap: (state, getters) => {
        return toMap(Vue.prototype.$clone(state.notes.list), 'facilityId', 'serviceType');
    },
    backupsFacilityIdServiceMap: (state) => {
        return toMap(Vue.prototype.$clone(state.backups.list), 'facilityId', 'service');
    },
    user: (state) => {
        let user = null;
        const accountId = state.userAccountId;

        Vue.prototype.$clone(state.users.list).forEach(u => {
            if (u.id === accountId) {
                user = u;
            }
        });

        return user;
    },
    firstName: (state, getters) => {
        const user = getters.user;
        const name = user === null ? '' : user.name.split(' ')[0];
        return name.length > 12 ? name.substr(0, 11) + '.' : name;
    }
}