<template>
    <v-overlay
        v-model="showViewer"
    >
        <v-layout
            :fill-height="true"
            :justify-center="true"
            :align-center="true"
            v-if="showViewer"
            @click="showViewer = false"
        >
            <v-img
                :src="url"
                contain
                max-height="100%"
                max-width="100%"
            >
                <template v-slot:placeholder>
                    <v-layout
                        :fill-height="true"
                        :align-center="true"
                        :justify-center="true"
                    >
                        <v-progress-circular
                            color="accent"
                            size="80"
                            indeterminate
                        ></v-progress-circular>
                    </v-layout>
                </template>
            </v-img>

            <v-btn
                fab
                fixed
                top
                right
                color="accent"
                @click="showViewer = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-layout>
    </v-overlay>

</template>

<script>
    import Vue from 'vue';

    export default {
        name: "TheImageViewer",
        data: () => ({
            url: null,
            showViewer: false,
        }),
        created() {
            Vue.prototype.$imageViewer = this;
        },
        methods: {
            show(url) {
                this.url = url;
                this.showViewer = true;
            }
        }
    }
</script>