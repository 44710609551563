<template>
    <div class="map">
        <MobileAppBar>
            <v-text-field
                solo-inverted
                placeholder="Search for hotels"
                prepend-inner-icon="mdi-magnify"
                single-line
                clearable
                hide-details
                v-model="searchInput"
                height="44px"
            ></v-text-field>
        </MobileAppBar>

        <l-map
                v-if="$route.name === 'map' || $route.name === 'details'"
                style="width: 100%; z-index: 1;"
                :zoom="zoom"
                :max-zoom="20"
                :center="{lat: 59.911491, lng: 10.757933}"
                @update:zoom="zoomChange"
        >
            <l-control-layers position="topright"></l-control-layers>
            <l-tile-layer
                    v-for="tileProvider in tileProviders"
                    :key="tileProvider.name"
                    :name="tileProvider.name"
                    :visible="tileProvider.visible"
                    :url="tileProvider.url"
                    :attribution="tileProvider.attribution"
                    layer-type="base"/>

            <template v-for="instance in items">
                <l-marker
                        :z-index-offset="instance.online ? 1 : 2"
                        :lat-lng="{lat: parseFloat(instance.lat), lng: parseFloat(instance.lng)}"
                        :name="instance.name"
                        :icon="instance.online ? onlineMarker : offlineMarker"
                        @click="select(instance)"
                >
                    <l-tooltip
                            :content="instance.name + ' - ' + instance.hotelType"
                    >
                    </l-tooltip>
                </l-marker>
            </template>
        </l-map>

        <InstanceActionsMenu
            :instance="instanceCard.instance"
            v-model="instanceCard.show"
        ></InstanceActionsMenu>

        <v-checkbox
            v-show="!$vuetify.breakpoint.mdAndDown"
            label="Cluster"
            hide-details
            v-model="cluster"
            class="cluster-checkbox"
        ></v-checkbox>

        <v-text-field
                v-if="!$route.query.clientToken && !$vuetify.breakpoint.mdAndDown"
                solo
                class="map-search-field"
                v-model="searchInput"
                hide-details
                prepend-inner-icon="mdi-magnify"
                clearable
                placeholder="Filter"
        ></v-text-field>
    </div>
</template>

<script>
    import InstanceActionsMenu from "../components/core/instanceactions/InstanceActionsMenu";
    import MobileAppBar from "../components/core/appbar/MobileAppBar";
    import {mapGetters} from "vuex";

    export default {
        name: "Map",
        components: {MobileAppBar, InstanceActionsMenu},
        data: () => ({
            instanceCard: {
                show: false,
                instance: null,
            },
            cluster: false,
            zoom: 4,
            search: null,
            searchInput: null,
            changeList: {},
            infoWindowTimeouts: {},
            onlineMarker: L.icon({
                iconUrl: require('@/assets/marker_online_32.svg'),
                iconSize: [32, 37],
                iconAnchor: [16, 37],
                html: "HOVERTEXT"
            }),
            offlineMarker: L.icon({
                iconUrl: require('@/assets/marker_offline_32.svg'),
                iconSize: [32, 37],
                iconAnchor: [16, 37],
                customText: "HOVERTEXT"
            }),
            tileProviders: [
                {
                    name: 'Street',
                    visible: true,
                    //attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012',
                    attribution: 'Tiles &copy; Esri',
                    //attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
                    //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                },
                {
                    name: 'Topology',
                    visible: false,
                    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
                    attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
                },
                {
                    name: "Satellite",
                    visible: false,
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                }
            ],
        }),
        computed: {
            ...mapGetters({
                instancesFiltered: 'instances/instancesFiltered',
                instancesComputedFacilityIdServiceMap: 'instances/instancesComputedFacilityIdServiceMap'
            }),
            items() {
                const instances = this.$vuetify.breakpoint.mdAndDown ? this.$store.state.instances.list : this.instancesFiltered;
                return typeof this.search === 'string' && this.search.length > 1 ?
                    instances.filter(i => Object.values(i).toString().toLowerCase().includes(this.search.toLowerCase())) :
                    instances;
            },
        },
        watch: {
            searchInput(s) {
                clearTimeout(this.searchTimeout);

                setTimeout(() => {
                    this.search = s;
                }, 400);
            },
            '$store.state.instances.offlineHotelIds'(newIds, oldIds) {
                if (this.$vuetify.breakpoint.mdAndDown) {
                    return;
                }

                if (oldIds !== null) {
                    const changed = [];

                    newIds.forEach(i => {
                        if (!oldIds.includes(i)) {
                            changed.push(i);
                        }
                    });

                    oldIds.forEach(i => {
                        if (!newIds.includes(i)) {
                            changed.push(i)
                        }
                    });

                    changed.forEach(c => {
                        const item = this.instancesFacilityIdServiceMap[c];

                        if (item !== undefined) {
                            this.changeList[item.facilityId] = {
                                content:
                                    "<b>" + item.name + "</b><br>Status changed " + item.updatedDate + "<br>WENT " +
                                    (item.online === true ? '<span style="color: var(--v-success-base);">ONLINE</span>' : '<span style="color: var(--v-error-base);">OFFLINE</span>')
                            };
                            if (this.infoWindowTimeouts.hasOwnProperty(item.facilityId)) {
                                clearTimeout(this.infoWindowTimeouts[item.facilityId]);
                            }
                            this.infoWindowTimeouts[item.facilityId] = setTimeout(() => {
                                delete this.changeList[item.facilityId];
                            }, 60000);
                        }
                    });
                }
            }

        },
        methods: {
            select(i) {
                this.instanceCard.show = false;

                setTimeout(() => {
                    this.instanceCard = {
                        show: true,
                        instance: i
                    }
                }, 100);
            },
            zoomChange(z) {
                this.zoom = z;
            },
        }
    }
</script>

<style scoped>
    .map {
        width: 100%;
        height: 100%;
    }

    .vue-map-container {
        height: 100%;
        width: 100%;
    }

    .marker {
        background: green !important;
        width: 100px;
    }

    .cluster-checkbox {
        position: fixed;
        top: 74px;
        left: 258px;
        background: white;
        padding: 8px 10px 8px 10px;
        margin-top: 0;
        box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
    }
</style>

<style lang="scss">
    .tablet .cluster-checkbox {
        left: 305px;
    }

    .map-search-field {
        position: fixed;
        top: 74px;
        left: calc(50% - 160px);
        width: 320px;
        max-height: 40px;
        box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px !important;
    }

    .map-search-field .v-input__slot {
        max-height: 40px !important;
        min-height: 40px !important;
        box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px !important;
    }

    .md-and-down .map {
        .v-input--is-focused ::placeholder {
            color: black !important;
            opacity: 0.6;
            caret-color: black !important;
        }

        .v-input--is-focused .v-icon {
            color: black !important;
            opacity: 0.6;
        }

        .v-text-field .v-input__slot {
            max-height: 42px;
            min-height: 42px;
            margin-top: 3px;
        }

        .v-text-field input {
            caret-color: black !important;
        }
    }
</style>