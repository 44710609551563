<template>
    <div
        class="details"
    >
        <MobileAppBar :disable-menu="true">
            <v-btn
                @click="$router.back()"
                icon
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title class="pa-0">{{instance ? instance.name : "Unknown ID"}}</v-toolbar-title>
        </MobileAppBar>

        <v-container v-if="instance != null" grid-list-md class="ma-0 pa-0">
            <v-layout wrap>
                <v-flex xs12 xl12 v-if="instance.lat !== null">
                    <DetailsHeading
                            :instance="instance"
                            :ref="'heading'"
                    ></DetailsHeading>
                </v-flex>
                <v-flex xs12 lg4>
                    <DetailsSummary
                            :instance="instance"
                    ></DetailsSummary>
                </v-flex>
                <v-flex xs12 :lg4="instance.service === 'enterprise'" :lg8="instance.service !== 'enterprise'">
                    <DetailsConnectivity
                        :instance="instance"
                        online-field="online"
                        title="Connectivity"
                    ></DetailsConnectivity>
                </v-flex>
                <v-flex xs12 lg4 v-if="instance.service === 'enterprise'">
                    <DetailsConnectivity
                        :instance="instance"
                        online-field="pmsLinkUp"
                        title="PMS Link"
                    ></DetailsConnectivity>
                </v-flex>
                <v-flex x12 lg4 xl4 v-if="$store.state.isAdmin && !$vuetify.breakpoint.mdAndDown">
                    <DetailsVirtualTv
                            :instance="instance"
                            @updateScreenshot="$refs.heading.updateScreenshot()"
                    ></DetailsVirtualTv>
                </v-flex>
                <v-flex xs12 lg4 xl4 v-if="$store.state.isAdmin">
                    <DetailsCondition
                        :instance="instance"
                    ></DetailsCondition>
                </v-flex>
                <v-flex xs12 lg4 xl4 v-if="$store.state.isAdmin">
                    <DetailsNote
                        :instance="instance"
                    ></DetailsNote>
                </v-flex>
                <v-flex xs12 lg6 xl4>
                    <DetailsAlerts
                            :instance="instance"
                    ></DetailsAlerts>
                </v-flex>
                <v-flex xs12 lg6 xl4>
                    <DetailsFirmware
                        :instance="instance"
                    ></DetailsFirmware>
                </v-flex>
                <v-flex xs12 lg4 xl4 v-if="$vuetify.breakpoint.mdAndDown">
                    <DetailsFeatures
                        :instance="instance"
                    ></DetailsFeatures>
                </v-flex>
                <v-flex xs12 lg12 xl12 v-if="!$vuetify.breakpoint.mdAndDown">
                    <DetailsEventLog
                        :instance="instance"
                    ></DetailsEventLog>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import DetailsInfoFields from "../components/details/DetailsInfoFields";
    import DetailsSummary from "../components/details/DetailsSummary";
    import DetailsVirtualTv from "../components/details/DetailsVirtualTv";
    import DetailsHeading from "../components/details/DetailsHeading";
    import DetailsFirmware from "../components/details/DetailsFirmware";
    import DetailsFeatures from "../components/details/DetailsFeatures";
    import DetailsEventLog from "../components/details/DetailsEventLog";
    import DetailsAlerts from "../components/details/DetailsAlerts";
    import DetailsCondition from "../components/details/DetailsCondition";
    import DetailsNote from "../components/details/DetailsNote";
    import DetailsConnectivity from "../components/details/DetailsConnectivity";
    import MobileAppBar from "../components/core/appbar/MobileAppBar";
    import {mapGetters} from "vuex";

    export default {
        name: "Details",
        components: {
            DetailsSummary,
            MobileAppBar,
            DetailsConnectivity,
            DetailsNote,
            DetailsCondition,
            DetailsAlerts,
            DetailsHeading,
            DetailsEventLog, DetailsFeatures, DetailsFirmware, DetailsVirtualTv, DetailsInfoFields
        },
        activated() {
            this.$vuetify.goTo(0);
        },
        computed: {
            ...mapGetters({
                instancesComputed: 'instances/instancesComputed',
                instancesComputedFacilityIdServiceMap: 'instances/instancesComputedFacilityIdServiceMap'
            }),
            instance() {
                const facilityId = this.$route.query.facilityId;
                const service = this.$route.query.service;
                return this.instancesComputedFacilityIdServiceMap[facilityId + service];
            }
        },
        watch: {
            instance: {
                immediate: true,
                handler(i) {
                    if (i != null) {
                        this.$store.dispatch('connectivity/fetch', {facilityId: i.facilityId, service: i.service, days: 14});
                        this.$store.dispatch('instances/addRecentInstance', i);
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .container {
        max-width: 100%;
    }

    .col {
        padding: 0;
    }

</style>

<style lang="scss">
    .details {
        padding: 10px 16px 10px 16px;

        > .layout {
            margin-bottom: 12px;
        }
    }

    .md-and-down .details {
        padding: 12px 12px 12px 12px;
    }
</style>