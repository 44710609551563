<template>
    <div>
        <v-btn
            text
            @click="create"
            height="64px"
        >
            <v-icon>mdi-account-plus</v-icon>
            Create User
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: "UserActions",
        methods: {
            create() {
                this.$userEditor.show(null, false);
            }
        }
    }
</script>