<template>
    <v-layout wrap>
        <DashboardButton
            :icon="instance.service === 'enterprise' ? instance.hosted ? 'mdi-cloud' : 'mdi-office-building' : 'mdi-google-chrome'"
            :color="instance.online ? 'success' : 'error'"
        ></DashboardButton>
        <DashboardButton
            title="Enterprise Web"
            icon="mdi-open-in-new"
            @buttonClick="buttonClick('ew')"
        ></DashboardButton>
        <DashboardButton
            title="Virtual TV"
            v-if="instance.tvUrl !== null"
            icon="mdi-open-in-new"
            @buttonClick="buttonClick('tv')"
        ></DashboardButton>
        <DashboardButton
            v-show="instance.activatedFeatures !== null && instance.activatedFeatures.includes('housekeeping')"
            title="Housekeeping"
            icon="mdi-open-in-new"
            @buttonClick="buttonClick('hk')"
        ></DashboardButton>
        <DashboardButton
            v-show="instance.activatedFeatures !== null && instance.activatedFeatures.includes('touch')"
            title="Enterprise Touch"
            icon="mdi-open-in-new"
            @buttonClick="buttonClick('touch')"
        ></DashboardButton>
    </v-layout>
</template>

<script>
    import DashboardField from "../core/dashboard/DashboardField";
    import DashboardButton from "../core/dashboard/DashboardButton";

    export default {
        name: "DetailsInfoFields",
        components: {DashboardButton, DashboardField},
        props: {
            instance: Object,
        },
        methods: {
            buttonClick(type) {
                if (type === 'ew') window.open('https://portal.otrum.net/' + this.instance.urlName, '_blank');
                if (type === 'touch') window.open('https://app.otrum.net/' + this.instance.urlName, '_blank');
                if (type === 'hk') window.open('https://hk.otrum.net/' + this.instance.urlName, '_blank');
                if (type === 'tv') window.open(this.instance.tvUrl, '_blank');
            },
        }
    }
</script>

<style scoped>
    .layout {
        padding: 0 4px 0 4px;
    }
</style>