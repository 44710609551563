import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#4a1d61',
                accent: '#8535AF',
                secondary: '#682989',
                info: '#6cacd1',
                error: '#b71c1c',
                success: '#6f9a3c',
                toolbar: '#ffffff',
                background: '#ffffff',
                text: '#000000',
                scrollbarThumb: '#b2b4b5',
                scrollbarThumbHover: '#939596'
            }
        }
    }
});
