import Vue from 'vue'
import VueRouter from 'vue-router'
import List from '../views/List.vue'
import Map from '../views/Map.vue'
import Users from "../views/Users"
import Hotels from "../views/Hotels";
import Details from "../views/Details";
import Home from "../views/Home";
import Status from "../views/Status";
import Alerts from "../views/Alerts";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/list',
        name: 'list',
        component: List
    },
    {
        path: '/map',
        name: 'map',
        component: Map
    },
    {
        path: '/status',
        name: 'status',
        component: Status
    },
    {
        path: '/alerts',
        name: 'alerts',
        component: Alerts
    },
    {
        path: '/users',
        name: 'users',
        component: Users
    },
    {
        path: '/hotels',
        name: 'hotels',
        component: Hotels
    },
    {
        path: '/details',
        name: 'details',
        component: Details
    },
];

const router = new VueRouter({
    routes
});

export default router
