<template>
    <v-app-bar
        app
        color="primary"
        dark
        clipped-left
        class="the-app-bar"
    >
        <AppBarMenu></AppBarMenu>
        <v-avatar size="42" @click="$router.push('/')">
            <img :src="require('@/assets/logo_white.svg')" alt="logo_white">
        </v-avatar>
        <v-layout :align-center="true" :fill-height="true">
            <v-tabs
                background-color="transparent"
                :grow="false"
                :show-arrows="false"
                height="64px"
                hide-slider
            >
                <v-tab to="/">
                    Home
                </v-tab>
                <v-tab to="/list">
                    List
                </v-tab>
                <v-tab to="/map">
                    Map
                </v-tab>
                <v-tab to="/status">
                    Status
                </v-tab>
                <v-tab to="/alerts">
                    Alerts
                </v-tab>
                <v-tab to="/users" v-if="$store.state.isAdmin">
                    Users
                </v-tab>
                <v-tab to="/hotels" v-if="$store.state.isAdmin">
                    Hotels
                </v-tab>
            </v-tabs>
        </v-layout>

        <v-spacer></v-spacer>

        <TheSearchField></TheSearchField>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <UserActions
            v-show="$route.name === 'users'"
        ></UserActions>
        <AlertActions
            v-show="$route.name === 'alerts'"
        ></AlertActions>
        <ListActions
            v-if="$route.name === 'list'"
        ></ListActions>

        <v-btn
            icon
            @click="$emit('refresh')"
        >
            <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <TheNotifications></TheNotifications>
    </v-app-bar>
</template>

<script>
    import UserActions from "../users/UserActions";
    import TheSearchField from "./TheSearchField";
    import TheNotifications from "./TheNotifications";
    import AppBarMenu from "../core/appbar/AppBarMenu";
    import AlertActions from "../alerts/AlertActions";
    import ListActions from "../list/ListActions";

    export default {
        name: "TheAppBar",
        components: {ListActions, AlertActions, AppBarMenu, TheNotifications, TheSearchField, UserActions},
    }
</script>

<style lang="scss">
    .the-app-bar {
        padding-right: 4px;
        padding-left: 4px;

        .v-btn {
            margin-right: 8px;
        }

        .v-tab {
            font-size: 1rem;
        }

        .v-slide-group__prev, .v-slide-group__next {
            display: none;
        }

        .v-tabs {
            width: 360px;
        }

        .v-avatar {
            margin-right: 14px;
        }
    }

    .is-admin .the-app-bar {
        .v-tabs {
            min-width: 540px;
        }
    }
</style>