<template>
    <v-bottom-sheet
        v-if="$vuetify.breakpoint.mdAndDown"
        v-model="$store.state.showInstanceTypeSheet"
        scrollable
    >
        <v-card>
            <v-card-title>
                <v-btn
                    icon
                    @click="$store.dispatch('updateShowInstanceTypeSheet', false)"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    Filter by Hotel Type
                </v-toolbar-title>
            </v-card-title>
            <v-card-text>
                <InstanceTypeList></InstanceTypeList>
            </v-card-text>
        </v-card>
    </v-bottom-sheet>
    <v-navigation-drawer
        v-else-if="shouldShowDrawer()"
        permanent
        app
        clipped
        dark
        touchless
        :expand-on-hover="!$store.state.tablet"
        :mini-variant="!$store.state.tablet"
        class="elevation-5"
        mini-variant-width="86px"
        :width="$store.state.tablet ? 100 : 295"
        color="primary"
    >
        <InstanceTypeList></InstanceTypeList>
    </v-navigation-drawer>
</template>

<script>
    import InstanceTypeList from "../core/instancetype/InstanceTypeList";

    export default {
        name: "TheInstanceTypeSelector",
        components: {InstanceTypeList},
        methods: {
            shouldShowDrawer() {
                return this.$route.name === 'map' || this.$route.name === 'list';
            }
        }
    }
</script>

<style scoped>
    .v-card__text {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
    }

    .v-card__title {
        padding-left: 8px !important;
        padding-right: 8px !important;
        padding-bottom: 4px !important;
    }

    .v-card__title .v-toolbar__title {
        margin-left: 5px;
        max-width: calc(100% - 50px);
    }
</style>