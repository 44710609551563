<template>
    <DashboardCard
        title="Firmware"
    >
        <template v-slot:content>
            <v-subheader v-if="instance.clientFirmwaresList == null || instance.clientFirmwaresList.length === 0">
                No firmwares on this instance.
            </v-subheader>
            <v-list dense class="pa-0">
                <v-list-item
                    v-for="i in instance.clientFirmwaresList"
                    :key="i"
                >
                    <v-list-item-title>{{i}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </template>
    </DashboardCard>
</template>

<script>
    import DashboardCard from "../core/dashboard/DashboardCard";

    export default {
        name: "DetailsFirmware",
        components: {DashboardCard},
        props: {
            instance: Object,
        }
    }
</script>