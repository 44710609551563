<template>
    <div v-if="!inView" :style="`height: ${itemHeight}px;`"></div>
    <v-list-item @click="$emit('itemClick', item)" v-else-if="clickableItems">
        <slot name="item" :item="item"></slot>
    </v-list-item>
    <v-list-item v-else>
        <slot name="item" :item="item"></slot>
    </v-list-item>
</template>

<script>
    export default {
        name: "FilterableListItem",
        props: {
            itemHeight: Number,
            index: Number,
            item: Object,
            clickableItems: Boolean,
        },
        computed: {
            inView() {
                const pos = this.index * this.itemHeight;
                const min = this.$store.state.scrollTop - 1000;
                const max = window.innerHeight + this.$store.state.scrollTop + 1000;

                return pos > min && pos < max;
            }
        }
    }
</script>