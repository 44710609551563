<template>
    <div class="home">
        <MobileAppBar>
            <v-toolbar-title>Otrum Status Tool</v-toolbar-title>
        </MobileAppBar>
        <v-toolbar-title v-if="$vuetify.breakpoint.mdAndDown" class="title welcome-title">
            Welcome {{$store.getters.firstName}}
        </v-toolbar-title>

        <v-container grid-list-md class="ma-0 pa-0">
            <v-layout wrap>
                <v-flex xs12 lg7>
                    <HomeMap
                            :username="$store.getters.firstName"
                            ref="mapElem"
                    ></HomeMap>
                </v-flex>
                <v-flex xs12 lg5 v-if="!$vuetify.breakpoint.mdAndDown">
                    <HomeEventLog
                            @rowHover="onEventLogRowHover"
                            @noHover="$refs.mapElem.hideInfoWindow()"
                    ></HomeEventLog>
                </v-flex>
                <v-flex xs12 lg4>
                    <HomeSummary
                            type="enterprise"
                            title="Enterprise"
                            clientTitle="TV"
                    ></HomeSummary>
                </v-flex>
                <v-flex xs12 lg4>
                    <HomeSummary
                            type="chromecast"
                            title="OtrumCast"
                            clientTitle="ChromeCast"
                    ></HomeSummary>
                </v-flex>
                <v-flex xs12 lg4>
                    <HomeVersions></HomeVersions>
                </v-flex>
                <v-flex xs12 lg3>
                    <HomeOccupancy
                    ></HomeOccupancy>
                </v-flex>
                <v-flex xs12 lg3>
                    <HomeBackups></HomeBackups>
                </v-flex>
                <v-flex xs12 lg6 v-if="false">
                    <HomeOS></HomeOS>
                </v-flex>
                <v-flex xs12 lg6 v-if="!$vuetify.breakpoint.mdAndDown">
                    <HomeTvTypes></HomeTvTypes>
                </v-flex>
                <v-flex xs12 lg12 v-if="$store.state.instances.list.length >= 500">
                    <HomeGroups
                            service="enterprise"
                            :by-screens="false"
                    ></HomeGroups>
                </v-flex>
                <v-flex xs12 lg12 v-if="$store.state.instances.list.length >= 500">
                    <HomeGroups
                            service="enterprise"
                            :by-screens="true"
                    ></HomeGroups>
                </v-flex>
                <v-flex xs12 lg12 v-if="$store.state.instances.list.length >= 500">
                    <HomeGroups
                            service="chromecast"
                            :by-screens="false"
                    ></HomeGroups>
                </v-flex>
                <v-flex xs12 lg12 v-if="$store.state.instances.list.length >= 500">
                    <HomeGroups
                            service="chromecast"
                            :by-screens="true"
                    ></HomeGroups>
                </v-flex>
                <v-flex xs12 lg3 v-if="$store.state.isAdmin">
                    <HomePushServer
                        title="DS Push 1"
                        url="https://status.otrum.net/push/prod/1/status"
                    ></HomePushServer>
                </v-flex>
                <v-flex xs12 lg3 v-if="$store.state.isAdmin">
                    <HomePushServer
                        title="DS Push 2"
                        url="https://status.otrum.net/push/prod/2/status"
                    ></HomePushServer>
                </v-flex>
                <v-flex xs12 lg3 v-if="$store.state.isAdmin">
                    <HomePushServer
                        title="DS Test Push 1"
                        url="https://status.otrum.net/push/test/1/status"
                    ></HomePushServer>
                </v-flex>
                <v-flex xs12 lg3 v-if="$store.state.isAdmin">
                    <HomePushServer
                        title="DS Test Push 2"
                        url="https://status.otrum.net/push/test/2/status"
                    ></HomePushServer>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import HomeSummary from "../components/home/HomeSummary";
    import HomeEventLog from "../components/home/HomeEventLog";
    import HomeVersions from "../components/home/HomeVersions";
    import HomeOS from "../components/home/HomeOs";
    import HomeBackups from "../components/home/HomeBackups";
    import HomePushServer from "../components/home/HomePushServer";
    import MobileAppBar from "../components/core/appbar/MobileAppBar";
    import HomeTvTypes from "../components/home/HomeTvTypes";
    import HomeMap from "../components/home/HomeMap";
    import HomeGroups from "../components/home/HomeGroups";
    import HomeOccupancy from "../components/home/HomeOccupancy";

    export default {
        name: "Home",
        components: {
            HomeTvTypes,
            MobileAppBar,
            HomeSummary,
            HomePushServer,
            HomeBackups,
            HomeOS,
            HomeVersions,
            HomeEventLog,
            HomeMap,
            HomeGroups,
            HomeOccupancy
        },
        methods: {
            onEventLogRowHover(item) {
                this.$refs.mapElem.displayEventBy(item);
            }
        }
    }
</script>

<style scoped>
    .container {
        max-width: 100%;
    }

    .welcome-title {
        max-width: calc(100% - 50px);
        margin-bottom: 12px;
    }
</style>

<style lang="scss">
    .home {
        padding: 10px 16px 10px 16px;
    }

    .md-and-down .home {
        padding: 8px 12px 8px 12px;
    }
</style>