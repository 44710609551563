<template>
    <div>
        <v-btn
                text
                height="64px"
                v-show="show"
                @click="exportList"
        >
            <v-icon>mdi-file-download</v-icon>
            Export
        </v-btn>
        <v-btn
                text
                height="64px"
                v-show="show"
                @click="exportAll"
        >
            <v-icon>mdi-file-download</v-icon>
            Export All
        </v-btn>
    </div>
</template>

<script>
    import API from "../../lib/api";
    import {mapGetters} from "vuex";

    export default {
        name: "ListActions",
        methods: {
            exportList() {
                const columns = [];
                const rows = [];
                const toIgnore = ['tvScreenshot'];

                this.$instanceList.headers.forEach(h => {
                    if (!toIgnore.includes(h.value) && h.enabled === true) {
                        columns.push({
                            title: h.text,
                            index: h.value,
                        });
                    }
                });

                let items = this.$instanceList.filteredItems;

                if (this.$instanceList.sortBy !== null) {
                    const field = this.$instanceList.sortBy;
                    const desc = this.$instanceList.sortDesc;

                    items = items.sort((a, b) => {
                        let valueA = typeof a[field] === 'string' ? a[field].toLowerCase() : a[field];
                        let valueB = typeof a[field] === 'string' ? b[field].toLowerCase() : b[field];

                        if (valueA > valueB) return desc ? -1 : 1;
                        if (valueA < valueB) return desc ? 1 : -1;
                        return 0;
                    });
                }

                items.forEach(i => {
                    const row = {
                        values: []
                    };

                    columns.forEach(c => {
                        let value = i[c.index];

                        if (c.index === 'condition') {
                            value = i[c.index] === 'z' ? '' : i[c.index];
                        } else if (c.index === 'clientFirmwaresList') {
                            value = i[c.index].join();
                        } else if (c.index === 'licenseValid') {
                            value = i.licenseValid === null ? false : i.licenseValid;
                        } else if (c.index === 'activationDate') {
                            if (i.service === 'chromecast') {
                                value = "n/a";
                            } else {
                                value = typeof i.activationDate !== 'string' ? null : i.activationDate.substr(0, 10);
                            }
                        }

                        row.values.push(value);
                    });

                    rows.push(row);
                });
                this.postToExport(columns, rows);
            },

            exportAll() {
                const rows = [];

                const columns = [
                    { title: 'Hotel ID', index: 'marsha' },
                    { title: 'Name', index: 'name' },
                    { title: 'Type', index: 'hotelType' },
                    { title: 'Online', index: 'online' },
                    { title: 'MaxClients', index: 'maxClientsFormatted' },
                    //{ title: 'TV Screenshot', index: 'hasTvScreenshot' },
                    { title: 'Facility ID', index: 'facilityId' },
                    { title: 'Brand', index: 'brandTitle' },
                    { title: 'Chain', index: 'ownerTitle' },
                    { title: 'Partner', index: 'group' },
                    { title: 'Country', index: 'country' },
                    { title: 'Condition', index: 'condition' },
                    { title: 'Last Update', index: 'updatedDate' },
                    { title: 'Last Status Change', index: 'lastArchiveDate' },
                    { title: 'Act. Date', index: 'activationDate' },
                    { title: 'PMS', index: 'pmsLinkUp' },
                    { title: 'Standalone', index: 'standalone' },
                    { title: 'Rooms', index: 'roomCnt' },
                    { title: 'Occ.', index: 'occupied' },
                    { title: 'TVs', index: 'numberOfClients' },
                    { title: 'Off. TVs', index: 'offlineTvs' },
                    { title: 'Wakeups', index: 'pendingWakeups' },
                    { title: 'Version', index: 'version' },
                    { title: 'Backup', index: 'hasBackup' },
                    { title: 'OS', index: 'operatingSystem' },
                    { title: 'Timezone', index: 'timeZone' },
                    { title: 'Server Time', index: 'serverTime' },
                    { title: 'Uptime', index: 'uptime' },
                    { title: 'Demo', index: 'demoHotel' },
                    { title: 'License', index: 'licenseValid' },
                    { title: 'TV Types', index: 'clientFirmwaresList' },
                    { title: 'Features', index: 'activatedFeatures' }
                ];

                let items = this.instancesComputed;

                items.forEach(i => {
                    const row = {
                        values: []
                    };

                    columns.forEach(c => {
                        let value = i[c.index];

                        if (c.index === 'condition') {
                            value = i[c.index] === 'z' ? '' : i[c.index];
                        } else if (c.index === 'clientFirmwaresList') {
                            if(Array.isArray(i[c.index])) {
                                value = i[c.index].join();
                            }
                        } else if (c.index === 'licenseValid') {
                            value = i.licenseValid === null ? false : i.licenseValid;
                        } else if (c.index === 'activationDate') {
                            if (i.service === 'chromecast') {
                                value = "n/a";
                            } else {
                                value = typeof i.activationDate !== 'string' ? null : i.activationDate.substr(0, 10);
                            }
                        }

                        row.values.push(value);
                    });

                    rows.push(row);
                });
                this.postToExport(columns, rows);
            },

            postToExport(columns, rows) {
                const data = {columns: columns, rows: rows};

                const form = document.createElement('form');
                form.style = 'display: none';
                form.setAttribute('method', 'POST');
                form.setAttribute('action', API.getBaseUrl() + '/facilities/exportColumns/' + this.$store.state.clientToken, true);

                const dataInput = document.createElement('input');
                dataInput.setAttribute('type', 'text');
                dataInput.setAttribute('name', 'columnData');
                dataInput.setAttribute('value', JSON.stringify(data));

                form.appendChild(dataInput);
                document.body.appendChild(form);

                form.submit();

                setTimeout(() => {
                    document.body.removeChild(form);
                }, 30000);
            }
        },
        computed: {
            ...mapGetters({
                instancesComputed: 'instances/instancesComputed'
            }),
            show() {
                return this.$instanceList !== undefined;
            }
        }
    }
</script>

<style scoped>
    .v-btn {
        margin-left: 8px;
    }
</style>