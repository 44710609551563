<template>
    <div>
        <FilterableList
            v-if="$vuetify.breakpoint.mdAndDown"
            :items="$store.state.users.list"
            :skeleton="$store.state.users.fetched === null"
            :filterable-fields="['name', 'username']"
            filter-text="Search for users"
            @itemClick="rowClick"
        >
            <template v-slot:item="{item}">
                <v-list-item-content>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.username}}</v-list-item-subtitle>
                </v-list-item-content>
            </template>
            <template v-slot:headerActions>
                <v-btn
                    icon
                    @click="createUser"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
        </FilterableList>
        <FilterableTable
            v-else
            :headers="headers"
            :items="$store.state.users.list"
            :skeleton="$store.state.users.fetched === null"
            initial-sort="name"
            @rowClick="rowClick"
            table-class="user-list"
        >
            <template v-slot:item="{item, header}">
                <td v-if="header.value === 'groups' || header.value === 'owners'" class="chip-row">
                    <span class="chip-span" v-for="i in item[header.value]">
                        {{i.name}}
                    </span>
                </td>
                <td v-else>{{item[header.value]}}</td>
            </template>
        </FilterableTable>
    </div>
</template>

<script>
    import FilterableTable from "../components/core/filterable/FilterableTable";
    import FilterableList from "../components/core/filterable/FilterableList";

    export default {
        name: "Users",
        components: {FilterableList, FilterableTable},
        data: () => ({
            headers: [
                {text: 'Name', value: 'name', enabled: true, filterType: 'string', filterValue: null},
                {text: 'Username', value: 'username', enabled: true, filterType: 'string', filterValue: null},
                {text: 'Roles', value: 'groups', enabled: true},
                {text: 'Chains', value: 'owners', enabled: true},
            ],
        }),
        methods: {
            rowClick(user) {
                this.$userEditor.show(user, true);
            },
            createUser() {
                this.$userEditor.show(null, false);
            }
        }
    }
</script>

<style scoped>
    .chip-row {
        padding-bottom: 5px !important;
        padding-top: 10px !important;
    }

    .chip-span {
        background: #e0e0e0;
        border-radius: 16px;
        padding: 6px 12px 6px 12px;
        margin-right: 5px;
        margin-bottom: 5px;
        float: left;
        vertical-align: middle;
        overflow: hidden;
    }
</style>