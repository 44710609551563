<template>
    <DialogEditor
        :title="edit ? 'Edit Alert' : 'New Alert'"
        v-model="showEditor"
    >
        <template v-slot:content>
            <v-autocomplete
                :items="$store.state.instances.list"
                v-model="instance"
                return-object
                item-text="name"
                label="Instance"
                placeholder="Start typing to search"
                :rules="[v => !!v || 'Field required']"
                :prepend-icon="instance === null ? '' : instance.service === 'enterprise' ?
                instance.hosted ? 'mdi-cloud' : 'mdi-office-building' : 'mdi-google-chrome'"
            >
                <template v-slot:item="{item}">
                    <v-list-item-icon>
                        <v-icon :color="item.online ? 'success' : 'error'">
                            {{item.service === 'enterprise' ? item.hosted ? 'mdi-cloud' : 'mdi-office-building' : 'mdi-google-chrome'}}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                </template>
            </v-autocomplete>
            <v-autocomplete
                :items="types"
                v-model="type"
                item-text="title"
                item-value="id"
                label="Alert me when"
                :rules="[v => !!v || 'Field required']"
            ></v-autocomplete>
            <v-autocomplete
                v-if="type === 'tvs_offline'"
                :items="offlineTvSelection"
                v-model="offlineTvs"
                label="Percentage of offline TVs"
                :rules="[v => !!v || 'Field required']"
            ></v-autocomplete>
            <v-text-field
                type="number"
                label="Threshold"
                v-model="threshold"
                hint="Minutes to wait before alerting"
                persistent-hint
                :rules="[v => !!v || 'Field required', v => v > -1 || 'Threshold must be 0 minutes or higher']"
            ></v-text-field>
            <v-combobox
                multiple
                chips
                deletable-chips
                v-model="recipients"
                label="Recipients"
                hint="Type an email into the field then hit enter"
                persistent-hint
                @change="onRecipientChange"
            ></v-combobox>
        </template>
        <template v-slot:actions="{valid}">
            <v-btn
                color="error"
                @click="deleteAlert"
                v-show="edit"
                :text="$vuetify.breakpoint.mdAndDown"
            >
                Delete
            </v-btn>
            <v-btn
                color="accent"
                @click="save"
                :disabled="!valid || recipients.length === 0"
                :text="$vuetify.breakpoint.mdAndDown"
            >
                {{edit ? 'Save' : 'Create'}}
            </v-btn>
        </template>
    </DialogEditor>
</template>

<script>
    import Vue from 'vue';
    import DialogEditor from "../core/DialogEditor";

    export default {
        name: "TheAlertEditor",
        components: {DialogEditor},
        data: () => ({
            showEditor: false,
            edit: false,
            alert: null,
            instance: null,
            recipients: [],
            types: [
                {id: 'facility_online', title: 'Facility comes online'},
                {id: 'facility_offline', title: 'Facility goes offline'},
                {id: 'tvs_offline', title: 'TVs go offline'},
            ],
            type: 'facility_online',
            threshold: 5,
            offlineTvs: '80%',
            offlineTvSelection: [
                '10%',
                '20%',
                '30%',
                '40%',
                '50%',
                '60%',
                '70%',
                '80%',
                '90%',
            ]
        }),
        created() {
            Vue.prototype.$alertEditor = this;
        },
        methods: {
            show(alert, edit, instance = null) {
                this.edit = edit;
                this.alert = alert;
                this.instance = instance;
                this.type = edit ? alert.type : 'facility_online';
                this.threshold = edit ? alert.threshold : 5;
                this.offlineTvs = edit && alert.type === 'tvs_offline' ? alert.extraParam : '80%';
                this.recipients = edit ? alert.recipients.split(',') : this.emailCheck(this.$store.getters.user.username) ?
                    [this.$store.getters.user.username] : [];
                this.showEditor = true;
            },
            save() {
                const alert = this.edit ? this.alert : {
                    facilityId: this.instance.facilityId,
                    serviceType: this.instance.service,
                };

                this.$store.dispatch('alerts/save', {
                    edit: this.edit, item: {
                        facilityId: alert.facilityId,
                        serviceType: alert.serviceType,
                        type: this.type,
                        threshold: this.threshold,
                        recipients: this.recipients.join(),
                        id: this.edit ? alert.id : undefined,
                        extraParam: this.type === 'tvs_offline' ? this.offlineTvs : ""
                    }
                });

                this.showEditor = false;
            },
            deleteAlert() {
                this.$confirm.delete('Delete Alert', 'Are you sure you wish to delete this alert?', () => {
                    this.$store.dispatch('alerts/delete', {item: this.alert});
                    this.showEditor = false;
                });
            },
            emailCheck(email) {
                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
            onRecipientChange() {
                const newEmail = this.recipients[this.recipients.length - 1];

                if (!this.emailCheck(newEmail)) {
                    this.recipients.splice(this.recipients.length - 1, 1);
                }
            }
        }
    }
</script>