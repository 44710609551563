<template>
    <DashboardCard
        title="Condition"
    >
        <template v-slot:content>
            <v-list>
                <v-subheader v-if="items.length === 0">
                    No conditions have been set for this instance yet.
                </v-subheader>
                <v-list-item
                    v-for="i in items"
                    :key="i.key"
                    @click="editCondition(i)"
                >
                    <v-list-item-action>
                        <v-icon v-if="i.condition === 'New'" color="accent">mdi-plus-circle</v-icon>
                        <v-icon v-else-if="i.condition === 'Issues'" color="error">mdi-alert-circle</v-icon>
                        <v-icon v-else-if="i.condition === 'OK'" color="success">mdi-check-circle</v-icon>
                        <v-icon v-else-if="i.condition === 'In progress'" color="secondary">mdi-progress-wrench</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{i.condition}}</v-list-item-title>
                        <v-list-item-subtitle>{{i.comment}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        {{i.timestampReadable}}
                        <br>
                        {{i.username}}
                    </v-list-item-action>
                </v-list-item>
            </v-list>

            <DetailsConditionEditor
                v-model="editor.show"
                :edit="editor.edit"
                :condition="editor.condition"
                :instance="instance"
            ></DetailsConditionEditor>
        </template>
        <template v-slot:titleActions>
            <v-btn
                text
                color="accent"
                @click="newCondition"
                :loading="$store.state.conditions.processing"
            >
                Update
            </v-btn>
        </template>
    </DashboardCard>
</template>

<script>
    import DashboardCard from "../core/dashboard/DashboardCard";
    import DetailsConditionEditor from "./DetailsConditionEditor";
    import DateLib from "../../lib/date";

    export default {
        name: "DetailsCondition",
        components: {DetailsConditionEditor, DashboardCard},
        props: {
            instance: Object,
        },
        data: () => ({
            editor: {
                show: false,
                edit: false,
                condition: null,
            }
        }),
        computed: {
            items() {
                return this.$store.state.conditions.list
                .filter(i => i.facilityId === this.instance.facilityId && i.serviceType === this.instance.service)
                .sort((a, b) => {
                    if (a.timeStamp < b.timeStamp) return 1;
                    if (a.timeStamp === b.timeStamp) return 0;
                    return -1;
                }).map(i => {
                    i.timestampReadable = DateLib.formatToReadable(new Date(i.timeStamp));
                    return i;
                });
            }
        },
        methods: {
            newCondition() {
                this.editor = {
                    show: true,
                    edit: false,
                    condition: null,
                }
            },
            editCondition(n) {
                this.editor = {
                    show: true,
                    edit: true,
                    condition: n,
                }
            }
        }
    }
</script>