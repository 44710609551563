<!--suppress JSUnfilteredForInLoop -->
<template>
    <DashboardCard
            :title="title"
            :no-scroll="true"
            :height="400"
            content-padding
    >
        <template v-slot:content>
            <v-container>
                <v-row>
                    <v-col cols="6" class="summary-body">
                        <div class="heading">Servers</div>
                        <span class="label">Total:</span><span class="value">{{stats.servers.total}}</span><br>
                        <span class="label">Online:</span><span class="value">{{stats.servers.online}}</span><br>
                        <span class="label">Offline:</span><span class="value">{{stats.servers.offline}}</span><br>
                    </v-col>
                    <v-col cols="6" class="summary-body">
                        <div class="heading">{{clientTitle}}s</div>
                        <span class="label">Total:</span><span class="value">{{stats.clients.total}}</span><br>
                        <span class="label">Online:</span><span class="value">{{stats.clients.online}}</span><br>
                        <span class="label">Offline:</span><span class="value">{{stats.clients.offline}}</span><br>
                        <span class="label">Unknown:</span><span class="value">{{stats.clients.unknown}}</span><br>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col
                    cols="6"
                    >
                        <VueApexChart
                                v-if="$route.name === 'home'"
                                height="200"
                                type="donut"
                                :series="[ stats.servers.online, stats.servers.offline ]"
                                :options="{ labels: [ 'online', 'offline' ], colors: [ '#008ffb', '#b71c1c' ], legend: { show: false } }"
                                @dataPointSelection="onChartSelection"
                        ></VueApexChart>
                    </v-col>
                    <v-col
                    cols="6"
                    >
                        <VueApexChart
                                v-if="$route.name === 'home'"
                                height="200"
                                type="donut"
                                :series="[ stats.clients.online, stats.clients.offline, stats.clients.unknown ]"
                                :options="{ labels: [ 'online', 'offline', 'unknown' ], colors: [ '#008ffb', '#b71c1c', '#b79a1c' ], legend: { show: false } }"
                        ></VueApexChart>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </DashboardCard>
</template>

<script>
    import DashboardCard from "../core/dashboard/DashboardCard";
    import VueApexChart from 'vue-apexcharts';

    export default {
        name: "HomeSummary",
        components: {DashboardCard, VueApexChart},
        props: {
            type: String,
            title: String,
            clientTitle: String
        },
        data: () => ({
            optionsServers: {}
        }),
        computed: {
            stats() {
                const data = {
                    servers: {total: 0, online: 0, offline: 0},
                    clients: {total: 0, online: 0, offline: 0, unknown: 0}
                };

                this.$store.state.instances.list.forEach(i => {
                    if(i.service === this.type) {
                        data.servers.total++;
                        i.online ? data.servers.online++ : data.servers.offline++;
                        if (i.hasOwnProperty('clientsOnline') && typeof i.clientsOnline === 'number') {
                            data.clients.total += i.numberOfClients;
                            if(i.online) {
                                data.clients.online += i.clientsOnline;
                                data.clients.offline += i.numberOfClients - i.clientsOnline;
                            } else {
                                data.clients.unknown += i.numberOfClients;
                            }
                        }
                    }
                });

                return data;
            }
        },
        methods: {
            onChartSelection(ev, ctx, cfg) {
                let type = this.type === 'enterprise' ? "offlineHotels" : "offlineCasts";
                if(cfg.dataPointIndex === 0) {
                    /* online */
                    type = this.type === 'enterprise' ? "onlineHotels" : "onlineCasts";
                }
                this.$store.dispatch('instances/applyFilter', { key: 'state', val: type });
                //this.$router.push('/list');
            }
        }
    }
</script>

<style scoped lang='scss'>
    .summary-body {
        .heading {
            font-size: 1.3em;
            line-height: 1.5em;
            border-bottom: 1px solid gray;
            margin-bottom: 10px;
        }
        .value {
            float: right;
        }
        font-size: 1em;
        line-height: 1.3em;
    }

    .label {
        width: 50px;
        font-weight: bold;
    }
</style>
