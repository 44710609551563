<!--suppress JSUnfilteredForInLoop -->
<template>
    <FilterableList
        v-if="$vuetify.breakpoint.mdAndDown"
        :items="instancesFiltered"
        :skeleton="$store.state.instances.fetched === null"
        :filterable-fields="['name', 'brandTitle', 'ownerTitle']"
        filter-text="Search for hotels"
        @itemClick="rowClick"
    >
        <template v-slot:headerActions>
            <v-btn
                icon
                @click="$store.dispatch('updateShowInstanceTypeSheet', true)"
            >
                <v-icon>{{getInstanceTypeIcon()}}</v-icon>
            </v-btn>
        </template>
        <template v-slot:item="{item}">
            <v-list-item-action>
                <v-icon
                    :color="item.online ? 'success' : 'error'"
                >
                    {{item.service === 'enterprise' ? item.hosted ? 'mdi-cloud' : 'mdi-office-building' : 'mdi-google-chrome'}}
                </v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{item.name}}</v-list-item-title>
                <v-list-item-subtitle>{{item.brandTitle}}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </FilterableList>
    <div
        v-else
    >
        <FilterableTable
            :items="instancesFiltered"
            :headers="headers"
            :skeleton="$store.state.instances.fetched === null"
            table-class="instance-list"
            :enable-ctrl-click="true"
            @ctrlClick="rowCtrlClick"
            @rowClick="rowClick"
            @rowRightClick="rowRightClick"
        >
            <template v-slot:item="{item, header}">
                <td v-if="header.value === 'name'" style="min-width: 300px;">
                    <i
                        class="mdi v-icon"
                        :class="`${item.online ? 'success--text' : 'error--text'}
                        ${item.service === 'enterprise' ? item.hosted ? 'mdi-cloud' : 'mdi-office-building' : 'mdi-google-chrome'}`"
                    ></i>
                    {{item[header.value]}}
                </td>
                <td v-else-if="header.value === 'facilityId'" style="min-width: 330px;">
                    {{item[header.value]}}
                </td>
                <td v-else-if="header.value === 'marsha'" style="width: 100px;">
                    {{item[header.value]}}
                </td>
                <td v-else-if="header.value === 'hasTvScreenshot'">
                    <img
                        v-if="item.hasTvScreenshot && item.service === 'enterprise'"
                        :src="item.tvScreenshotUrl"
                        width="320"
                        height="180"
                        style="margin-top: 5px;"
                        @click.stop="openImage(item.tvScreenshotUrl)"
                    >
                </td>
                <td v-else-if="header.value === 'condition'">
                    <i
                        v-if="item.condition === 'New'"
                        class="v-icon mdi accent--text mdi-plus-circle"
                    ></i>
                    <i
                        v-if="item.condition === 'Issues'"
                        class="v-icon mdi error--text mdi-alert-circle"
                    ></i>
                    <i
                        v-if="item.condition === 'OK'"
                        class="v-icon mdi success--text mdi-check-circle"
                    ></i>
                    <i
                        v-if="item.condition === 'In progress'"
                        class="v-icon mdi secondary--text mdi-progress-wrench"
                    ></i>
                    <span>{{item.condition === 'z' ? '' : item.condition}}</span>
                </td>
                <td v-else-if="header.value === 'pmsLinkUp'">
                    <i v-if="item.online"
                        class="mdi v-icon mdi-power"
                        :class="`${item.pmsLinkUp ? 'success--text' : 'error--text'}`"
                    ></i>
                </td>
                <td v-else-if="header.value === 'licenseValid'">
                    <i
                        class="mdi mdi-clipboard-check v-icon"
                        :class="item.licenseValid ? 'success--text' : 'error--text'"
                    ></i>
                </td>
                <td v-else-if="header.value === 'standalone'">
                    <i
                        class="mdi v-icon"
                        :class="item.standalone ? 'success--text mdi-check-circle-outline' : 'error--text mdi-minus-circle-outline'"
                    ></i>
                </td>
                <td v-else-if="header.value === 'activatedFeatures'" class="chip-row">
                    <template v-if="item.activatedFeatures != null">
                        <span
                            class="chip-span"
                            v-for="f in item.activatedFeatures.split(',')"
                        >
                        {{f}}
                        </span>
                    </template>
                </td>
                <td v-else-if="header.value === 'hasBackup'">
                    <i
                        class="mdi v-icon"
                        :class="item.hasBackup ? 'mdi-database-check success--text' : 'mdi-database-remove error--text'"
                    ></i>
                </td>
                <td v-else-if="header.value === 'clientFirmwaresList'">
                    <div
                        v-for="(f, index) in item.clientFirmwaresList"
                        :key="f"
                    >
                        <li v-if="index < 5">{{f}}</li>
                    </div>
                    <div v-if="item.clientFirmwaresList.length > 5">+{{item.clientFirmwaresList.length - 5}} more</div>
                </td>
                <td v-else-if="header.value === 'activationDate' && item.service === 'chromecast'">
                    n/a
                </td>
                <td v-else>{{item[header.value]}}</td>
            </template>
        </FilterableTable>

        <InstanceActionsMenu
            :instance="actionMenu.instance"
            v-model="actionMenu.show"
        ></InstanceActionsMenu>
    </div>
</template>

<script>
    import Storage from "../lib/storage";
    import FilterableTable from "../components/core/filterable/FilterableTable";
    import FilterableList from "../components/core/filterable/FilterableList";
    import InstanceActionsMenu from "../components/core/instanceactions/InstanceActionsMenu";
    import {mapGetters} from "vuex";

    export default {
        name: "List",
        components: {InstanceActionsMenu, FilterableList, FilterableTable},
        data: () => ({
            actionMenu: {
                show: false,
                instance: null,
            },
            headers: [
                {text: 'Hotel ID', value: 'marsha', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Name', value: 'name', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Type', value: 'hotelType', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Online', value: 'online', enabled: false, filterType: 'bool', filterValue: 0},
                {text: 'MaxClients', value: 'maxClientsFormatted', enabled: false },
                {text: 'TV Screenshot', value: 'hasTvScreenshot', enabled: false, filterType: 'bool', filterValue: 0},
                {text: 'Facility ID', value: 'facilityId', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Brand', value: 'brandTitle', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Chain', value: 'ownerTitle', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Partner', value: 'group', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Country', value: 'country', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Condition', value: 'condition', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Last Update', value: 'updatedDate', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Last Status Change', value: 'lastArchiveDate', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Act. Date', value: 'activationDate', enabled: false, filterType: 'string', filterValue: null},
                {text: 'PMS', value: 'pmsLinkUp', enabled: false, filterType: 'bool', filterValue: 0},
                {text: 'Standalone', value: 'standalone', enabled: false, filterType: 'bool', filterValue: 0},
                {text: 'Rooms', value: 'roomCnt', enabled: false},
                {text: 'Occ.', value: 'occupied', enabled: false},
                {text: 'TVs', value: 'numberOfClients', enabled: false},
                {text: 'Off. TVs', value: 'offlineTvs', enabled: false},
                {text: 'Wakeups', value: 'pendingWakeups', enabled: false},
                {text: 'Version', value: 'version', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Backup', value: 'hasBackup', enabled: false, filterType: 'bool', filterValue: 0},
                {text: 'OS', value: 'operatingSystem', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Timezone', value: 'timeZone', enabled: false, filterType: 'string', filterValue: null},
                {text: 'Server Time', value: 'serverTime', enabled: false},
                {text: 'Uptime', value: 'uptime', enabled: false},
                {text: 'Demo', value: 'demoHotel', enabled: false},
                {text: 'License', value: 'licenseValid', enabled: false},
                {text: 'TV Types', value: 'clientFirmwaresList', enabled: false},
                {text: 'Features', value: 'activatedFeatures', enabled: false},
            ],
        }),
        created() {
            const enabledHeaders = Storage.get('enabledHeaders', 'name,lastArchiveDate,pmsLinkUp,roomCnt,numberOfClients,version,backup,licenseValid').split(',');

            enabledHeaders.forEach(h => {
                this.headers.forEach(header => {
                    if (header.value === h) {
                        this.$set(header, 'enabled', true);
                    }
                });
            });
        },
        watch: {
            headers: {
                deep: true,
                handler(headers) {
                    const enabledHeaders = headers.filter(h => h.enabled).map(h => h.value);
                    Storage.set('enabledHeaders', enabledHeaders.join());
                }
            }
        },
        computed: {
            ...mapGetters({
                instancesFiltered: 'instances/instancesFiltered'
            })
        },
        methods: {
            rowCtrlClick(i) {
                window.open('https://portal.otrum.net/' + i.urlName, '_blank');
            },
            rowClick(i) {
                this.$router.push({path: '/details', query: {facilityId: i.facilityId, service: i.service}});
            },
            rowRightClick(i) {
                this.actionMenu.show = false;

                setTimeout(() => {
                    this.actionMenu = {
                        show: true,
                        instance: i,
                    };
                }, 100);
            },
            openImage(url) {
                this.$imageViewer.show(url);
            },
            getInstanceTypeIcon() {
                const type = this.$store.state.instances.selectedType;
                let icon = 'mdi-domain';

                if (type === 'hotels') icon = 'mdi-hospital-building';
                if (type === 'cloudHotels') icon = 'mdi-cloud';
                if (type === 'onPremHotels') icon = 'mdi-office-building';
                if (type === 'casts') icon = 'mdi-google-chrome';
                if (type === 'offlineHotels' || type === 'offlineCasts') icon = 'mdi-flash-circle';
                if (type === 'castScreens' || type === 'hotelScreens') icon = 'mdi-television-classic';

                return icon;
            }
        }
    }
</script>

<style scoped>
    .chip-row {
        padding-bottom: 5px !important;
        padding-top: 10px !important;
        min-width: 300px;
    }

    .chip-span {
        background: #e0e0e0;
        border-radius: 16px;
        padding: 6px 12px 6px 12px;
        margin-right: 5px;
        margin-bottom: 5px;
        float: left;
        vertical-align: middle;
        overflow: hidden;
    }

    img {
        cursor: zoom-in;
    }
</style>