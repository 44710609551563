<template>
    <v-bottom-sheet
        v-if="$vuetify.breakpoint.mdAndDown"
        scrollable
        v-model="show"
    >
        <template v-slot:activator="{on}">
            <v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>
        </template>
        <AppBarMenuContent
            @close="show = false"
        ></AppBarMenuContent>
    </v-bottom-sheet>
    <v-menu offset-y v-else>
        <template v-slot:activator="{on}">
            <v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>
        </template>
        <AppBarMenuContent></AppBarMenuContent>
    </v-menu>
</template>

<script>
    import AppBarMenuContent from "./AppBarMenuContent";

    export default {
        name: "AppBarMenu",
        components: {AppBarMenuContent},
        data: () => ({
            show: false,
        }),
    }
</script>