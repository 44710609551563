<template>
    <v-bottom-sheet
        v-model="show"
        scrollable
        v-if="$vuetify.breakpoint.mdAndDown"
        content-class="dialog-editor"
    >
        <v-card class="elevation-0">
            <v-card-title>
                <v-btn
                    icon
                    @click="show = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{title}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <slot name="actions" :valid="valid"></slot>
            </v-card-title>
            <v-card-text>
                <v-form v-model="valid">
                    <slot name="content"></slot>
                </v-form>
            </v-card-text>
        </v-card>
    </v-bottom-sheet>
    <v-dialog
        max-width="500px"
        content-class="dialog-editor"
        v-model="show"
        scrollable
        v-else
    >
        <v-card>
            <v-card-title>
                {{title}}
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    @click="show = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form v-model="valid">
                    <slot name="content"></slot>
                </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="show = false"
                >
                    Close
                </v-btn>
                <slot name="actions" :valid="valid"></slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "DialogEditor",
        props: {
            value: Boolean,
            title: String,
        },
        data: () => ({
            valid: true
        }),
        computed: {
            show: {
                get() {
                    return this.value;
                },
                set(input) {
                    this.$emit('input', input);
                }
            }
        }
    }
</script>

<style lang="scss">
    .dialog-editor {
        .v-card__title {
            padding-right: 14px !important;
        }
    }

    .md-and-down .dialog-editor {
        .v-card__title {
            padding-left: 8px !important;
            padding-right: 8px !important;

            .v-toolbar__title {
                margin-left: 5px;
                max-width: calc(100% - 230px);
            }
        }

        .v-card__text {
            padding-left: 18px;
            padding-right: 18px;
        }
    }

    .v-form {
        margin-top: 12px;
        margin-bottom: 12px;
    }
</style>