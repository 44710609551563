<template>
    <v-app-bar
        v-if="$vuetify.breakpoint.mdAndDown"
        dark
        color="primary"
        app
        height="56px"
    >
        <AppBarMenu v-if="!disableMenu"></AppBarMenu>
        <slot></slot>
    </v-app-bar>
</template>

<script>
    import AppBarMenu from "./AppBarMenu";

    export default {
        name: "MobileAppBar",
        components: {AppBarMenu},
        props: {
            disableMenu: {
                default: false,
                type: Boolean,
            }
        }
    }
</script>