<template>
    <div>
        <FilterableList
            v-if="$vuetify.breakpoint.mdAndDown"
            :items="$store.state.hotels.list"
            :skeleton="$store.state.hotels.fetched === null"
            :clickable-items="false"
            :filterable-fields="['name', 'facilityId']"
            filter-text="Search for hotels"
            :item-height="81"
        >
            <template v-slot:item="{item}">
                <v-list-item-content>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                    <v-list-item-subtitle>
                        <v-layout wrap>
                            <v-btn
                                text
                                @click="updateHotel(item, 'enabled')"
                            >
                                <v-icon :color="item.enabled ? 'accent' : ''" large>
                                    {{item.enabled ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off-outline'}}
                                </v-icon>
                                Enabled
                            </v-btn>
                            <v-btn
                                text
                                @click="updateHotel(item, 'active')"
                            >
                                <v-icon :color="item.active ? 'accent' : ''" large>
                                    {{item.active ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off-outline'}}
                                </v-icon>
                                Active
                            </v-btn>
                        </v-layout>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </template>
        </FilterableList>
        <FilterableTable
            v-else
            :items="$store.state.hotels.list"
            :skeleton="$store.state.hotels.fetched === null"
            :headers="headers"
            initial-sort="name"
            table-class="hotel-list"
        >
            <template v-slot:item="{item, header}">
                <td
                    style="cursor: pointer;"
                    v-if="header.value === 'enabled' || header.value === 'active'"
                    @click.stop="updateHotel(item, header.value)"
                >
                    <i
                        class="mdi mdi-toggle-switch accent--text v-icon v-size--large"
                        style="font-size: 50px;"
                        v-if="item[header.value] === true"
                    ></i>
                    <i
                        class="mdi mdi-toggle-switch-off-outline v-icon v-size--large"
                        style="font-size: 50px; opacity: 0.7;"
                        v-else
                    ></i>
                </td>
                <td v-else>{{item[header.value]}}</td>
            </template>
        </FilterableTable>
    </div>
</template>

<script>
    import FilterableTable from "../components/core/filterable/FilterableTable";
    import FilterableList from "../components/core/filterable/FilterableList";

    export default {
        name: "Hotels",
        components: {FilterableList, FilterableTable},
        data: () => ({
            headers: [
                {text: 'Name', value: 'name', enabled: true, filterType: 'string', filterValue: null},
                {text: 'ID', value: 'facilityId', enabled: true, filterType: 'string', filterValue: null},
                {text: 'Enabled', value: 'enabled', enabled: true},
                {text: 'Active', value: 'active', enabled: true},
            ]
        }),
        methods: {
            updateHotel(h, value) {
                this.$store.dispatch('hotels/update', {hotel: h, value: value});
            }
        }
    }
</script>