<template>
    <v-card>
        <div>
            <l-map
                    v-if="$route.name === 'details'"
                    :style="$vuetify.breakpoint.mdAndDown ? 'height: 200px;' : 'height: 360px; z-index: 1;'"
                    :zoom="14"
                    :center="{ lat: parseFloat(instance.lat), lng: parseFloat(instance.lng)}"
                    :options="{ zoomControl: false }"
            >
                <l-tile-layer
                        :url="mapURL"
                        :attribution="attribution">
                </l-tile-layer>
                <l-marker
                        :lat-lng="{lat: parseFloat(instance.lat), lng: parseFloat(instance.lng)}"
                        :name="instance.name"
                        :icon="instance.online ? onlineMarker : offlineMarker"
                >
                </l-marker>
            </l-map>
            <div
                    class="overlay"
                    v-if="!$vuetify.breakpoint.mdAndDown"
            >
                <v-layout
                        wrap
                        class="desktop-title-header"
                        :class="$store.state.scrollTop > 50 ? 'elevation-1' : 'elevation-0'"
                >
                    <v-btn
                            @click="$router.back()"
                            icon
                            style="margin-right: 5px;"
                    >
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-toolbar-title class="title">
                        {{instance ? instance.name : "Unknown ID"}}
                        <span style="float: right;">{{instance ? instance.group + " | " + instance.version  : ""}}</span>
                    </v-toolbar-title>
                </v-layout>

                <v-row>
                    <v-col>

                        <v-layout style="padding: 20px;" wrap>
                            <DashboardButton
                                    :icon="instance.service === 'enterprise' ? instance.hosted ? 'mdi-cloud' : 'mdi-office-building' : 'mdi-google-chrome'"
                                    :color="instance.online ? 'success' : 'error'"
                            ></DashboardButton>
                            <DashboardButton
                                    title="Enterprise Web"
                                    icon="mdi-open-in-new"
                                    @buttonClick="buttonClick('ew')"
                            ></DashboardButton>
                            <DashboardButton
                                    title="Virtual TV"
                                    v-if="instance.tvUrl !== null"
                                    icon="mdi-open-in-new"
                                    @buttonClick="buttonClick('tv')"
                            ></DashboardButton>
                            <DashboardButton
                                    title="Housekeeping"
                                    v-if="hasHousekeeping"
                                    icon="mdi-open-in-new"
                                    @buttonClick="buttonClick('hk')"
                            ></DashboardButton>
                            <DashboardButton
                                    title="Enterprise Touch"
                                    v-if="hasTouch"
                                    icon="mdi-open-in-new"
                                    @buttonClick="buttonClick('touch')"
                            ></DashboardButton>
                        </v-layout>
                    </v-col>
                    <v-col class="shrink" v-if="instance && instance.service === 'enterprise' && instance.tvUrl !== null">
                        <img
                                :src="screenshotUrl !== null ? screenshotUrl : instance.tvScreenshotUrl"
                                height="200"
                                style="background: rgba(0, 0, 0, .75); border-radius: 4px; cursor:zoom-in; margin-right: 10px;"
                                @click="openImage"
                        >
                    </v-col>
                    <v-col
                        v-else
                    >
                        <div style="height: 200px;"></div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    <template>
                        <div>
                            <v-chip
                                    v-if="instance.service === 'enterprise'"
                                    color="accent"
                                    class="ma-1"
                            >
                                Max clients: {{maxClients}}
                            </v-chip>
                            <v-chip
                                    class="ma-1"
                                    color="accent"
                                    v-for="f in instance.activatedFeatures.split(',')"
                                    :key="f"
                            >
                                {{f}}
                            </v-chip>
                        </div>
                    </template>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-img
                v-if="$vuetify.breakpoint.mdAndDown"
                :src="screenshotUrl !== null ? screenshotUrl : instance.tvScreenshotUrl"
                style="background: rgba(0, 0, 0, .75);"
        >
        </v-img>
    </v-card>
</template>

<script>
    import DashboardField from "../core/dashboard/DashboardField";
    import DashboardButton from "../core/dashboard/DashboardButton";

    export default {
        name: "DetailsHeading",
        components: {DashboardButton, DashboardField},
        props: {
            instance: Object,
        },
        data: () => ({
            hasHousekeeping: false,
            hasTouch: false,
            screenshotUrl: null,
            mapURL: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          //attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
            attribution: 'Tiles &copy; Esri',
            onlineMarker: L.icon({
                iconUrl: require('@/assets/marker_online_32.svg'),
                iconSize: [32, 37],
                iconAnchor: [16, 37]
            }),
            offlineMarker: L.icon({
                iconUrl: require('@/assets/marker_offline_32.svg'),
                iconSize: [32, 37],
                iconAnchor: [16, 37]
            }),
        }),
        methods: {
            buttonClick(type) {
                if (type === 'ew') window.open('https://portal.otrum.net/' + this.instance.urlName, '_blank');
                if (type === 'touch') window.open('https://touch.otrum.net/' + this.instance.urlName, '_blank');
                if (type === 'hk') window.open('https://hk.otrum.net/' + this.instance.urlName, '_blank');
                if (type === 'tv') window.open(this.instance.tvUrl, '_blank');
            },
            openImage() {
                this.$imageViewer.show(this.instance.tvScreenshotUrl);
            },
            updateScreenshot() {
                this.screenshotUrl = this.instance.tvScreenshotUrl + '?' + Math.floor(Math.random() * 1000000)
            }
        },
        computed: {
            maxClients() {
                if(this.instance.maxClients === undefined || this.instance.maxClients === 0 || this.instance.maxClients === -1) {
                    return "N/A";
                }
                return this.instance.maxClients;
            },
        },
        async mounted() {
            /**
             * The async stuff(fetch etc) causes to much trouble when receving 404
             */
            const hkXhr = new XMLHttpRequest();
            hkXhr.open('GET', 'https://hk.otrum.net/' + this.instance.urlName + '/Config.js');
            hkXhr.onload = () => {
                this.hasHousekeeping = (hkXhr.status === 200);
            };

            const tXhr = new XMLHttpRequest();
            tXhr.open('GET', 'https://touch.otrum.net/' + this.instance.urlName + '/Config.js');
            tXhr.onload = () => {
                this.hasTouch = (tXhr.status === 200);
            };

            hkXhr.send();
            tXhr.send();
        }
    }
</script>
<style scoped>
    .overlay {
        z-index: 2;
        position:absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 360px;
    }

    .desktop-title-header {
        background: rgba(255,255,255,.8);
        border-radius: 4px;
        left: 0;
        top: 64px;
        padding: 6px 0 6px 12px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

    .title {
        width: calc(100% - 60px);
    }

</style>