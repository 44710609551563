<!--suppress JSUnfilteredForInLoop -->
<template>
    <DashboardCard
        title="Occupancy"
        content-padding
    >
        <template v-slot:content>
            <VueApexChart
                v-if="$route.name === 'home'"
                height="270"
                type="donut"
                :series="series"
                :options="options"
            ></VueApexChart>
        </template>
    </DashboardCard>
</template>

<script>
import DashboardCard from "../core/dashboard/DashboardCard";
import VueApexChart from 'vue-apexcharts';
import {mapGetters} from "vuex";

export default {
    name: "HomeOccupancy",
    components: {DashboardCard, VueApexChart},
    data: () => ({
        options: {}
    }),
    computed: {
        ...mapGetters({
            instancesByService: 'instances/instancesFilteredComputedByService'
        }),
        series() {
            let roomsUnmanaged = 0;
            let roomsManaged = 0;
            let roomsOccupied = 0;
            let roomsOffline = 0;

            this.instancesByService('enterprise').forEach(entry => {
                try {
                    if(entry.online === false) {
                        roomsOffline += entry.roomCnt;
                    } else if (entry.pmsLinkUp) {
                        roomsOccupied += parseInt(entry.roomCnt * (entry.occupancy / 100));
                        roomsManaged += entry.roomCnt;
                    } else {
                        roomsUnmanaged += entry.roomCnt;
                    }
                } catch(e) {
                    console.error("Could not parse " + entry);
                }
            });

            this.options = {
                labels: ['Occupied', 'Vacant', 'Offline', 'Unmanaged'],
                colors: ['#008ffb', '#336522', '#b71c1c', '#b79a1c']
            };

            return [ roomsOccupied, roomsManaged - roomsOccupied, roomsOffline, roomsUnmanaged ];
        }
    }
}
</script>

<style scoped lang='scss'>

</style>
