<template>
    <div
        class="filterable-table-paging elevation-2"
        :style="dragging ? `top: ${$store.state.mousePos.y}px; left: ${$store.state.mousePos.x}px; opacity: 0.8;` :
        `top: ${top}px; left: ${left}px; opacity: 0.95;`"

    >
        <v-row>
            <v-tooltip left>
                <template v-slot:activator="{on}">
                    <v-btn
                        class="elevation-0"
                        color="primary"
                        @mousedown="dragStart"
                        v-on="on"
                    >
                        <v-icon>mdi-drag</v-icon>
                    </v-btn>
                </template>
                Drag me!
            </v-tooltip>
            <div style="width: 20px;"></div>
            <v-btn
                tile
                class="elevation-0"
                color="primary"
                @click="itemsPerPage = 100"
                :class="itemsPerPage === 100 ? 'active' : ''"
            >
                100
            </v-btn>
            <v-btn
                tile
                class="elevation-0"
                color="primary"
                @click="itemsPerPage = 250"
                :class="itemsPerPage === 250 ? 'active' : ''"
            >
                250
            </v-btn>
            <v-btn
                tile
                class="elevation-0"
                color="primary"
                @click="itemsPerPage = -1"
                :class="itemsPerPage === -1 ? 'active' : ''"
            >
                All
            </v-btn>
            <div style="width: 20px;"></div>
            <v-btn
                tile
                class="elevation-0"
                color="primary"
                @click="$emit(currentPage === 1 ? '' : 'backPage')"
            >
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="page-display">
                {{currentPage}}/{{maxPages}}
            </div>
            <v-btn
                tile
                class="elevation-0"
                color="primary"
                @click="$emit(currentPage === maxPages ? '' : 'forwardPage')"
            >
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: "FilterableTablePaging",
        props: {
            value: Number,
            currentPage: Number,
            maxPages: Number,
        },
        data: () => ({
            top: window.innerHeight - 80,
            left: window.innerWidth - 410,
            dragging: false,
        }),
        created() {
            window.addEventListener('mouseup', () => {
                this.dragging = false;
            });
        },
        computed: {
            itemsPerPage: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },
        watch: {
            '$store.state.mousePos'(pos) {
                if (this.dragging) {
                    this.top = pos.y;
                    this.left = pos.x;
                }
            }
        },
        methods: {
            dragStart() {
                this.dragging = true;
            },
        }
    }
</script>

<style lang="scss">
    .filterable-table-paging {
        background-color: var(--v-primary-base);
        position: fixed;
        border-radius: 4px !important;

        .row {
            width: 100%;
            margin: 0;
        }

        .v-btn {
            min-height: 50px;
            max-width: 50px !important;
            min-width: 50px !important;
        }

        .v-btn.active {
            background-color: var(--v-primary-lighten1) !important;
        }

        .v-btn:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }

        .page-display {
            height: 50px;
            line-height: 50px;
            color: white;
            margin: 0 8px 0 8px;
        }
    }
</style>