import API from '../../lib/api'
import Vue from 'vue'
import crudModule from "./crudModule";
import DateLib from "@/lib/date";

const set = Vue.prototype.$set;

const module = crudModule('Event log', '/status');

module.actions['fetch'] = async ({commit}, {facilityId, service}) => {
    commit('setFetching', true);

    const response = await API.request('GET', module.path + `/${facilityId}?service=${service}&start=0&limit=100`);

    if (response.success === true) {
        commit('setList', response.data.items);
    } else {
        Vue.prototype.$alert.error(`Failed to fetch ${module.itemName.toLowerCase()} list`);
        commit('setList', []);
    }

    commit('setFetching', false);
};

module.getters = {
    withTime: (state) => {
        return Vue.prototype.$clone(state.list).map(i => {
            set(i, 'time', DateLib.formatFromString(i.updatedDate));
            return i;
        });
    },
};

export default module;