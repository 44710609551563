import Vue from "vue";
import API from "../../lib/api";

export default function crudModule(itemName, path) {
    return {
        path: path,
        itemName: itemName,
        namespaced: true,
        state: {
            list: [],
            fetching: false,
            fetched: null,
            processing: false,
        },
        mutations: {
            resetState(state) {
                state.list = [];
                state.fetched = null;
            },
            setFetching(state, f) {
                state.fetching = f;
            },
            setProcessing(state, p) {
                state.processing = p;
            },
            setList(state, l) {
                state.list = l;
                state.fetched = new Date();
            },
            itemEdited(state, item) {
                state.list.forEach(i => {
                    if (i.id === item.id) {
                        for (let key in i) {
                            // noinspection JSUnfilteredForInLoop
                            if (item[key] !== undefined) { // noinspection JSUnfilteredForInLoop
                                Vue.prototype.$set(i, key, item[key]);
                            }
                        }
                    }
                });

                Vue.prototype.$alert.success(itemName + ' successfully saved');
            },
            itemCreated(state, item) {
                state.list.push(item);
                Vue.prototype.$alert.success(itemName + ' successfully created');
            },
            itemDeleted(state, item) {
                state.list.forEach(i => {
                    if (i.id === item.id) {
                        state.list.splice(state.list.indexOf(i), 1);
                    }
                });

                Vue.prototype.$alert.success(itemName + ' successfully deleted');
            }
        },
        actions: {
            reset({commit}) {
                commit('resetState');
            },
            async fetch({commit}) {
                commit('setFetching', true);

                const response = await API.request('GET', path);

                if (response.success === true) {
                    commit('setList', response.data.items);
                } else {
                    Vue.prototype.$alert.error(`Failed to fetch ${itemName.toLowerCase()} list`);
                    commit('setList', []);
                }

                commit('setFetching', false);
            },
            async save({commit}, {item, edit}) {
                commit('setProcessing', true);

                const response = await API.request(edit ? 'PUT' : 'POST', edit ? path + '/' + item.id : path, item);

                if (response.success) {
                    if (edit) commit('itemEdited', response.data);
                    else commit('itemCreated', response.data);
                } else {
                    Vue.prototype.$alert.error(edit ? `Failed to save ${itemName.toLowerCase()}` :
                        `Failed to create ${itemName.toLowerCase()}`);
                }

                commit('setProcessing', false);
            },
            async delete({commit}, {item}) {
                commit('setProcessing', true);

                const response = await API.request('DELETE', path + '/' + item.id, null, false);

                if (response.success) {
                    commit('itemDeleted', item);
                } else {
                    Vue.prototype.$alert.error(`Failed to delete ${itemName.toLowerCase()}`);
                }

                commit('setProcessing', false);
            }
        }
    }
}