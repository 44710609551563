<template>
    <v-card
            max-height="500"
            @mouseleave="$emit('noHover')"
    >
        <v-card-title>Events</v-card-title>
        <v-card-text class="pa-0">
            <v-data-table
                :items-per-page="10"
                :items="$store.state.eventLogAll.list"
                :headers="headers"
                sort-by="updatedDate"
                class="home-eventlog-table"
                fixed-header
                sort-desc
            >
                <template v-slot:item="{item, headers}">
                    <tr @click="rowDoubleClick(item)" @mouseenter="$emit('rowHover', item)">
                        <template v-for="header in headers">
                            <td v-if="header.value === 'status'">
                            <span :class="item.online || 'offline'">
                                {{item.online ? 'ONLINE' : 'OFFLINE'}}
                            </span>
                            </td>
                            <td v-else-if="header.value === 'pms'">
                                <i
                                   :class="item.pmsLinkUp ? 'mdi mdi-lan-connect success--text' : 'mdi mdi-lan-disconnect error--text'"
                                ></i>
                           </td>
                            <td v-else>{{item[header.value]}}</td>
                        </template>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "HomeEventLog",
        props: {
            instance: Object,
        },
        data: () => ({
            headers: [
                // {text: 'Time', value: 'updatedDate'},
                {text: 'Time', value: 'updatedDate', width: 180 },
                {text: 'Name', value: 'name'},
                {text: 'Status', value: 'status', width: 30 },
                {text: 'PMS', value: 'pms', width: 30 },
                // {text: 'Version', value: 'version'},
                // {text: 'Uptime', value: 'uptime'},
            ]
        }),
        methods: {
            rowDoubleClick(i) {
                this.$router.push({path: '/details', query: {facilityId: i.facilityId, service: i.service}});
            }
        }
    }
</script>

<style scoped lang="scss">
    span {
        color: var(--v-success-base);
    }

    span.offline {
        color: var(--v-error-base);
    }

    .v-card__title {
        padding-bottom: 0;
    }

    tr {
        cursor: pointer;
    }
</style>

<style lang="scss">

    .home-eventlog-table .v-data-table__wrapper {
        height: 394px !important;
    }
</style>
