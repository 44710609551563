<template>
    <DashboardCard
        title="Features"
        content-padding
    >
        <template v-slot:content>
            <v-subheader v-if="instance.activatedFeatures == null || instance.activatedFeatures.split(',').length === 0">
                No features have been added to this instance yet.
            </v-subheader>
            <v-chip
                v-else
                class="ma-1"
                v-for="f in instance.activatedFeatures.split(',')"
                :key="f"
            >
                {{f}}
            </v-chip>
        </template>
    </DashboardCard>
</template>

<script>
    import DashboardCard from "../core/dashboard/DashboardCard";

    export default {
        name: "DetailsFeatures",
        components: {DashboardCard},
        props: {
            instance: Object,
        }
    }
</script>

<style scoped>
    .v-card__text {
        padding: 0 12px 0 12px;
    }
</style>