<template>
    <v-card v-if="instance !== null && instance !== undefined" class="instance-actions">
        <v-card-title>
            <v-btn
                icon
                @click="$emit('close')"
                v-if="$vuetify.breakpoint.mdAndDown"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-icon
                :color="instance.online ? 'success' : 'error'"
            >
                {{instance.service === 'enterprise' ? instance.hosted ? 'mdi-cloud' : 'mdi-office-building' : 'mdi-google-chrome'}}
            </v-icon>
            <v-toolbar-title>{{instance.name}}</v-toolbar-title>
        </v-card-title>
        <v-card-text>
            <v-list>
                <v-list-item @click="$router.push({path: '/details', query: {facilityId: instance.facilityId, service: instance.service}})">
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-view-dashboard</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Open details</v-list-item-title>
                </v-list-item>
                <v-list-item @click="addAlert">
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-email-alert</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Create alert</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="openTouch"
                    v-show="instance.activatedFeatures !== null && instance.activatedFeatures.includes('touch')"
                >
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-open-in-new</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Enterprise Touch</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openEw">
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-open-in-new</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Enterprise Web</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="openTouch"
                    v-show="instance.activatedFeatures !== null && instance.activatedFeatures.includes('touch')"
                >
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-open-in-new</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Enterprise Touch</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="openHk"
                    v-show="instance.activatedFeatures !== null && instance.activatedFeatures.includes('housekeeping')"
                >
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-open-in-new</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Housekeeping</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openTv" v-show="instance.tvUrl !== null && instance.service==='enterprise'">
                    <v-list-item-icon>
                        <v-icon color="accent">mdi-open-in-new</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Virtual TV</v-list-item-title>
                </v-list-item>
                <v-list-item @click="show = false" v-if="!$vuetify.breakpoint.mdAndDown">
                    <v-list-item-icon>
                        <v-icon>mdi-close</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Close</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "InstanceActions",
        props: {
            instance: Object,
        },
        methods: {
            openEw() {
                window.open('https://portal.otrum.net/' + this.instance.urlName, '_blank');
            },
            openHk() {
                window.open('https://hk.otrum.net/' + this.instance.urlName, '_blank');
            },
            openTouch() {
                window.open('https://touch.otrum.net/' + this.instance.urlName, '_blank');
            },
            openTv() {
                window.open(this.instance.tvUrl, '_blank');
            },
            addAlert() {
                this.$alertEditor.show(null, false, this.instance);
            }
        }
    }
</script>

<style scoped>
    .v-card__title {
        padding-left: 12px !important;
        padding-right: 24px !important;
        padding-bottom: 8px !important;
    }

    .v-card__title .v-btn {
        padding-left: 0;
        margin-left: 0;
    }

    .v-toolbar__title {
        margin-left: 5px;
        max-width: calc(100% - 50px);
    }

    .v-card__text {
        padding: 0 !important;
        padding-bottom: 6px !important;
    }

    .v-list {
        padding-top: 0;
    }

    .v-toolbar__title {
        max-width: 300px;
    }
</style>

<style lang="scss">
    .md-and-down {
        .instance-actions {
            .v-card__title {
                .v-toolbar__title {
                    max-width: calc(100% - 80px);
                }
            }
        }
    }
</style>