<template>
    <DashboardCard
        title="Extended Info"
        content-padding
        class="details-note"
    >
        <template v-slot:titleActions>
            <v-btn
                text
                color="accent"
                @click="save"
                :loading="$store.state.notes.processing"
            >
                Save
            </v-btn>
        </template>
        <template v-slot:content>
            <v-layout wrap>
                <v-flex xs12>
                    <v-text-field
                        label="SSH Host"
                        v-model="sshHost"
                        style="padding-right: 12px;"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12>
                    <v-text-field
                        label="Hotel ID"
                        v-model="hotelId"
                    ></v-text-field>
                </v-flex>
                <v-flex xs12>
                    <v-textarea
                        label="General Info"
                        v-model="notes"
                    ></v-textarea>
                </v-flex>
            </v-layout>
        </template>
    </DashboardCard>
</template>

<script>
    import DashboardCard from "../core/dashboard/DashboardCard";

    export default {
        name: "DetailsNote",
        components: {DashboardCard},
        props: {
            instance: Object,
        },
        data: () => ({
            sshHost: null,
            hotelId: null,
            notes: null
        }),
        computed: {
            note() {
                return this.$store.state.notes.list.filter
                (n => n.facilityId === this.instance.facilityId && n.serviceType === this.instance.service)[0];
            }
        },
        watch: {
            note: {
                immediate: true,
                handler(n) {
                    if (n !== undefined) {
                        this.sshHost = n.sshHost;
                        this.hotelId = n.marshaCode;
                        this.notes = n.note;
                    }
                }
            },
        },
        methods: {
            save() {
                this.$store.dispatch('notes/save', {
                    edit: this.note !== undefined, item: {
                        id: this.note !== undefined ? this.note.id : undefined,
                        sshHost: this.sshHost,
                        marshaCode: this.hotelId,
                        note: this.notes === null ? "" : this.notes,
                        lastChangeBy: this.$store.state.userAccountId,
                        serviceType: this.instance.service,
                        facilityId: this.instance.facilityId
                    }
                });
            }
        }
    }
</script>

<style>
    .details-note textarea {
        max-height: 100px;
    }
</style>