<template>
    <FilterableList
        v-if="$vuetify.breakpoint.mdAndDown"
        :items="$store.getters.alertsComputed"
        :skeleton="$store.state.alerts.fetched === null"
        :filterable-fields="['instanceTitle', 'typeTitle', 'recipients']"
        filter-text="Search for alerts"
        @itemClick="rowClick"
    >
        <template v-slot:item="{item}">
            <v-list-item-content>
                <v-list-item-title>
                    {{item.typeTitle}}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{item.instanceTitle}}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                {{item.threshold}} min
            </v-list-item-action>
        </template>
        <template v-slot:headerActions>
            <v-btn
                icon
                @click="createAlert"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>
    </FilterableList>
    <FilterableTable
        v-else
        :items="$store.getters.alertsComputed"
        :skeleton="$store.state.alerts.fetched === null"
        :headers="headers"
        initial-sort="instanceTitle"
        table-class="hotel-list"
        @rowClick="rowClick"
    >
        <template v-slot:item="{item, header}">
            <td v-if="header.value === 'recipients'" class="chip-row">
                <span class="chip-span" v-for="i in item[header.value].split(',')">
                    {{i}}
                </span>
            </td>
            <td v-else-if="header.value === 'instanceTitle'">
                <i
                    class="mdi v-icon"
                    :class="`${item.instanceOnline ? 'success--text' : 'error--text'}
                        ${item.serviceType === 'enterprise' ? item.instanceHosted ? 'mdi-cloud' : 'mdi-office-building' : 'mdi-google-chrome'}`"
                ></i>
                {{item[header.value]}}
            </td>
            <td v-else-if="header.value === 'threshold'">
                {{item.threshold}} min
            </td>
            <td v-else>{{item[header.value]}}</td>
        </template>
    </FilterableTable>
</template>

<script>
    import FilterableTable from "../components/core/filterable/FilterableTable";
    import FilterableList from "../components/core/filterable/FilterableList";
    import {mapGetters} from "vuex";

    export default {
        name: "Alerts",
        components: {FilterableList, FilterableTable},
        data: () => ({
            headers: [
                {text: 'Facility', value: 'instanceTitle', enabled: true, filterType: 'string', filterValue: null},
                {text: 'Type', value: 'typeTitle', enabled: true, filterType: 'string', filterValue: null},
                {text: 'Threshold', value: 'threshold', enabled: true, filterType: 'string', filterValue: null},
                {text: 'Extra Param', value: 'extraParam', enabled: true},
                {text: 'Recipients', value: 'recipients', enabled: true, filterType: 'string'},
            ]
        }),
        methods: {
            rowClick(i) {
                this.$alertEditor.show(i, true, this.instancesFacilityIdServiceMap[i.facilityId + i.serviceType]);
            },
            createAlert() {
                this.$alertEditor.show(null, false);
            }
        },
        computed: {
            ...mapGetters({
                instancesComputedFacilityIdServiceMap: 'instances/instancesComputedFacilityIdServiceMap'
            }),
        }
    }
</script>

<style scoped>
    .chip-row {
        padding-bottom: 5px !important;
        padding-top: 10px !important;
    }

    .chip-span {
        background: #e0e0e0;
        border-radius: 16px;
        padding: 6px 12px 6px 12px;
        margin-right: 5px;
        margin-bottom: 5px;
        float: left;
        vertical-align: middle;
        overflow: hidden;
    }
</style>