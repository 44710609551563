<template>
    <v-btn
        v-if="$vuetify.breakpoint.mdAndDown"
        rounded
        small
        max-height="30px"
        @click="fieldClick"
        class="elevation-1 dashboard-field-button"
    >
        <v-icon>{{infoIcon}}</v-icon>
        {{infoText}}
    </v-btn>
    <div v-else @click="fieldClick">
        <v-text-field
            class="dashboard-field elevation-1"
            rounded
            filled
            readonly
            dense
            hide-details
            :style="`min-width: ${minWidth}px;`"
            :value="infoText"
            :prepend-inner-icon="infoIcon"
            :label="infoLabel"
        ></v-text-field>
    </div>
</template>

<script>
    export default {
        name: "DashboardField",
        props: {
            infoText: [String, Number],
            infoIcon: String,
            infoLabel: String,
            minWidth: {
                default: 200,
                type: Number,
            },
            clickEvent: {
                default: false,
                type: Boolean,
            }
        },
        methods: {
            fieldClick() {
                if (this.clickEvent) {
                    this.$emit('fieldClick');
                } else {
                    const dummy = document.createElement("input");
                    document.body.appendChild(dummy);
                    dummy.setAttribute('value', this.infoText);
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
                    this.$alert.success(this.infoLabel + ' copied to clipboard');
                }
            }
        }
    }
</script>

<style lang="scss">
    .dashboard-field-button {
        margin: 0 8px 8px 0;
    }

    .dashboard-field {
        margin-right: 12px !important;
        margin-bottom: 12px !important;
        max-width: 280px;
        width: auto;
        cursor: pointer !important;

        div, input {
            cursor: pointer !important;
        }
    }
</style>