<template>
    <v-layout
        :fill-height="true"
        :justify-center="true"
        :align-center="true"
        class="login"
    >
        <div>
            <v-layout :justify-center="true">
                <v-avatar size="140">
                    <img :src="require('@/assets/logo_purple.svg')" alt="logo_purple">
                </v-avatar>
            </v-layout>
            <v-toolbar-title class="text-center">Cloud Status Tool</v-toolbar-title>
            <div class="info-text" v-if="$store.state.loginError === null">Enter your username and password to begin.</div>
            <div class="error--text info-text" v-else>Login failed: {{$store.state.loginError}}.</div>
            <v-text-field
                outlined
                placeholder="Username"
                v-model="username"
                prepend-inner-icon="mdi-account"
                clearable
                :disabled="$store.state.loggingIn"
                v-on:keyup="onKeyPress"
            ></v-text-field>
            <v-text-field
                ref="passwordInput"
                outlined
                placeholder="Password"
                v-model="password"
                clearable
                type="password"
                prepend-inner-icon="mdi-lock"
                :disabled="$store.state.loggingIn"
                v-on:keyup="onKeyPress"
            ></v-text-field>
            <v-btn
                color="accent"
                block
                large
                @click="login"
                :disabled="password == null || username == null"
                :loading="$store.state.loggingIn"
            >
                Log In
            </v-btn>
        </div>
    </v-layout>
</template>

<script>
    export default {
        name: "Login",
        data: () => ({
            username: '',
            password: ''
        }),
        methods: {
            login() {
                this.$store.dispatch('login', {username: this.username, password: this.password});
            },
            onKeyPress(e) {
                if (e.key === 'Enter') {
                    if (e.srcElement.placeholder === 'Username') {
                        this.$refs.passwordInput.focus();
                    } else {
                        this.login();
                    }
                }
            },
        }
    }
</script>

<style lang="scss">
    .login {
        > div {
            max-width: 400px;
            width: 90%;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        .info-text {
            opacity: 0.7;
            font-size: 1em;
            width: 100%;
            text-align: center;
            margin: 15px 5px 10px 5px;
        }
    }
</style>