<template>
    <v-card>
        <div>
            <l-map
                    v-if="$route.name === 'home'"
                    ref="homeMap"
                    style="height: 500px; width: 100%; z-index: 1;"
                    :zoom="zoom"
                    :center="{ lat: coords.lat, lng: coords.lng }"
                    :options="{ zoomControl: false }"
                    @update:zoom="zoomChange"
                    @update:center="centerChanged"
                    @update:bounds="boundsChanged"
            >
                <l-tile-layer
                        :url="mapURL"
                        :attribution="attribution">
                </l-tile-layer>

                <template v-for="instance in items">
                    <l-marker
                            :z-index-offset="instance.online ? 1 : 2"
                            :lat-lng="{lat: parseFloat(instance.lat), lng: parseFloat(instance.lng)}"
                            :name="instance.name"
                            :icon="instance.online ? onlineMarker : offlineMarker"
                            @click="select(instance)"
                    >
                        <l-tooltip
                                :content="instance.name + ' - ' + instance.hotelType"
                        >
                        </l-tooltip>
                    </l-marker>
                </template>
            </l-map>
            <div class="overlay">
                <v-layout
                        wrap
                        v-if="!$vuetify.breakpoint.mdAndDown"
                        class="desktop-title-header"
                        :class="$store.state.scrollTop > 50 ? 'elevation-1' : 'elevation-0'"
                >
                    <v-toolbar-title
                            class="title"
                            v-html="title"
                            >
                    </v-toolbar-title>
                </v-layout>
                <v-layout style="padding: 10px;">
                    <v-spacer></v-spacer>
                    <v-btn
                            v-if="$store.state.instances.filterKey !== null"
                            @click="clearFilters"
                    >
                    Clear Filter
                    </v-btn>
                </v-layout>
            </div>
        </div>
    </v-card>
</template>

<script>
    import Storage from "../../lib/storage";
    import {mapGetters} from "vuex";

    export default {
        name: "HomeMap",
        components: {
            Storage
        },
        props: {
            username: String,
        },
        data: () => ({
            coords: {
                lat: isNaN(parseFloat(Storage.get('lastHomeMapLat', 53.911491))) ? 53.911491 : parseFloat(Storage.get('lastHomeMapLat', 53.911491)),
                lng: isNaN(parseFloat(Storage.get('lastHomeMapLng', 10.757933))) ? 10.757933 : parseFloat(Storage.get('lastHomeMapLng', 10.757933))
            },
            trackedCoords: {
                lat: parseFloat(Storage.get('lastHomeMapLat', 53.911491)),
                lng: parseFloat(Storage.get('lastHomeMapLng', 10.757933))
            },
            //mapURL: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            mapURL: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
            onlineMarker: L.icon({
                iconUrl: require('@/assets/marker_online_32.svg'),
                iconSize: [32, 37],
                iconAnchor: [16, 37]
            }),
            offlineMarker: L.icon({
                iconUrl: require('@/assets/marker_offline_32.svg'),
                iconSize: [32, 37],
                iconAnchor: [16, 37]
            }),
            infoWindow: L.popup(),
            //attribution: '&copy; <a href="http://osm.org/copyright\">OpenStreetMap</a> contributors'
            //attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012',
            attribution: 'Tiles &copy; Esri',
        }),
        computed: {
            ...mapGetters({
                instancesFiltered: 'instances/instancesFiltered'
            }),
            zoom() {
                return parseInt(Storage.get('lastHomeMapZoom', 3));
            },
            title() {
                let title = "Welcome " + this.username + ", you are monitoring";
                let added = false;
                if(this.$store.state.instances.typeCount.hotels > 0) {
                    title += '<span style="font-style: italic; font-weight: bold;"> ' + this.$store.state.instances.typeCount.hotels + " Enterprise servers</span>";
                    added = true;
                }

                if(this.$store.state.instances.typeCount.casts > 0) {
                    if(added) {
                        title += " and";
                    }
                    title += '<span style="font-style: italic; font-weight: bold;"> ' + this.$store.state.instances.typeCount.casts + " OtrumCast proxies</span>";
                    added = true;
                }
                if(!added) {
                    if(this.username.length === 0) {
                        return "Loading...";
                    }
                    return "Hi " + this.username + ", please wait...";
                }

                return title;
            },
            items() {
                const instances = this.$vuetify.breakpoint.mdAndDown ? this.$store.state.instances.list : this.instancesFiltered;
                return typeof this.search === 'string' && this.search.length > 1 ?
                        instances.filter(i => Object.values(i).toString().toLowerCase().includes(this.search.toLowerCase())) :
                        instances;
            },
        },
        methods: {
            select(i) {
                this.$nextTick(() => {
                    this.$router.push({path: '/details', query: {facilityId: i.facilityId, service: i.service}});
                });
            },
            zoomChange(z) {
                Storage.set('lastHomeMapZoom', z)
            },
            centerChanged(data) {
                this.trackedCoords.lat = data.lat;
                this.trackedCoords.lng = data.lng;
                Storage.set('lastHomeMapLat', this.trackedCoords.lat);
                Storage.set('lastHomeMapLng', this.trackedCoords.lng);
            },
            boundsChanged() {
            },
            displayEventBy(item) {
                this.infoWindow.setLatLng({
                    lat: parseFloat(item.lat),
                    lng: parseFloat(item.lng)
                });
                this.infoWindow.setContent(
                        "<b>" + item.name + "</b><br>" +
                        "Status changed " + item.updatedDate + "<br>" +
                        "System: " + (item.online === true ? "ONLINE" : "OFFLINE") + "<br>" +
                        "PMS link: " + (item.pmsLinkUp ? "ONLINE" : "OFFLINE")
                );
                this.infoWindow.openOn(this.$refs.homeMap.mapObject);
            },
            hideInfoWindow() {
                this.showInfoWindow = false;
            },
            clearFilters() {
                this.$store.dispatch('instances/applyFilter', { key: null, val: null });
            }
        }
    }
</script>

<style scoped lang="scss">
    .overlay {
        z-index: 2;
        position:absolute;
        top: 0;
        left: 0;
        width: 100%;
        .title {
            pointer-events: none;
        }
    }

    .desktop-title-header {
        background: rgba(255,255,255,.7);
        border-radius: 4px;
        left: 0;
        top: 64px;
        padding: 6px 0 6px 12px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

</style>