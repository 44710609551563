import API from '../../lib/api'
import Vue from 'vue'
import crudModule from "./crudModule";

const module = crudModule('Hotel', '/facilities');

module.state.clearingCache = false;

module.mutations['setClearingCache'] = (state, c) => {
    state.clearingCache = c;
};

module.actions['update'] = async ({commit}, {hotel, value}) => {
    hotel[value] = !hotel[value];

    const response = await API.request('PUT', '/facilities/' + hotel.facilityId, hotel, false);

    if (!response.success) {
        hotel[value] = !hotel[value];
        Vue.prototype.$alert.error(value === 'active' ? 'Hotel active change failed - please try again' :
            'Hotel enable change failed - please try again');
    } else {
        if (value === 'active') {
            Vue.prototype.$alert.success(hotel[value] ? 'Hotel successfully activated' : 'Hotel successfully deactivated');
        } else {
            Vue.prototype.$alert.success(hotel[value] ? 'Hotel successfully enabled' : 'Hotel successfully disabled');
        }
    }
};

export default module;