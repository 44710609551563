<!--suppress JSUnfilteredForInLoop -->
<template>
    <div>
        <v-data-table
            v-show="!skeleton"
            :items="filteredItems"
            :headers="headers"
            :items-per-page="itemsPerPage"
            :page="currentPage"
            :hide-default-footer="true"
            :hide-default-header="true"
            :class="tableClass"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            fixed-header
            class="filterable-table"
            @update:sort-by="sortByUpdate"
            @update:sort-desc="sortDescUpdate"
            @current-items="currentItemsChange"
        >
            <template v-slot:header="{props, on}">
                <thead>
                <tr>
                    <template v-for="header in props.headers">
                        <th
                            v-if="header.enabled"
                            :class="header.filterType ==='string' ? 'filterable-text' : ''"
                        >
                            <div @click.stop="on.sort(header.value)">
                                <v-hover>
                                    <v-row
                                        slot-scope="{hover}"
                                        class="column-header-row"
                                        @contextmenu="e => e.preventDefault()"
                                        @click.right="openHeaderOptions(header)"
                                    >
                                        <v-col class="pa-0">
                                            <v-layout
                                                :align-center="true"
                                                :fill-height="true"
                                            >
                                                {{header.text}}
                                                <v-icon
                                                    small
                                                    v-if="props.options.sortBy.includes(header.value) && props.options.sortDesc[0]"
                                                >
                                                    mdi-arrow-down
                                                </v-icon>
                                                <v-icon
                                                    small
                                                    v-else-if="props.options.sortBy.includes(header.value)"
                                                >
                                                    mdi-arrow-up
                                                </v-icon>
                                            </v-layout>
                                        </v-col>
                                        <v-col
                                            class="shrink pa-0"
                                            v-show="hover"
                                        >
                                            <v-btn
                                                style="margin-top: 0 !important; margin-left: 4px;"
                                                icon
                                                small
                                                @click.stop="openHeaderOptions(header)"
                                            >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-hover>
                            </div>

                            <v-text-field
                                v-if="header.filterType === 'string'"
                                placeholder="Filter"
                                hide-details
                                filled
                                dense
                                single-line
                                clearable
                                v-model="header.filterValue"
                                @input="filterInput(header)"
                            ></v-text-field>
                            <v-btn-toggle
                                v-else-if="header.filterType === 'bool'"
                                v-model="header.filterValue"
                                @change="filterChange(header)"
                            >
                                <v-btn :value="false">
                                    <v-icon>mdi-minus-circle-outline</v-icon>
                                </v-btn>
                                <v-btn :value="true">
                                    <v-icon>mdi-check-circle-outline</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                            <div
                                v-else
                                style="height: 45px;"
                            ></div>
                        </th>
                    </template>
                </tr>
                </thead>
            </template>
            <template v-slot:item="{item, index, headers}">
                <tr
                    @click="$emit(ctrlPressed && enableCtrlClick ? 'ctrlClick' : 'rowClick', item)"
                    @contextmenu="(e) => e.preventDefault()"
                    @click.right="$emit('rowRightClick', item)"
                >
                    <template v-for="header in headers">
                        <template v-if="header.enabled">
                            <slot name="item" :item="item" :header="header"></slot>
                        </template>
                    </template>
                </tr>
            </template>
        </v-data-table>

        <FilterableTablePaging
            v-model="itemsPerPage"
            :current-page="currentPage"
            :max-pages="itemsPerPage === -1 ? 1 : Math.ceil(filteredItems.length / itemsPerPage)"
            @forwardPage="currentPage++"
            @backPage="currentPage--"
        ></FilterableTablePaging>

        <v-skeleton-loader
            type="table-thead"
            width="100%"
            tile
            v-show="skeleton"
        ></v-skeleton-loader>

        <template v-for="i in [0, 1, 2]">
            <v-skeleton-loader
                type="table-tbody"
                width="100%"
                tile
                v-show="skeleton"
            ></v-skeleton-loader>
        </template>

        <FilterableTableHeaderOptions
            v-model="headerOptions.show"
            :headers="headers"
            :header="headerOptions.header"
            @sort="sort"
        ></FilterableTableHeaderOptions>
    </div>
</template>

<script>
    import Vue from 'vue';
    import FilterableTableHeaderOptions from "./FilterableTableHeaderOptions";
    import FilterableTablePaging from "./FilterableTablePaging";
    import Storage from "../../../lib/storage";

    export default {
        name: "FilterableTable",
        components: {FilterableTablePaging, FilterableTableHeaderOptions},
        props: {
            headers: Array,
            items: Array,
            skeleton: Boolean,
            enableCtrlClick: {
                default: false,
                type: Boolean,
            },
            initialSort: {
                default: null,
                type: String,
            },
            tableClass: {
                required: true,
                type: String,
            }
        },
        data: () => ({
            filters: {},
            currentItems: [],
            itemsPerPage: 100,
            currentPage: 1,
            sortBy: null,
            sortDesc: false,
            ctrlPressed: false,
            headerOptions: {
                show: false,
                header: null,
            }
        }),
        created() {
            if (this.tableClass === 'instance-list') {
                Vue.prototype.$instanceList = this;
            }

            this.sort(this.initialSort, false);
            this.itemsPerPage = parseInt(Storage.get('itemsPerPage', 100));

            window.addEventListener('keydown', (e) => {
                if (e.key === 'Control') this.ctrlPressed = true;
            });

            window.addEventListener('keyup', (e) => {
                if (e.key === 'Control') this.ctrlPressed = false;
            });
        },
        mounted() {
            $(document).ready(() => {
                $(`.${this.tableClass} .v-data-table__wrapper`).floatingScroll('init');

                this.floatingScrollInterval = setInterval(() => {
                    $(`.${this.tableClass} .v-data-table__wrapper`).floatingScroll('update');
                }, 2000);
            });
        },
        destroyed() {
            clearInterval(this.floatingScrollInterval);

            try {
                $(`.${this.tableClass} .v-data-table__wrapper`).floatingScroll('destroy');
            } catch (ignore) {
            }
        },
        computed: {
            filteredItems() {
                const items = this.items;

                if (Object.keys(this.filters).length === 0) return items;

                return items.filter(i => {
                    let included = true;

                    for (let key in this.filters) {
                        const filter = this.filters[key];

                        if (filter.type === 'string') {
                            if (included !== false && typeof i[key] === 'string') {
                                included = i[key].toLowerCase().includes(filter.value.toLowerCase());
                            } else {
                                included = false;
                            }
                        } else {
                            if (filter.value === 0) {
                                included = true;
                            } else if (included !== false && typeof i[key] === 'boolean') {
                                included = i[key] === filter.value;
                            } else {
                                included = false;
                            }
                        }
                    }

                    if (included === true) return i;
                });
            }
        },
        watch: {
            itemsPerPage(i) {
                Storage.set('itemsPerPage', i);
            }
        },
        methods: {
            sort(by, desc) {
                by = by === null ? this.initialSort : by;
                this.sortBy = by;
                this.sortDesc = desc;
            },
            sortByUpdate(by) {
                this.sortBy = by === undefined ? null : by;
            },
            sortDescUpdate(desc) {
                this.sortDesc = desc === undefined ? null : desc;
            },
            openHeaderOptions(header) {
                this.headerOptions.show = false;

                setTimeout(() => {
                    this.headerOptions = {
                        show: true,
                        header: header,
                    }
                }, 100);
            },
            filterInput(header) {
                clearTimeout(this.filterInputTimeout);

                this.filterInputTimeout = setTimeout(() => {
                    this.filterChange(header);
                }, 400);
            },
            filterChange(header) {
                if (header.filterValue == null) this.$delete(this.filters, header.value);
                else this.$set(this.filters, header.value, {
                    value: header.filterValue,
                    type: header.filterType,
                });
            },
            currentItemsChange(items) {
                this.currentItems = items;
            }
        }
    }
</script>

<style scoped>
    th {
        padding-top: 8px !important;
        padding-bottom: 12px !important;
        min-width: 120px;
    }

    th .v-input, th .v-btn {
        margin-top: 5px !important;
    }

    .v-btn {
        max-height: 40px;
    }

    th.filterable-text {
        min-width: 160px;
    }

    tr {
        cursor: pointer;
    }

    .column-header-row {
        width: 100%;
        height: 28px;
        margin: 0;
        padding: 0;
    }

    .column-header-row .v-btn {
        max-width: 28px;
        width: 28px;
        min-width: 28px;
    }
</style>

<style lang="scss">
    .filterable-table .v-data-table__wrapper {
        height:calc(100vh - 64px) !important;
    }

    .filterable-table {
        //padding-bottom: 59px;

        .v-data-footer {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            background: white;
        }
    }
</style>