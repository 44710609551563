export default class DateLib {

    static formatFromString(date, withTime = true) {
        if (date == null || typeof date !== 'string' || date.length !== 14) return '';

        const y = date.substr(0, 4);
        const m = date.substr(4, 2);
        const d = date.substr(6, 2);

        if (!withTime) return y + '-' + m + '-' + d;

        const hh = date.substr(8, 2);
        const mm = date.substr(10, 2);
        const ss = date.substr(12, 2);

        return y + '-' + m + '-' + d + ' ' + hh + ':' + mm + ':' + ss;
    }

    /* "2019-10-29T12:33:53+0000" */
    static formatToString(d) {
        if(typeof d !== 'object' || typeof d.getFullYear !== 'function') {
            console.error("Cannot format non-date object: " + d);
            return '';
        }
        return d.getUTCFullYear() + '-' + (d.getUTCMonth() + 1) + '-' + d.getUTCDate() + 'T' + d.getUTCHours() + ':' + d.getUTCMinutes() + ':' + d.getUTCSeconds();
    }

    static removeOffset(date) {
        if (typeof date === 'string') date = new Date(date);

        const offset = new Date().getTimezoneOffset();
        date.setMinutes(date.getMinutes() - offset);

        return date;
    }

    static formatToReadable(date) {
        const now = new Date();

        date = new Date(date.getTime());

        let seconds = Math.floor((now - (date)) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        let years = Math.floor(days / 365);

        hours = hours - (days * 24);
        minutes = minutes - (days * 24 * 60) - (hours * 60);

        if (years > 0) {
            return years === 1 ? '1 year ago' : years + ' years ago';
        } else if (days > 0) {
            return days === 1 ? '1 day ago' : days + ' days ago';
        } else if (hours > 0) {
            return hours === 1 ? '1 hour ago' : hours + ' hours ago';
        } else if (minutes > 1) {
            return minutes === 1 ? '1 minute ago' : minutes + ' minutes ago';
        }

        return 'Now';
    }

    static daysBetween(date1, date2) {
        const diffTime = Math.abs(date2 - date1);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

}