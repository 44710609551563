<template>
    <v-dialog
        max-width="400"
        v-model="showDialog"
    >
        <v-card>
            <v-card-title>
                {{title}}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <div v-html="text"></div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="no"
                >
                    No
                </v-btn>
                <v-btn
                    :color="color"
                    @click="yes"
                >
                    Yes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: "TheConfirm",
        data: () => ({
            callback: null,
            color: 'primary',
            title: '',
            text: '',
            showDialog: false,
        }),
        created() {
            Vue.prototype.$confirm = this;
        },
        methods: {
            delete(title, text, callback) {
                this.title = title;
                this.text = text;
                this.callback = callback;
                this.color = 'error';
                this.showDialog = true;
            },
            show(title, text, callback) {
                this.title = title;
                this.text = text;
                this.callback = callback;
                this.color = 'primary';
                this.showDialog = true;
            },
            no() {
                this.showDialog = false;
            },
            yes() {
                this.showDialog = false;
                this.callback();
            }
        }
    }
</script>

<style scoped>
    .v-card__text {
        margin-top: 24px;
        margin-bottom: 12px;
    }
</style>